// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Tabs, Modal, Typography } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { TransactionDetailTabProps } from 'types/transaction';
// EZ web-app components import
import { ITableQueryParams } from 'components/table/EZProTable';
import { HomeContext } from 'context/EZContext';
import { EMAIL_INTEGRATION_ERR_CODE, EmailCredentialType, EmailLogType } from 'types/email';
import { EMAIL_TAB } from 'components/appPage/modals/taskEmailModal';
import { Partial_EmailLogs } from 'components/appPage/partials/emailLogs';
import { getEmailLogDetail } from 'utils/ez_api/emailLogAPIs';
import { EmailIntegrationIndicator } from 'components/partials/emailIntegrationIndicator';
import { Partial_EmailForm } from 'pages/tabs/email/partials/emailForm';

// EZ web-app styles and assets import

const { TabPane } = Tabs;

const defaultTableParam = {
    searchKeyword: '',
    orderParams: [],
    whereParams: {},
    whereFilterParams: {},
};

export const SubTab_Emails: React.FC<TransactionDetailTabProps> = ({
    contractID,
    manualReloadCount,
}) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const [activeTabKey, setActiveTabKey] = useState<string>(EMAIL_TAB.EMAIL_LIST);

    const _reloadSubTabData = () => {
        setQueryParams({ ...defaultTableParam });
    };

    const _editLogDetail = (emailLog: EmailLogType) => {
        const _onSendEmail = () => {
            editLogDetailModal.destroy();
            setRefreshCounter(refreshCounter + 1);
        };

        const editLogDetailModal = Modal.confirm({
            title: <Typography.Title level={4}>Edit Email Draft</Typography.Title>,
            content: (
                <>
                    <Partial_EmailForm
                        contractID={contractID}
                        emailLog={emailLog}
                        onSendEmail={_onSendEmail}
                    />
                </>
            ),
            okText: 'Save',
            cancelText: 'Close',
            cancelButtonProps: {
                style: {
                    display: 'none',
                },
            },
            okButtonProps: {
                style: {
                    display: 'none',
                },
            },
            icon: <></>,
            style: { top: '40px' },
            width: '80%',
            autoFocusButton: null,
            onOk: async () => {
                editLogDetailModal.destroy();
            },
            onCancel: () => {
                setRefreshCounter(refreshCounter + 1);
            },
            closable: true,
            maskClosable: true,
        });
    };

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez_detail_emails">
            <Row gutter={[0, 0]}>
                <Col span={24}>
                    <Tabs
                        type="editable-card"
                        activeKey={activeTabKey}
                        onChange={val => setActiveTabKey(val as EMAIL_TAB)}
                        hideAdd
                        className="tabs-with-leftmargin"
                    >
                        <TabPane
                            tab={
                                <>
                                    Draft and Sent Mails of <b>This Transaction</b>
                                </>
                            }
                            key={EMAIL_TAB.EMAIL_LIST}
                            closable={false}
                        >
                            {/* <EmailIntegrationIndicator /> */}
                            <Row>
                                <Col span={24}>
                                    <Partial_EmailLogs
                                        contractID={contractID}
                                        editLogDetail={_editLogDetail}
                                        pageSize={20}
                                        refreshCounter={refreshCounter}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};
