import { AuthenticationResult } from '@azure/msal-browser';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
import { EmailCredentialType, EmailLogType, SENDMAIL_ONBEHALF } from 'types/email';

export const getEmailLogs = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-logs` + (params ? `?${params}` : ''),
    });
};

export const verifyCustomSMTPSetting = (formValues: Partial<EmailCredentialType>) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/verify-smtp-setting`,
        data: { ...formValues },
    });
};

export const swapGoogleCodeWithToken = (code: string) => {
    Mixpanel.track('app.swap_google_code_with_token', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/process-google-auth-code`,
        data: { code },
    });
};

export const processMSGraphAuthPayload = (payload: AuthenticationResult) => {
    Mixpanel.track('app.process_msgraph_auth_payload', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/process-msgraph-auth-payload`,
        data: { payload },
    });
};

export const getMSGraphAuthURL = () => {
    Mixpanel.track('app.get_msgraph_auth_url', {});

    return APIRequest({
        method: 'get',
        url: apiUrl + `email-credential/get-msgraph-auth-url`,
    });
};

export const updateEmailCredential = (formValues: Partial<EmailCredentialType>) => {
    Mixpanel.track('app.update_email_credential', {
        relay_type_id: formValues.relay_type_id,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-credential`,
        data: { ...formValues },
        useDefaultErrorNotif: true,
    });
};

export const requestNew3rdPartyEmailAccessToken = (relay_type_id: SENDMAIL_ONBEHALF) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/request-access-token/${relay_type_id}`,
    });
};

export const getMyEmailCredential = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-credential`,
    });
};

export const generateLogoutCallbackURL = () => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/create-logout-callback-url`,
        useDefaultErrorNotif: true,
    });
};
export const googleLogout = () => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `email-credential/google-logout`,
        useDefaultErrorNotif: true,
    });
};

export const getEmailLogDetail = (emailLogID: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-log/${emailLogID}`,
    });
};

export const createEmailDraft = (formValues: Partial<EmailLogType>) => {
    Mixpanel.track('app.add_email_draft', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-logs`,
        data: { ...formValues },
    });
};

export const updateEmailDraft = (emailLogID: number, formValues: Partial<EmailLogType>) => {
    Mixpanel.track('app.edit_email_draft', {
        emailLogID: emailLogID,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-log/${emailLogID}`,
        data: { ...formValues },
    });
};

export const sendEmailByEmailLogID = (
    emailLogID: number,
    formValues: Partial<EmailLogType>,
    isNotPreview: boolean = true
) => {
    Mixpanel.track('app.dispatch_new_email', {
        relay_type_id: formValues.relay_type_id,
        emailLogID: emailLogID,
        isPreview: !isNotPreview,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-log/${emailLogID}/send?isPreview=${!isNotPreview}`,
        data: { ...formValues },
        useDefaultErrorNotif: true,
    });
};

export const getEmailLogDetailByTransactionTaskID = (
    taskID: number,
    skip_template: boolean = false
) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-log/by-task-id/${taskID}?skip_template=${skip_template}`,
    });
};
