import { updateOnboardingCompleted } from './ez_api/onboardingAPIs';

export type onboardingCodeLearner =
    | 'add_new_transaction'
    | 'add_new_task_template'
    | 'add_additional_contacts'
    | 'add_documents_or_files'
    | 'add_notes'
    | 'check_daily_task'
    | 'add_new_sub_account';

export type onboardingCodeMaster =
    | 'use_advanced_search'
    | 'use_quick_search'
    | 'add_new_custom_section'
    | 'customize_transaction_status'
    | 'customize_property_status'
    | 'customize_sales_type'
    | 'customize_document_folder';

export const updateOnboardingStep = (
    onboardingCode: onboardingCodeLearner | onboardingCodeMaster,
    onSuccess?: () => void
) => {
    const payload = {
        onboarding_code: onboardingCode,
    };

    updateOnboardingCompleted(payload).then(() => {
        if (onSuccess) {
            onSuccess();
        }
    });
};
