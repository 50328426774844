import { ISelectOptionGroup } from './base';

import { IDataType } from 'components/table/EZProTable';
import { AccountCompactDataType, AccountDataType, AccountRoleType } from './account';
import { EmailLogType } from './email';

export const RENTAL_CATEGORY_ID = 3;

export const TRANSACTION_STATUSES = [
    {
        status_category_id: 2,
        status_category_label: 'Listings',
        status_list: [
            {
                status: 'Active',
                status_id: 1,
                color: '#67f14b',
            },
            {
                status: 'Pending',
                status_id: 2,
                color: '#5a35b6',
            },
            {
                status: 'Contingent',
                status_id: 3,
                color: '#b64135',
            },
            {
                status: 'Sold',
                status_id: 4,
                color: '#3590b6',
            },
            {
                status: 'Withdrawn',
                status_id: 5,
                color: '#b6a335',
            },
            {
                status: 'Expired',
                status_id: 6,
                color: '#e2e4e2',
            },
            {
                status: 'Cancelled',
                status_id: 8,
                color: '#000000',
            },
            {
                status: 'New Listing',
                status_id: 16,
                color: '#RRGGBB',
            },
            {
                status: 'Pre-Listing',
                status_id: 20,
                color: '#e4f09d',
            },
            {
                status: 'Hold',
                status_id: 25,
                color: '#e97c84',
            },
            {
                status: 'BPO',
                status_id: 26,
                color: '#f2ff0a',
            },
            {
                status: 'Referral - Listing',
                status_id: 27,
                color: '#b9ff80',
            },
            {
                status: 'Backup Offer',
                status_id: 29,
                color: '#d2ea76',
            },
            {
                status: 'Rejected Offer',
                status_id: 30,
                color: '#000000',
            },
            {
                status: 'Terminated Contract - Listing',
                status_id: 31,
                color: '#582225',
            },
        ],
    },
    {
        status_category_id: 1,
        status_category_label: 'Buyer',
        status_list: [
            {
                status_id: 9,
                status: 'Buyer',
                color: '#ece1d0',
            },
            {
                status_id: 15,
                status: 'Contract Out',
                color: '#e6bd37',
            },
            {
                status_id: 18,
                status: 'Pending Buyer',
                color: '#b69cf6',
            },
            {
                status_id: 19,
                status: 'Cancelled Buyer',
                color: '#000000',
            },
            {
                status_id: 24,
                status: 'Closed Buyer',
                color: '#7c90e9',
            },
            {
                status_id: 28,
                status: 'Referral - Buyer',
                color: '#f1efc0',
            },
            {
                status_id: 32,
                status: 'Terminated Contract - Buyer',
                color: '#582225',
            },
            {
                status_id: 33,
                status: 'Buyer - Expired',
                color: '',
            },
        ],
    },
    {
        status_category_id: 3,
        status_category_label: 'Rental',
        status_list: [
            {
                status_id: 10,
                status: 'Available',
                color: '#80ffa4',
            },
            {
                status_id: 11,
                status: 'Leased',
                color: '#1eae47',
            },
            {
                status_id: 12,
                status: 'Off Market',
                color: '#f9920b',
            },
            {
                status_id: 13,
                status: 'Pending Lease',
                color: '#8e6ae7',
            },
            {
                status_id: 14,
                status: 'Rerental',
                color: '#f7ff0a',
            },
            {
                status_id: 34,
                status: 'Rental - Expired',
                color: '',
            },
        ],
    },
];

export const STATUS_LIST_RENTAL =
    TRANSACTION_STATUSES.find(item => item.status_category_id === RENTAL_CATEGORY_ID)
        ?.status_list || [];

export const ACTIVE_STATUS_IDS = [
    1, 2, 3, 9, 10, 13, 15, 16, 18, 20, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
];

export const PROPERTY_TYPES = [
    {
        type_id: 18,
        type_name: 'Income Sale',
    },
    {
        type_id: 6,
        type_name: 'Commercial',
    },
    {
        type_id: 12,
        type_name: 'Townhouse',
    },
    {
        type_id: 16,
        type_name: 'Vacant Land',
    },
    {
        type_id: 1,
        type_name: 'SFR',
    },
    {
        type_id: 2,
        type_name: 'Condo',
    },
    {
        type_id: 3,
        type_name: 'TIC',
    },
    {
        type_id: 15,
        type_name: 'Mobile Home',
    },
    {
        type_id: 17,
        type_name: 'COOP',
    },
    {
        type_id: 4,
        type_name: '2-4',
    },
    {
        type_id: 5,
        type_name: '5+',
    },
    {
        type_id: 10,
        type_name: 'Other',
    },
];

export const SALE_TYPES = [
    {
        sale_type_id: 1,
        saletype_name: 'Foreclosure',
    },
    {
        sale_type_id: 2,
        saletype_name: 'Probate',
    },
    {
        sale_type_id: 3,
        saletype_name: 'Short Sale',
    },
    {
        sale_type_id: 4,
        saletype_name: 'Approved Short Sale',
    },
    {
        sale_type_id: 7,
        saletype_name: 'Sale Type 1 (customize in admin)',
    },
    {
        sale_type_id: 8,
        saletype_name: 'Sale Type 2',
    },
    {
        sale_type_id: 9,
        saletype_name: 'Sale Type 3',
    },
    {
        sale_type_id: 10,
        saletype_name: 'Sale Type 4',
    },
    {
        sale_type_id: 11,
        saletype_name: 'Sale Type 5',
    },
    {
        sale_type_id: 12,
        saletype_name: 'Sale Type 6',
    },
];

export const TRANSACTION_FLAGS = [
    {
        flag_id: 1,
        flag: 'No Flag',
        flag_color: 'black',
        status: 1,
    },
    {
        flag_id: 2,
        flag: 'Missing Documents',
        flag_color: 'red',
        status: 1,
    },
    {
        flag_id: 3,
        flag: 'Broker Review',
        flag_color: 'green',
        status: 1,
    },
    {
        flag_id: 4,
        flag: 'Missing Signatures',
        flag_color: 'orange',
        status: 1,
    },
    {
        flag_id: 5,
        flag: 'Past Expiration',
        flag_color: 'yellow',
        status: 1,
    },
    {
        flag_id: 6,
        flag: 'Past Closing',
        flag_color: 'gray',
        status: 1,
    },
    {
        flag_id: 7,
        flag: 'Broker Approved',
        flag_color: 'pink',
        status: 1,
    },
    {
        flag_id: 9,
        flag: 'Coordinator Approved',
        flag_color: 'purple',
        status: 1,
    },
    {
        flag_id: 10,
        flag: 'Coordinator Review',
        flag_color: 'black',
        status: 1,
    },
    {
        flag_id: 11,
        flag: 'Missing E-Mails',
        flag_color: 'blue',
        status: 1,
    },
];

export type PARTY_TYPES =
    // non-rental
    | 'contract_buyers'
    | 'contract_buyer_agents'
    | 'contract_sellers'
    | 'contract_seller_agents'
    // rental
    | 'contract_tenants'
    | 'contract_referral_sources'
    | 'contract_landlords'
    | 'contract_leasing_agents';

export const TRANSACTION_STATUSES_OPT_GROUP: ISelectOptionGroup[] = TRANSACTION_STATUSES.map(
    status_category => {
        const optGroup: ISelectOptionGroup = {
            label: status_category.status_category_label,
            options: [],
        };
        optGroup.options = status_category.status_list.map(opt => ({
            label: opt.status,
            value: opt.status_id.toString(),
            color: opt.color,
        }));

        return optGroup;
    }
);

export type ContractAdditionalInfoOption = {
    additional_info_fields_option_id: number;
    additional_info_field_id: number;
    option_value: string;
    account_id: number;
    parent_account_id: number;
};

export type AdditionalInfoFieldType = {
    active: number;
    additional_info_field_id: number;
    additional_info_fields_category_id: number;
    additional_info_fields_preference_id: number;
    contract_additional_info_category: {
        category: string;
    };
    newname: string;
    parent_account_id: number;
    rank: number;
    toggle: number;
    field_value?: any;
    field_option_id?: any;

    contract_additional_info_field: {
        class: string | null; // date, dropdown, numeric, text. All lower cases.
        tempFieldType?: string;

        additional_info_field_id: number;
        name: string;
        type: string;
        id: string;
        status: number;
        rank: number;
        account_id: number;
        parent_account_id: number;
    };

    contract_additional_info_options: ContractAdditionalInfoOption[];
    contract_additional_info_options_temp?: ContractAdditionalInfoOption[];

    contract_additional_info_value?: {
        additional_info_field_id: number;
        field_value: any;
    };
};

export type contractAdditionalInfoCategoryType = {
    additional_info_fields_category_id: number;
    category: string;
    account_id: number;
    parent_account_id: number;

    contract_additional_info_preferences: AdditionalInfoFieldType[];
};

export type ContractBuyer = {
    buyer_id: number;
    contract_id?: number;
    date_posted?: Date;
    buyer_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
};
export type ContractBuyerAgents = {
    agent_id: number;
    contract_id?: number;
    date_posted?: Date;
    agent_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission_rate?: number;
    commission_amount?: number;
};
export type ContractSellerAgents = {
    broker_id: number;
    contract_id?: number;
    date_posted?: Date;
    broker_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission_rate?: number;
    commission_amount?: number;
};
export type ContractSellers = {
    seller_id: number;
    contract_id?: number;
    date_posted?: Date;
    seller_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
};

export type ContractLeasingAgent = {
    leasing_agent_id: number;
    contract_id?: number;
    date_posted?: Date;
    leasing_agent_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission_rate?: number;
    commission_amount?: number;
};
export type ContractReferralSources = {
    referral_source_id: number;
    contract_id?: number;
    date_posted?: Date;
    referral_source_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission_rate?: number;
    commission_amount?: number;
};
export type ContractTenants = {
    tenant_id: number;
    contract_id?: number;
    date_posted?: Date;
    tenant_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
};
export type ContractLandlords = {
    landlord_id: number;
    contract_id?: number;
    date_posted?: Date;
    landlord_name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
};

export interface ITransactionListDataType extends IDataType {
    contract_id: number;
    account_id: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    escrow: string;
    mis: string;
    status_id: number;
    contract_status?: {
        status: string;
        color: string;
    };
    flag_id: number;
    type_id: number | null;
    sale_types_id: number | null;
    date_posted: string;
    account?: {
        email: string;
        fullname: string;
    };
    contract_flag?: {
        flag: string;
        flag_color: string;
    };
    contract_date?: {
        date_id?: number;
        contract_id?: number;
        date_posted?: Date;
        contract_date?: string;
        close_date?: string;
        inspection_contingency_date?: string;
        financing_contingency_date?: string;
        expiration_date?: string;
        lease_signed_date?: string;
        lease_start_date?: string;
        lease_end_date?: string;
        application_date?: string;
        lease_term?: string;
        move_in_date?: string;
        year_built?: number;
        appraisal_contingency_date?: string;
        close_past_alert?: number;
        expiration_past_alert?: number;
        contingency_past_alert?: number;
        listing_date?: string;
        effective_date?: string;
        listing_agreement_date?: string;
        listing_live_date?: string;
    };
    contract_price?: {
        price_id?: number;
        contract_id?: number;
        date_posted?: Date;
        listprice?: number;
        salesprice?: number;
        cccredit?: number;
        marketedprice?: number;
        rentalprice?: number;
        credits?: number;
        deposits?: number;
    };
    contract_property_type?: {
        type_name: string;
    };

    // parties for non-rental prop
    contract_buyers?: ContractBuyer[];
    contract_buyer_agents?: ContractBuyerAgents[];
    contract_sellers?: ContractSellers[];
    contract_seller_agents?: ContractSellerAgents[];

    // parties for rental prop
    contract_tenants?: ContractTenants[];
    contract_referral_sources?: ContractReferralSources[];
    contract_landlords?: ContractLandlords[];
    contract_leasing_agents?: ContractLeasingAgent[];

    contract_date_additionals?: {
        date_additional_id: number;
        date_name: string;
        val_date: string;
        telephone: string;
        past_alerts: number;
    }[];

    contract_additional_info_categories?: contractAdditionalInfoCategoryType[];

    complete_task_count?: number;
    total_task_count?: number;

    complete_doc_count?: number;
    total_doc_count?: number;

    total_note_count?: number;
}

export type TransactionListQuickStatType = {
    contract_id: number;

    complete_task_count: number;
    incomplete_task_count: number;

    complete_doc_count: number;
    incomplete_doc_count: number;

    email_msg_count: number;
    contract_note_count: number;
};

export type TransactionDetailType = ITransactionListDataType & {
    assigned_accounts?: {
        account_id: number;
        email: string;
        fullname: string;

        accountrole: AccountRoleType;
    }[];
};

export type TransactionDetailTabPane = {
    key: string;
    label: string | React.ReactNode;
    tabContent: React.FC<TransactionDetailTabProps>;
};

export type TransactionDetailTabProps = {
    isLoading?: boolean;
    subTabs: TransactionDetailTabPane[];
    currentTab: TransactionDetailTabPane;

    contractID: number;
    contractAddress?: string;

    isRentalProperty: boolean;
    manualReloadCount: number;
    isLoadingSubTabData: boolean;
    setIsLoadingSubTabData: React.Dispatch<React.SetStateAction<boolean>>;

    reloadTransactionStat: (contract_id?: number) => void;
    // transactionData: TransactionDetailType;
    // setTransactionData: React.Dispatch<React.SetStateAction<TransactionDetailType>>;
};

export type ContactRoleType = {
    role_id: number;
    role?: string;
    parent_account_id?: number;
    date_added?: Date;
};

export type TransactionContactItemType = {
    address?: string | null;
    city?: string | null;
    company?: string | null;
    contact_id?: number | null;
    contactname?: string | null;
    contract_id?: number | null;
    country?: string | null;
    date_posted?: string | null;
    email?: string | null;
    email2?: string | null;
    fax?: string | null;
    role?: string | null;

    role_id?: number | null;
    contact_role?: ContactRoleType;

    state?: string | null;
    telephone?: string | null;
    telephone2?: string | null;
    zip?: string | null;

    notes?: string | null;
};

export type TransactionNoteItemType = {
    category: string;
    contract_id: number;
    date_posted: string;
    note_id: number;
    notes: string;
    posted_account?: { fullname: string; email: string };
    posted_account_id: number;
};

export type TransactionNoteViewItemType = {
    category: string;
    contract_id: number;
    date_posted: string;
    note_id: number;
    notes: string;
    username: string;
    msg_body: string;
    account?: { fullname: string; email: string };
    account_id: number;
};

export type TransactionEmailNoteItemType = {
    m_id: number;
    account_id: number;
    contract_id: number;
    msg_date: Date;
    msg_sender?: string;
    msg_subject?: string;
    msg_body?: string;
    account?: { fullname: string; email: string };
};

export type TransactionTaskItemType = {
    id: number;
    uuid: string;
    list_id: number;
    d_created: number;
    d_completed: number;
    d_edited: number;
    compl: number;
    title: string;
    note?: string;
    prio: number;
    ow: number;
    tags: string;
    tags_ids: string;
    duedate?: string | null;
    completeddate?: Date;
    defaulttask_id: number;
    required_signers?: string;
    alertnumdays?: number;

    assignee?: number;
    email_template_id?: number;
    message_log_id?: number; // active draft id

    task_list: {
        name: string;
        contract_id: number;
        contract?: {
            address: string;
            city: string;
            state: string;
            zip: string;
            contract_id: number;
        };
    };

    task_todo_reminders?: {
        id: number;
        contract_id: number;
        task_id: number;
        duedate: string;
        day_number: string;
        day_after_before: string;
        sendemailto: string;
        send_reminder_on_date: string;
        email_sent: number;
        task_level: number;
        parent_account_id: number;
    }[];

    docs?: TransactionDocItemType[];

    emails?: EmailLogType[];
    emails_sent_count?: number;

    assignee_account?: AccountCompactDataType;
};

export type TransactionTaskListType = {
    id: number;
    contract_id: number;
    account_id: number;
    uuid: string;
    ow: number;
    name: string;
    d_created: number;
    d_edited: number;
    sorting: number;
    published: number;
    taskview: number;
};

export type DocFolderPreference = {
    folders_preference_id: number;
    folder_id: number;
    parent_account_id: number;
    folder_name?: string;
    toggle: number;
};

export type DocNotesType = {
    doc_note_id: number;
    doc_id: number;
    notes: string;
    date_posted: Date;
    posted_user_id?: number;

    account?: {
        email: string;
        fullname: string;
    };
};

export type DocFolderType = {
    folder_id: number;
    folder: string;
    date_added?: Date;
    path?: string;
    folder_name?: string;
    rank?: number;
    parent_account_id: number;

    account?: {
        email: string;
        fullname: string;
    };

    doc_folder_preference?: DocFolderPreference;

    new_folder_name?: string;
};

export type TransactionDocItemType = {
    doc_id: number;
    account_id?: number;
    contract_id: number;
    date_uploaded?: Date;
    filename?: string;
    u_filename?: string;
    path?: string;
    description?: string;
    uploadedby_account_id?: number;
    status: number;
    folder_id?: number;
    review_status: number;
    rank: number;
    sender_email?: string;
    emailed: number;
    document_id?: number;
    m_id?: number;
    docusign_envelopeid?: string;
    docusign_documentid?: string;
    docusign_uri?: string;
    docusign_fetched: number;
    archived: number;

    bucket: string;
    key: string;
    content_type: string;
    size: number;

    doc_folder?: {
        rank: number;
        folder_name: string;

        doc_folder_preference?: DocFolderPreference;
    };
    account?: {
        email: string;
        fullname: string;
    };
    doc_notes?: DocNotesType[];

    task_todolists?: TransactionTaskItemType[];
};

export type TransactionQuickStat = {
    taskStatInfo: {
        incomplete: number;
        complete: number;
        total: number;
    };
    documentStatInfo: {
        incomplete: number;
        complete: number;
        total: number;
    };
    noteStatInfo: {
        total: number;
    };
};

export interface SelectPartyItemType {
    key: string;
    name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission?: number;
    commission_rate?: number;
    commission_amount?: number;
}

export type GreenSheetType = {
    greensheet_id: number;
    contract_id?: number;
    other_fee?: string;
    trans_coord_fee?: string;
    brokerage_fee?: string;
    home_warranty_fee?: string;
    referral_amount?: string;
    referral_paid_to?: string;
    trans_coord_name?: string;
    credit_to_buyer_seller?: string;
    titleco_contact_id?: number;
    titleco_company?: string;
    titleco_closing_officer?: string;
    titleco_address?: string;
    titleco_fax?: string;
    titleco_email?: string;
    titleco_office_num?: string;
    titleco_mobile?: string;
    lenderco_contact_id?: number;
    lenderco_cash_deal?: number;
    lenderco_company?: string;
    lenderco_loan_officer?: string;
    lenderco_address?: string;
    lenderco_fax?: string;
    lenderco_email?: string;
    lenderco_office_num?: string;
    lenderco_mobile?: string;
    notes?: string;
    show_additional_info?: boolean;
};
