// Core React libraries import
import React from 'react';
import { useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Typography,
    Button,
    Form,
    Input,
    Alert,
    Checkbox,
    Radio,
    Space,
    Modal,
} from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import { CloseOutlined, GoogleOutlined, ReloadOutlined, WindowsOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';

// EZ web-app utils import
import { REDIRECT_URI } from 'utils/envUtil';
// EZ web-app APIs import
import {
    generateLogoutCallbackURL,
    getMSGraphAuthURL,
    getMyEmailCredential,
    googleLogout,
    processMSGraphAuthPayload,
    swapGoogleCodeWithToken,
    updateEmailCredential,
    verifyCustomSMTPSetting,
} from 'utils/ez_api/emailLogAPIs';
// EZ web-app types import
import { showMessage, showNotification } from 'utils/notificationUtil';
import { EmailCredentialType, SENDMAIL_ONBEHALF } from 'types/email';
import { checkEmailCredential } from 'utils/emailUtil';
// EZ web-app components import

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;
// const msalInstance = new PublicClientApplication(msalConfig);

export const SubTab_EmailIntegration: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const [emailCredential, setEmailCredential] = useState<EmailCredentialType>();
    const [relayTypeID, setRelayTypeID] = useState<number | undefined>();

    const [isCheckingSMTPSetting, setIsCheckingSMTPSetting] = useState<boolean>(false);
    const [isMS265ButtonClicked, setIsMS265ButtonClicked] = useState<boolean>(false);
    const [smtpVerificationMsg, setSmtpVerificationMsg] = useState<{
        messageType: 'error' | 'success' | 'info';
        messageContent: string;
    } | null>();
    const [isEmailCredentialSet, setIsEmailCredentialSet] = useState<boolean>(false);

    const { instance: MSALInstance } = useMsal();

    const loginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => {
            const code = tokenResponse.code;
            const scope = tokenResponse.scope;

            if (!scope.includes('https://mail.google.com/')) {
                showNotification(
                    'error',
                    'Required scope is still missing. Please re-authorize your google permission and make sure that you check all the necessary access.'
                );
            } else {
                swapGoogleCodeWithToken(code)
                    .then(resp => {
                        updateEmailCredential({
                            relay_type_id: SENDMAIL_ONBEHALF.GMAIL,
                        }).then(() => {
                            showNotification(
                                'success',
                                `You are now using your gmail account as the sender profile.`
                            );
                            _fetchMyCredentials();
                        });
                    })
                    .catch(err => {
                        console.log(
                            '🚀 ~ file: subTabs_Integration.tsx:43 ~ swapGoogleCodeWithToken ~ err:',
                            err
                        );
                    });
            }
        },
        flow: 'auth-code',
        scope: 'https://mail.google.com/',
        redirect_uri: REDIRECT_URI,
    });

    const _fetchMyCredentials = async () => {
        setIsEmailCredentialSet(false);
        getMyEmailCredential().then(resp => {
            const emailCredentialTemp = resp.data as EmailCredentialType;

            setEmailCredential(emailCredentialTemp);
            form.setFieldsValue(emailCredentialTemp);

            setRelayTypeID(relayTypeID || emailCredentialTemp?.relay_type_id);

            setIsMS265ButtonClicked(false);

            const checkResult = checkEmailCredential(emailCredentialTemp);
            setIsEmailCredentialSet(checkResult.isEmailIntegrationSet);
        });
    };

    const _signInWithMicrosoft = () => {
        setIsMS265ButtonClicked(true);
        getMSGraphAuthURL().then(resp => {
            const url = resp.data?.url;
            window.open(url, '_blank');
        });
    };

    const _reloadMS365Integration = () => {
        getMyEmailCredential().then(resp => {
            const emailCredentialTemp = resp.data as EmailCredentialType;

            if (
                emailCredentialTemp.ms_email &&
                emailCredentialTemp.ms_email !== emailCredential?.ms_email
            ) {
                updateEmailCredential({
                    relay_type_id: SENDMAIL_ONBEHALF.MS_365,
                }).then(() => {
                    showNotification(
                        'success',
                        `You are now using your microsoft account as the sender profile.`
                    );
                    _fetchMyCredentials();
                });
            } else {
                _fetchMyCredentials();
            }
        });
    };

    const _onLogoutMS365 = () => {
        setIsMS265ButtonClicked(true);
        generateLogoutCallbackURL().then(resp => {
            const url = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
                resp.data?.url
            )}`;
            window.open(url, '_blank');
        });
    };

    const _onGoogleLogout = () => {
        googleLogout().then(resp => {
            showNotification('success', 'You have unlinked a Google account successfully.');
            _fetchMyCredentials();
        });
    };

    const _saveChanges = async () => {
        if (relayTypeID === SENDMAIL_ONBEHALF.CUSTOM_SMTP) {
            form.validateFields()
                .then((formValues: EmailCredentialType) => {
                    formValues.smtp_incoming_ssl = formValues.smtp_incoming_ssl ? true : false;
                    formValues.relay_type_id = SENDMAIL_ONBEHALF.CUSTOM_SMTP; // Custom SMTP

                    updateEmailCredential(formValues).then(() => {
                        showNotification(
                            'success',
                            'You are now using a Custom SMTP setting as the sender profile.'
                        );
                        _fetchMyCredentials();
                    });
                })
                .catch(() => {});
        } else if (relayTypeID) {
            updateEmailCredential({
                relay_type_id: relayTypeID,
            }).then(() => {
                showNotification(
                    'success',
                    `You are now using ${
                        SENDMAIL_ONBEHALF.GMAIL === relayTypeID
                            ? 'your gmail account'
                            : 'your microsoft account'
                    } as the sender profile.`
                );
                _fetchMyCredentials();
            });
        } else {
            showMessage('error', 'Please select an email integration to start.');
        }
    };

    const _testSMTPConnection = async () => {
        form.validateFields()
            .then((formValues: EmailCredentialType) => {
                formValues.smtp_incoming_ssl = formValues.smtp_incoming_ssl ? true : false;
                formValues.relay_type_id = SENDMAIL_ONBEHALF.CUSTOM_SMTP; // Custom SMTP

                setIsCheckingSMTPSetting(true);
                verifyCustomSMTPSetting(formValues)
                    .then(() => {
                        setSmtpVerificationMsg({
                            messageContent: 'SMTP setting is valid',
                            messageType: 'success',
                        });
                    })
                    .catch(() => {
                        setSmtpVerificationMsg({
                            messageContent:
                                "Connection can't be established. Please check your setting.",
                            messageType: 'error',
                        });
                    })
                    .finally(() => {
                        setIsCheckingSMTPSetting(false);

                        setTimeout(() => {
                            setSmtpVerificationMsg(null);
                        }, 10000);
                    });
            })
            .catch(() => {});
    };

    useEffect(() => {
        _fetchMyCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez_email_logs">
            <Row gutter={[0, 24]} className="">
                <Col span={24} className="">
                    {isEmailCredentialSet && (
                        <Alert
                            icon={<></>}
                            message={
                                <>
                                    You currently use&nbsp;
                                    <b>
                                        {emailCredential?.relay_type_id === SENDMAIL_ONBEHALF.GMAIL
                                            ? emailCredential?.google_email
                                            : emailCredential?.relay_type_id ===
                                              SENDMAIL_ONBEHALF.MS_365
                                            ? emailCredential.ms_email
                                            : emailCredential?.relay_type_id ===
                                              SENDMAIL_ONBEHALF.CUSTOM_SMTP
                                            ? emailCredential.smtp_outgoing_username
                                            : '-'}
                                    </b>{' '}
                                    (
                                    {emailCredential?.relay_type_id === SENDMAIL_ONBEHALF.GMAIL
                                        ? 'Gmail integration'
                                        : emailCredential?.relay_type_id ===
                                          SENDMAIL_ONBEHALF.MS_365
                                        ? 'Microsoft 365 integration'
                                        : emailCredential?.relay_type_id ===
                                          SENDMAIL_ONBEHALF.CUSTOM_SMTP
                                        ? 'Custom SMTP integration'
                                        : '-'}
                                    ) for sending your email.
                                </>
                            }
                            type="success"
                            showIcon
                            className="mb-3"
                        />
                    )}
                    <Alert
                        message={
                            <>
                                Hit the button <b>"Save Changes"</b> to save all the changes you
                                have made, and start using the selected type of integration.
                            </>
                        }
                        type="info"
                        showIcon
                        action={
                            <Space>
                                <Button type="primary" onClick={() => _saveChanges()}>
                                    Save Changes
                                </Button>
                            </Space>
                        }
                    />
                </Col>
                <Col span={24} className="">
                    <Radio.Group
                        defaultValue="1"
                        buttonStyle="solid"
                        size="large"
                        onChange={e => setRelayTypeID(e.target.value)}
                        value={relayTypeID}
                    >
                        <Radio.Button value={2}>Gmail</Radio.Button>
                        <Radio.Button value={3}>Microsoft 365</Radio.Button>
                        <Radio.Button value={4}>Custom SMTP (Outgoing)</Radio.Button>
                    </Radio.Group>
                </Col>
                {relayTypeID == SENDMAIL_ONBEHALF.GMAIL && (
                    <Col span={24} className="">
                        <span style={{ width: '220px' }} className="mr-2">
                            <Button onClick={() => loginWithGoogle()} icon={<GoogleOutlined />}>
                                {emailCredential?.google_access_token &&
                                emailCredential?.google_email ? (
                                    <>
                                        Signed in as <b>{emailCredential.google_email}</b>
                                    </>
                                ) : (
                                    <>Sign in with Google</>
                                )}
                            </Button>
                        </span>
                        {emailCredential?.google_access_token && emailCredential?.google_email && (
                            <a className="mr-2" onClick={() => _onGoogleLogout()}>
                                <u>Logout</u>
                            </a>
                        )}
                    </Col>
                )}
                {relayTypeID == SENDMAIL_ONBEHALF.MS_365 && (
                    <Col span={24} className="">
                        <span style={{ width: '220px' }} className="mr-2">
                            <Button
                                icon={<WindowsOutlined />}
                                onClick={() => _signInWithMicrosoft()}
                            >
                                {emailCredential?.ms_access_token && emailCredential?.ms_email ? (
                                    <>
                                        Signed in as <b>{emailCredential.ms_email}</b>
                                    </>
                                ) : (
                                    <>Sign in with Microsoft</>
                                )}
                            </Button>
                        </span>
                        {emailCredential?.ms_access_token && emailCredential?.ms_email && (
                            <a className="mr-2" onClick={() => _onLogoutMS365()}>
                                <u>Logout</u>
                            </a>
                        )}
                        {isMS265ButtonClicked && (
                            <a onClick={() => _reloadMS365Integration()}>
                                <u>
                                    <ReloadOutlined /> Reload integration
                                </u>
                            </a>
                        )}
                    </Col>
                )}
                {relayTypeID == SENDMAIL_ONBEHALF.CUSTOM_SMTP && (
                    <Col span={24} className="">
                        <div style={{ width: '600px' }}>
                            <Form
                                layout="vertical"
                                form={form}
                                requiredMark={true}
                                autoComplete="off"
                            >
                                <Row gutter={[16, 0]}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Email Address (Username)"
                                            name="smtp_outgoing_username"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter the email address, e.g.,: youremail@yourdomain.com" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Password"
                                            name="smtp_outgoing_password"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Enter the password" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            label="Hostname"
                                            name="smtp_outgoing_server"
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: false,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter the hostname, e.g.,: mail.yourdomain.com" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Port" name="smtp_outgoing_port">
                                            <Input placeholder="25" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label=""
                                            name="smtp_outgoing_ssl"
                                            valuePropName="checked"
                                            extra={
                                                <>
                                                    Enables enhanced security.&nbsp;
                                                    <a
                                                        href="https://www.mailmodo.com/guides/ssl/"
                                                        target="blank"
                                                    >
                                                        Read more
                                                    </a>
                                                </>
                                            }
                                        >
                                            <Checkbox>Use SSL</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button
                                            onClick={() => _testSMTPConnection()}
                                            disabled={isCheckingSMTPSetting}
                                        >
                                            {isCheckingSMTPSetting ? (
                                                <>Verifying...</>
                                            ) : (
                                                <>Test Connection</>
                                            )}
                                        </Button>
                                    </Col>

                                    {smtpVerificationMsg?.messageType && (
                                        <Col span={24} className="mt-2">
                                            <Alert
                                                message={smtpVerificationMsg.messageContent}
                                                type={smtpVerificationMsg.messageType}
                                                showIcon
                                                closeText="Close"
                                                closable
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        </div>
                    </Col>
                )}
                <Col span={24} className="mt-4"></Col>
            </Row>
        </div>
    );
};
