// Core React libraries import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Button, Divider, Form, Alert, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { EditOutlined } from '@ant-design/icons';
import { get } from 'lodash';

// EZ web-app utils import
// EZ web-app APIs import
import { updateTransactionDetail } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import { TransactionDetailType } from 'types/transaction';
// EZ web-app components import
import { CommissionInfo, EZCopyable } from 'components';
import { showNotification } from 'utils/notificationUtil';
import { ModalEditTransactionParty } from '../modals/Modal_showEditParty';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const { Text } = Typography;

const colLabelSpan = 14;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];

const partiesSectionDefaultStyle: React.CSSProperties = {
    minHeight: '106px',
};

const PartyComponent: React.FC<{
    listName: string; // for e.g.: contract_sellers
    recordColumnName: string; // for e.g.: seller_name
    listLabel: string; // for e.g.: seller
    seeAllLabel: string; // for e.g.: See All Sellers Information
    listPrimaryID: string; // for e.g.: leasing_agent_id
    transactionData: TransactionDetailType | undefined;
    isModalVisible?: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setModalTitle: React.Dispatch<React.SetStateAction<string>>;
    openPartyModal: (
        isEdit: boolean,
        modalTitle: string,
        listName: string,
        listFullnameColumnName: string,
        listPrimaryID: string
    ) => void;
}> = ({
    listName,
    recordColumnName,
    listLabel,
    seeAllLabel,
    listPrimaryID,
    transactionData,
    isModalVisible,
    setIsModalVisible,
    setModalTitle,
    openPartyModal,
}) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const dataList: any[] = get(transactionData, listName) || [];

    const hasCommission = [
        'contract_buyer_agents',
        'contract_seller_agents',
        'contract_referral_sources',
        'contract_leasing_agents',
    ].includes(listName);

    const useMarketedPrice = ['contract_referral_sources', 'contract_leasing_agents'].includes(
        listName
    );

    const _openPartyModal = (
        isEdit: boolean,
        modalTitle: string,
        listName: string,
        listFullnameColumnName: string,
        listPrimaryID: string
    ) => {
        openPartyModal(isEdit, modalTitle, listName, listFullnameColumnName, listPrimaryID);
    };

    return (
        <>
            <Col span={24} className="">
                <Row className="">
                    <Col span={12} className="text-title-secondary party-header-name">
                        {listLabel}
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {UserPermission && UserPermission.toggle === 2 && (
                            <Button
                                className="ez-action-btn"
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() =>
                                    _openPartyModal(
                                        true,
                                        seeAllLabel,
                                        listName,
                                        recordColumnName,
                                        listPrimaryID
                                    )
                                }
                            >
                                Edit
                            </Button>
                        )}
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24} style={{ ...partiesSectionDefaultStyle }}>
                {dataList?.length ? (
                    <ol>
                        {dataList?.map((record: any, idx) => (
                            <li key={`${listName}-${idx}`}>
                                <div>
                                    {record[recordColumnName] ? (
                                        <>
                                            {record[recordColumnName]}
                                            <EZCopyable
                                                text={record[recordColumnName]}
                                                className="ml-1"
                                            />
                                        </>
                                    ) : (
                                        <Text type="secondary">Name is empty</Text>
                                    )}
                                </div>
                                <div>
                                    {record.email ? (
                                        <>
                                            {record.email}
                                            <EZCopyable text={record.email} className="ml-1" />
                                        </>
                                    ) : (
                                        <Text type="secondary">Email is empty</Text>
                                    )}
                                </div>
                                <div>
                                    {record.telephone ? (
                                        <>
                                            {record.telephone}
                                            <EZCopyable text={record.telephone} className="ml-1" />
                                        </>
                                    ) : (
                                        <Text type="secondary">Phone is empty</Text>
                                    )}
                                </div>
                                {hasCommission && (
                                    <div>
                                        <CommissionInfo
                                            commission_base={
                                                useMarketedPrice
                                                    ? transactionData?.contract_price?.marketedprice
                                                    : transactionData?.contract_price?.salesprice
                                            }
                                            commission_rate={record.commission_rate}
                                            commission_amount={record.commission_amount}
                                            ifEmptyText={
                                                <Text type="secondary">Commission isn't set</Text>
                                            }
                                        />
                                    </div>
                                )}
                            </li>
                        ))}
                    </ol>
                ) : (
                    <Alert
                        message={`No ${listLabel} record is found, click the Edit button to start adding it`}
                        type="info"
                        showIcon
                    />
                )}
            </Col>
            <Divider />
            <Col span={24}>
                <span
                    className={`${
                        dataList?.length ? 'text-action-primary' : 'text-action-disabled'
                    }`}
                    onClick={() =>
                        _openPartyModal(
                            false,
                            seeAllLabel,
                            listName,
                            recordColumnName,
                            listPrimaryID
                        )
                    }
                >
                    {seeAllLabel}
                </span>
            </Col>
        </>
    );
};

export const PartiesSection: React.FC<{
    contractID: number;
    reloadSubTabData: () => void;
    transactionData: TransactionDetailType | undefined;
    isRentalProperty: boolean;
}> = ({ contractID, reloadSubTabData, transactionData, isRentalProperty }) => {
    const [form] = Form.useForm();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [modalTitle, setModalTitle] = useState('');
    const [listName, setListName] = useState('');
    const [listFullnameColumnName, setListFullnameColumnName] = useState('');
    const [listPrimaryID, setListPrimaryID] = useState('');

    const _onSubmitForm = async (formValues?: any) => {
        if (isSubmittingForm) return;
        setIsSubmittingForm(true);
        updateTransactionDetail(contractID, formValues, 'general_information')
            .then(async resp => {
                if (reloadSubTabData) {
                    reloadSubTabData();
                }

                showNotification(
                    'success',
                    'New data changes on general information have been saved successfully'
                );
                setIsEditMode(false);
            })
            .finally(() => {
                setIsSubmittingForm(false);
            });
    };

    const _openPartyModal = (
        isEdit: boolean,
        modalTitle: string,
        listName: string,
        listFullnameColumnName: string,
        listPrimaryID: string
    ) => {
        setIsEditMode(isEdit ? true : false);
        setIsModalVisible(true);
        setModalTitle(modalTitle);
        setListName(listName);
        setListFullnameColumnName(listFullnameColumnName);
        setListPrimaryID(listPrimaryID);
    };

    useEffect(() => {
        if (isEditMode) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    return (
        <>
            <ModalEditTransactionParty
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                transactionData={transactionData}
                isEditMode={isEditMode}
                modalTitle={modalTitle}
                listName={listName}
                listPrimaryID={listPrimaryID}
                listFullnameColumnName={listFullnameColumnName}
                reloadSubTabData={reloadSubTabData}
            />
            {!isRentalProperty && (
                <>
                    <Col span={8}>
                        <Row gutter={rowFormViewGutter}>
                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_buyers'}
                                listPrimaryID={'buyer_id'}
                                recordColumnName={'buyer_name'}
                                listLabel={'buyer'}
                                seeAllLabel={'See All Buyers Information'}
                                transactionData={transactionData}
                            />

                            <Col span={24} className="">
                                &nbsp;
                            </Col>

                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_buyer_agents'}
                                listPrimaryID={'agent_id'}
                                recordColumnName={'agent_name'}
                                listLabel={`buyer's agent`}
                                seeAllLabel={`See All Buyer's Agents Information`}
                                transactionData={transactionData}
                            />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={rowFormViewGutter}>
                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_sellers'}
                                listPrimaryID={'seller_id'}
                                recordColumnName={'seller_name'}
                                listLabel={`seller`}
                                seeAllLabel={`See All Sellers Information`}
                                transactionData={transactionData}
                            />

                            <Col span={24} className="">
                                &nbsp;
                            </Col>

                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_seller_agents'}
                                listPrimaryID={'broker_id'}
                                recordColumnName={'broker_name'}
                                listLabel={`seller's agent`}
                                seeAllLabel={`See All Seller's Agents Information`}
                                transactionData={transactionData}
                            />
                        </Row>
                    </Col>
                </>
            )}
            {isRentalProperty && (
                <>
                    <Col span={8}>
                        <Row gutter={rowFormViewGutter}>
                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_tenants'}
                                listPrimaryID={'tenant_id'}
                                recordColumnName={'tenant_name'}
                                listLabel={`tenant`}
                                seeAllLabel={`See All Tenants Information`}
                                transactionData={transactionData}
                            />

                            <Col span={24} className="">
                                &nbsp;
                            </Col>

                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_referral_sources'}
                                listPrimaryID={'referral_source_id'}
                                recordColumnName={'referral_source_name'}
                                listLabel={`referral source`}
                                seeAllLabel={`See All Referral Sources Information`}
                                transactionData={transactionData}
                            />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={rowFormViewGutter}>
                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_landlords'}
                                listPrimaryID={'landlord_id'}
                                recordColumnName={'landlord_name'}
                                listLabel={`landlord`}
                                seeAllLabel={`See All Landlords Information`}
                                transactionData={transactionData}
                            />

                            <Col span={24} className="">
                                &nbsp;
                            </Col>

                            <PartyComponent
                                setModalTitle={setModalTitle}
                                openPartyModal={_openPartyModal}
                                isModalVisible={isModalVisible}
                                setIsModalVisible={setIsModalVisible}
                                listName={'contract_leasing_agents'}
                                listPrimaryID={'leasing_agent_id'}
                                recordColumnName={'leasing_agent_name'}
                                listLabel={`leasing agent`}
                                seeAllLabel={`See All Leasing Agents Information`}
                                transactionData={transactionData}
                            />
                        </Row>
                    </Col>
                </>
            )}
        </>
    );
};
