import { SubscriptionTypeItem } from 'types/payment';

export const ALLOWED_CONTENT_TYPES = [
    'text/htm',
    'text/html',

    'text/plain',
    // 'text/csv',

    'image/bmp',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/tiff',

    'application/pdf',

    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ALLOWED_EXTENSIONS = [];

export const ALLOWED_DOCUSIGN_TYPES = [
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'htm',
    'html',
    'msg',
    'pdf',
    'rtf',
    'txt',
    'wpd',
    'xps',
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'tif',
    'tiff',
    'pot',
    'potx',
    'pps',
    'ppt',
    'pptm',
    'pptx',
    'csv',
    'xls',
    'xlsm',
    'xlsx',
];

export const SIMPLE_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
