import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { PARTY_TYPES } from 'types/transaction';
import { Mixpanel } from 'utils/mixPanelUtil';
import { HOTJAR_EVENT_TYPE, hotjarEvent } from 'utils/3rd_party_api/hotjarUtil';

export type DetailType =
    | 'all'
    | 'basic_info'
    | 'contract_info'
    | 'general_information'
    | 'contacts'
    | 'documents'
    | 'tasks'
    | 'notes'
    | undefined;

export const getAllTransactions = (list_type: string, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transactions?list_type=${list_type}&${params}`,
    });
};

export const getTransactionParties = (partyType: PARTY_TYPES, keyword?: string) => {
    const params: string = qs.stringify({
        party_type: partyType,
        keyword: keyword,
    });

    return APIRequest({
        method: 'get',
        url: apiUrl + 'transaction/parties?' + params,
    });
};

export const getTransactionDetail = (contract_id: number, detail_type?: DetailType) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}?detail_type=` + (detail_type || 'basic_info'),
    });
};
export const getQuickStatByContractIDs = (contract_ids: number[]) => {
    const params: string = qs.stringify({
        contract_ids: contract_ids,
    });

    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/quick-stat-by-ids?${params}`,
    });
};

export const addNewTransaction = (payload: any) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `transactions`,
        data: payload,
        useDefaultErrorNotif: true,
    }).then(resp => {
        hotjarEvent(HOTJAR_EVENT_TYPE.ADD_NEW_TRANSACTION);
        Mixpanel.track('app.add_transaction', {
            contract_id: resp?.data?.contract_id,
            ...payload,
        });
        return resp;
    });
};

export const updateTransactionDetail = (
    contract_id: number,
    payload: any,
    detail_type?: DetailType
) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        section: 'basic',
        detail_type,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}?detail_type=` + (detail_type || 'basic_info'),
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const getTransactionQuickStats = (contract_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}?detail_type=quick_stats`,
    });
};

export const updateTransactionParty = (
    contract_id: number | undefined,
    party_id: number | string,
    party_type: PARTY_TYPES,
    payload: any
) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'contract_information',
        action: 'update_transaction_party',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/party/${party_id}?party_type=` + party_type,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteTransactionParty = (
    contract_id: number | undefined,
    party_id: number | string,
    party_type: PARTY_TYPES
) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'contract_information',
        action: 'delete_transaction_party',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/party/${party_id}?party_type=` + party_type,
        useDefaultErrorNotif: true,
    });
};

export const updateCustomFieldsByCategory = (
    contract_id: number,
    category_id: number,
    payload: any
) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'contract_information',
        action: 'update_custom_fields',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/custom-fields-category/${category_id}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const getGreensheet = (contract_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/greensheet`,
        useDefaultErrorNotif: true,
    });
};

export const updateGreensheet = (contract_id: number, payload: any) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        section: 'green_sheet',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/greensheet`,
        data: payload,
    });
};

export const archiveTransaction = (contract_id: number) => {
    Mixpanel.track('app.archive_transaction', {
        contract_id,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/archive`,
        useDefaultErrorNotif: true,
    });
};

export const requestTransactionBackup = (contract_id: number) => {
    Mixpanel.track('app.backup_transaction', {
        contract_id,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/request-backup`,
        useDefaultErrorNotif: true,
    });
};
