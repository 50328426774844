export const NODE_ENV = process.env.NODE_ENV;

export const facebookID = process.env.REACT_APP_Facebook_ID ?? '';
export const googleID = process.env.REACT_APP_Google_ID ?? '';
export const microsoftID = process.env.REACT_APP_Microsoft_ID ?? '';
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI ?? '';
export const serverUrl = process.env.REACT_APP_Server_URL ?? '';
export const TinyMceUrl = process.env.REACT_APP_TINYMCE ?? '';
export const googleApiKey = process.env.REACT_APP_Google_apiKey ?? '';
export const microsoftTenantID = process.env.REACT_APP_TENANT_ID ?? '';
export const microsoftClientSecret = process.env.REACT_APP_CLIENT_SECRET ?? '';

export const GOOGLE_CLIENTID = process.env.REACT_APP_Google_ClientID ?? '';
export const GOOGLE_CLIENTSECRET = process.env.REACT_APP_Google_ClientSecret ?? '';

export const SENTRY_DSN =
    process.env.REACT_APP_SENTRY_DSN ||
    'https://2e3a30c0ae0f41a6b667b6d70dcbf0b8@o4505152428376064.ingest.sentry.io/4505232098787328';
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || 'local-development';

Object.entries({
    facebookID: facebookID,
    googleID: googleID,
    microsoftID: microsoftID,
    REDIRECT_URI: REDIRECT_URI,
    serverUrl: serverUrl,
    TinyMceUrl: TinyMceUrl,
    googleApiKey: googleApiKey,
    microsoftTenantID: microsoftTenantID,
    microsoftClientSecret: microsoftClientSecret,
}).forEach(([key, val]) => {
    if (val.trim().length < 3) throw new Error(`Environment vaiable ${key} not defined.`);
});
export const apiUrl = serverUrl + 'api/';
// export const enableMock: boolean = process.env.REACT_APP_MockEnabled ? process.env.REACT_APP_MockEnabled === "true" : false;
export const enableMock: boolean = false;

export const checkingUpgrade = serverUrl === 'https://beta.ezcoordinator.com/';

export enum Ez_Email_Type {
    Client = 'Client',
    Listing = 'Listing',
    Transaction = 'Transaction',
}

export const msalConfig = {
    auth: {
        clientId: microsoftID,
        authority: `https://login.microsoftonline.com/common/`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // redirectUri: REDIRECT_URI,
        // redirectUri: 'https://localhost:8080/api/email-credential/process-msgraph-auth-code',
        redirectUri: 'https://localhost:3333/',
        clientSecret: microsoftClientSecret,
        clientType: 'Single-page-application',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const MSloginRequest = {
    clientSecret: microsoftClientSecret,
    clientId: microsoftID,
    // scopes: ['user.read', 'mail.read', 'mail.send', 'mail.readwrite'],

    scopes: ['profile', 'User.Read', 'Mail.Send', 'Mail.Read', 'openid', 'offline_access'],
};

export const isDemoENV = () => {
    return process.env.REACT_APP_IS_DEMO && process.env.REACT_APP_IS_DEMO === 'true' ? true : false;
};

export const isProductionENV = () => {
    return process.env.NODE_ENV === 'production';
};
