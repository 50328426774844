// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Space,
    Menu,
    Button,
    Tabs,
    Typography,
    Dropdown,
    Tag,
    Tooltip,
    Popover,
    Divider,
    Select,
    UploadProps,
    Alert,
    Popconfirm,
    List,
    Avatar,
    UploadFile,
    Collapse,
    Progress,
    message,
    Modal,
    Skeleton,
    Pagination,
    Input,
    Table,
} from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import {
    DeleteOutlined,
    DownOutlined,
    FieldTimeOutlined,
    InboxOutlined,
    DeliveredProcedureOutlined,
    CarryOutOutlined,
    DeleteFilled,
    CheckCircleOutlined,
    CheckCircleFilled,
    RightOutlined,
    MessageOutlined,
    FolderViewOutlined,
    CloseOutlined,
    EllipsisOutlined,
    SaveFilled,
    DownloadOutlined,
    FileSearchOutlined,
    PlusOutlined,
    InfoCircleFilled,
    EditOutlined,
    SaveOutlined,
    AuditOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import fileDownload from 'js-file-download';
import qs from 'qs';

// EZ web-app utils import
import { downloadFileRequest } from 'utils/apiUtil';
// EZ web-app APIs import
import {
    addNewNote,
    addNewTransactionDoc,
    deleteDocument,
    deleteDocuments,
    downloadLegacyDocument,
    getPresignedURLContractDocUpload,
    getPresignedURLDoc,
    getPresignedURLDocsRead,
    getTransactionDocs,
    getTransactionFolders,
    linkDocsToTasks,
    moveSelectedDocs,
    updateDocument,
} from 'utils/ez_api/documentAPIs';
// EZ web-app types import
import {
    DocFolderType,
    DocNotesType,
    TransactionDocItemType,
    TransactionTaskItemType,
} from 'types/transaction';
import { DocViewerType } from 'types/document';
// EZ web-app components import
import { EZDateFormat, EZSelectTask, EZTable, EZUpload, UploadProgress } from 'components';
import {
    forwardRefEZTableProps,
    IOnChangeCallbackProps,
    ITableQueryParams,
} from 'components/table/EZProTable';
import { formatBytes, getFileExtension, getInitialFromFullname } from 'utils/commonUtil';
import { EZUploadProps } from 'components/upload/upload';
import { ALLOWED_CONTENT_TYPES, ALLOWED_DOCUSIGN_TYPES } from 'static/consts';
import { showMessage, showNotification } from 'utils/notificationUtil';
import { getTransactionTasks } from 'utils/ez_api/taskAPIs';
import { EsignatureModalContent } from './modals/esignatureModal';
import { HomeContext } from 'context/EZContext';
import { isDemoENV } from 'utils/envUtil';
import { updateOnboardingStep } from 'utils/onboardingUtil';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [],
    // orderParams: [['$doc_folder.rank$', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

const defaultTableParamMainTaskMenu: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [],
    // orderParams: [['$doc_folder.rank$', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

export enum DOC_EMBEDDED_TYPE {
    TRANSACTION_DETAIL = 'transaction_detail',
    MAIN_MENU = 'main_menu',
    TRANSACTION_LIST = 'transaction_list',
}

export type DocType = 'contract_document' | 'doc_library' | 'prospect_document';

const DocViewerWrapper: React.FC<{
    docType: DocType;
    fileListIDs: {
        file_id: number;
        contract_id?: number;
        prospect_id?: number;
    }[];
}> = ({ docType, fileListIDs }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState<DocViewerType[]>([]);
    const [activeDocIndex, setActiveDocIndex] = useState(0);
    const [activeDoc, setActiveDoc] = useState<DocViewerType>();

    const _fetchPresignURLs = () => {
        setIsLoading(true);
        getPresignedURLDocsRead(docType, fileListIDs)
            .then(({ data }) => {
                const docList = (data as DocViewerType[]).map(doc => {
                    doc.uri = doc.presignedURL;
                    doc.fileType = doc.content_type;

                    return doc;
                });

                setDocuments(docList);

                if (docList.length) {
                    setActiveDocIndex(0);
                    setActiveDoc(docList[activeDocIndex]);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (activeDocIndex >= 0 && documents[activeDocIndex]) {
            setActiveDoc(documents[activeDocIndex]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocIndex]);

    useEffect(() => {
        // get presign urls from AWS S3
        _fetchPresignURLs();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Row className="mb-4">
                <Col span={16}>
                    Previewing: {activeDoc?.filename}
                    {activeDoc?.key ? ` (${formatBytes(activeDoc?.size || 0)})` : ''}
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                    {documents.length > 1 ? (
                        <Pagination
                            simple
                            defaultCurrent={activeDocIndex + 1}
                            total={documents.length}
                            onChange={page => {
                                setActiveDocIndex(page - 1);
                            }}
                        />
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
            {!isLoading && activeDoc && ALLOWED_CONTENT_TYPES.includes(activeDoc.content_type) ? (
                <DocViewer
                    config={{
                        header: {
                            disableHeader: true,
                        },
                    }}
                    documents={activeDoc ? [activeDoc] : []}
                    pluginRenderers={DocViewerRenderers}
                    style={{ minHeight: '520px' }}
                />
            ) : (
                ''
            )}
            {!isLoading &&
            (!activeDoc || !ALLOWED_CONTENT_TYPES.includes(activeDoc.content_type)) ? (
                <Alert
                    description="Preview is unavailable for the selected document(s)."
                    showIcon
                    type="info"
                />
            ) : (
                ''
            )}
            {isLoading && <Skeleton active />}
        </div>
    );
};

export const previewDoc = (
    docType: 'contract_document' | 'doc_library' | 'prospect_document',
    fileListIDs: {
        file_id: number;
        contract_id?: number;
        prospect_id?: number;
        accountfolder_id?: number;
    }[]
) => {
    Modal.success({
        title: <b>Document Viewer</b>,
        width: '95%',
        icon: <></>,
        okText: 'Close',
        style: {
            top: '30px',
        },
        closable: true,
        maskClosable: true,
        content: (
            <div>
                <DocViewerWrapper docType={docType} fileListIDs={fileListIDs} />
            </div>
        ),
        onOk() {},
    });
};

const EditNoteComponent: React.FC<{
    record: TransactionDocItemType;
    saveNote: (document: TransactionDocItemType, note: string) => Promise<any>;
}> = ({ record, saveNote }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [noteContent, setNoteContent] = useState('');

    const _saveNote = () => {
        if (noteContent.trim().length > 0) {
            saveNote(record, noteContent.trim()).then(() => {
                setIsVisible(false);
                setNoteContent('');
            });
        }
    };

    useEffect(() => {
        setNoteContent('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Popover
            visible={isVisible}
            content={
                <div className="add-new-note-popover">
                    <Row gutter={[0, 10]}>
                        <Col span={24}>
                            <Input.TextArea
                                rows={3}
                                value={noteContent}
                                onChange={e => setNoteContent(e?.currentTarget?.value)}
                            ></Input.TextArea>
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                className="mr-2"
                                onClick={() => setIsVisible(false)}
                            >
                                Close
                            </Button>
                            <Button type="primary" onClick={() => _saveNote()}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
            overlayClassName=""
            title={
                <>
                    <span>Add New Note for Document: </span>
                    <br />
                    <span>{record.filename}</span>
                </>
            }
            trigger={'click'}
            showArrow={false}
        >
            <Button
                className=""
                size="small"
                type="link"
                icon={<PlusOutlined />}
                onClick={() => setIsVisible(true)}
            >
                Add Note
            </Button>
        </Popover>
    );
};

export const AppPageDocuments: React.FC<{
    pageType: DOC_EMBEDDED_TYPE;
    contractID?: number;
    manualReloadCount: number;
    reloadTransactionStat?: (contract_id: number) => void;
}> = ({ contractID = -1, manualReloadCount, pageType, reloadTransactionStat }) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const EZTableRef = useRef<forwardRefEZTableProps>();

    const isMainMenuTaskTab = !contractID || contractID <= 0;
    const [docFolders, setDocFolders] = useState<DocFolderType[]>([]);
    const [docIDsEdit, setDocIDsEdit] = useState<
        {
            docID: number;
        }[]
    >([]);

    const [fileUploadList, setFileUploadList] = useState<UploadFile[]>([]);
    const [taskList, setTaskList] = useState<TransactionTaskItemType[]>([]);
    const [selectedTaskListBulk, setSelectedTaskListBulk] = useState<TransactionTaskItemType[]>([]);

    const getDefaultTableParam = (isMainMenuTaskTab: boolean): ITableQueryParams => {
        return isMainMenuTaskTab ? defaultTableParamMainTaskMenu : defaultTableParam;
    };
    const [queryParams, setQueryParams] = useState<ITableQueryParams>(
        getDefaultTableParam(isMainMenuTaskTab)
    );
    const [docCompletionTypeFilter, setDocCompletionTypeFilter] = useState<string>(
        isMainMenuTaskTab ? 'unchecked_docs' : 'all_docs'
    );
    const [filterType, setFilterType] = useState<string>('today');
    const [docUploadFolderID, setDocUploadFolderID] = useState<number>();
    const [docFolderFilter, setDocFolderFilter] = useState<number>();
    const [bulkLinkTasksVisible, setBulkLinkTasksVisible] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    // const [isUpdatingDoc, setIsUpdatingDoc] = useState<boolean>(false);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getTransactionDocs(contractID, EZTableQueryParams).then(result => result);
    }

    const _handleFileUploadChange: UploadProps['onChange'] = ({ file, fileList }) => {
        // TODO: Find the alternative solution as the setState for array will trigger re-rendering the table, which is bad for user experience due to unresponsive scroll
        setFileUploadList([...fileList]);

        if (file.status === 'done') {
            if (contractID) {
                addNewTransactionDoc(contractID, {
                    folder_id: docUploadFolderID,
                    filename: file.name,
                    u_filename: file.response?.newFilename,
                    bucket: file.response?.Bucket,
                    key: file.response?.Key,
                    size: file.size,
                    content_type: file.type,
                }).then(() => {
                    setQueryParams({
                        ...queryParams,
                        resetPagination: false,
                    });
                    // update onboarding step to completed
                    updateOnboardingStep('add_documents_or_files', HomeCtx.refetchOnboardingList);
                });
                setFileUploadList(fileList.filter(f => f.uid !== file.uid));

                reloadTransactionStat?.(contractID);
            }
        }
    };

    async function _fetchPresignURL(payload: any): Promise<any> {
        return getPresignedURLContractDocUpload(contractID, payload);
    }

    const _onChangeCallback: IOnChangeCallbackProps = () => {};

    const _reloadSubTabData = (resetPagination?: boolean, silentLoading?: boolean) => {
        setQueryParams({
            ...queryParams,
            resetPagination,
            silentLoading: silentLoading || undefined,
        });
    };

    const _fetchDocFolders = () => {
        getTransactionFolders(contractID).then(resp => {
            if (resp.data) {
                setDocFolders(resp.data);
                setDocUploadFolderID(resp.data?.[0]?.folder_id);
            }
        });
    };

    const _fetchTasks = () => {
        getTransactionTasks(
            contractID,
            qs.stringify({
                pageSize: 500,
            })
        ).then(result => {
            setTaskList(result?.data || []);
        });
    };

    const _downloadDocument = (doc: TransactionDocItemType) => {
        showMessage('success', 'Download has been initiated. Please wait...');

        // if bucket and doc property has value, the doc will be downloaded from AWS S3
        if (doc.bucket && doc.key) {
            getPresignedURLDoc('contract_document', doc.doc_id, 'download').then(resp => {
                downloadFileRequest(resp.data?.presignedURL, doc.filename || 'untitled');
            });
        } else {
            downloadLegacyDocument('contract_document', doc.doc_id).then((resp: any) => {
                fileDownload(resp, doc.filename || 'untitled');
            });
        }
    };

    const _showEsignatureModal = (doc: TransactionDocItemType) => {
        const docuSignModal = Modal.info({
            title: (
                <>
                    E-signature for document/file: <b>{doc.filename}</b>
                </>
            ),
            content: <EsignatureModalContent contractID={contractID} doc={doc} />,
            okButtonProps: {
                style: {
                    display: 'none',
                },
            },
            className: 'modal-wrapper-docusign',
            onOk: () => {
                return true;
            },
            width: '1100px',
            closable: true,
            maskClosable: true,
            cancelText: 'Close',
        });
    };

    const _initiateDocuSign = (doc: TransactionDocItemType) => {
        if (!ALLOWED_DOCUSIGN_TYPES.includes(getFileExtension(doc.filename) || '')) {
            showMessage(
                'error',
                <span>
                    Invalid file. Please see all the supporting file types{' '}
                    <a
                        href="https://support.docusign.com/s/document-item?language=en_US&rsc_301&bundleId=gbo1643332197980&topicId=xln1578456261162.html&_LANG=enus"
                        target={'_blank'}
                    >
                        here
                    </a>
                    .
                </span>
            );

            return;
        }

        _showEsignatureModal(doc);
    };

    const _saveDocumentRecord = async (
        newData: TransactionDocItemType,
        oldData: TransactionDocItemType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        // setDocIDsEdit([...docIDsEdit, { docID: newData.doc_id }]);

        if (newData.filename === '' || newData.filename === null) {
            newData.filename = oldData.filename;
        }

        if (newData.doc_id) EZTableRef.current?.modifyData(newData, newData.doc_id);

        return updateDocument(
            oldData.contract_id,
            newData.doc_id,
            {
                ...newData,
            },
            formType
        )
            .then(() => {
                if (contractID) {
                    reloadTransactionStat?.(contractID);
                }

                if (newData.status === 1 && oldData.status === 0) {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been marked as checked.`
                    );
                } else if (newData.status === 0 && oldData.status === 1) {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been marked as unchecked.`
                    );
                } else {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been successfully updated.`
                    );
                }

                if (oldData.folder_id !== newData.folder_id) {
                    _reloadSubTabData(false, true);
                }

                return newData;
            })
            .catch(err => {
                throw err;
            });
    };

    const _applyFilter = (
        filterType: string,
        docCompletionTypeFilter: string,
        docFolderFilter?: number
    ) => {
        const currentWhereFilterParams: any = {};
        // const currentWhereFilterParams = { ...queryParams.whereFilterParams };

        switch (docCompletionTypeFilter) {
            case 'checked_docs':
                currentWhereFilterParams['status'] = 1;
                break;
            case 'all_docs':
                currentWhereFilterParams['status'] = undefined;
                break;
            case 'unchecked_docs':
                currentWhereFilterParams['status'] = 0;
                break;

            default:
                break;
        }

        if (docFolderFilter === -1) {
            if (currentWhereFilterParams['$doc_folder.folder_id$']) {
                delete currentWhereFilterParams['$doc_folder.folder_id$'];
            }
        } else if (docFolderFilter) {
            currentWhereFilterParams['$doc_folder.folder_id$'] = docFolderFilter;
        }

        setQueryParams({
            ...queryParams,
            whereFilterParams: { ...currentWhereFilterParams },
            resetPagination: true,
        });
    };

    const _columns: ProColumns<TransactionDocItemType>[] = [
        {
            title: 'Quick Info',
            dataIndex: 'status',
            width: isMainMenuTaskTab ? '60px' : '65px',
            sorter: false,
            editable: false,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.doc_notes?.length || 0} note(s)`}>
                        <Tag
                            className={`tag-rounded ${
                                record.doc_notes?.length ? '' : 'color-secondary'
                            }`}
                            onClick={() => _expandSelectedRows([record.doc_id])}
                        >
                            <MessageOutlined /> {record.doc_notes?.length || 0}
                        </Tag>
                    </Tooltip>
                    <Tooltip title={`${record.task_todolists?.length || 0} linked task(s)`}>
                        <Tag
                            className={`tag-rounded ${
                                record.task_todolists?.length ? '' : 'color-secondary'
                            }`}
                            onClick={() => _expandSelectedRows([record.doc_id])}
                        >
                            <CarryOutOutlined /> {record.task_todolists?.length || 0}
                        </Tag>
                    </Tooltip>
                    <Tooltip
                        title={
                            record.review_status === 2
                                ? 'This document/file has been approved'
                                : "This document/file hasn't yet been approved "
                        }
                    >
                        <Tag
                            className={`mr-0 tag-rounded ${
                                record.review_status === 2 ? '' : 'color-secondary'
                            }`}
                        >
                            <FolderViewOutlined />
                        </Tag>
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Checked?',
            dataIndex: 'status',
            width: isMainMenuTaskTab ? '40px' : '35px',
            sorter: false,
            editable: false,
            align: 'center',
            render: (_, record) => (
                <>
                    <Button
                        className="mr-1"
                        size="small"
                        type="default"
                        onClick={e => {
                            if (UserPermission && UserPermission?.documents_toggle !== 2) return;
                            _saveDocumentRecord(
                                { ...record, status: record.status === 1 ? 0 : 1 },
                                record
                            );
                        }}
                    >
                        {record.status === 1 ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <CheckCircleOutlined />
                        )}
                    </Button>
                </>
            ),
        },
        {
            title: 'Document Name',
            dataIndex: 'filename',
            width: isMainMenuTaskTab ? '170px' : '140px',
            sorter: true,
            // ellipsis: true,
            fixed: 'left',
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.filename}
                    >
                        {record.filename || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Folder',
            dataIndex: 'folder_id',
            width: isMainMenuTaskTab ? '100px' : '100px',
            sorter: true,
            // hideInTable: docFolderFilter && docFolderFilter > 0 ? true : false,
            valueType: 'select',
            fieldProps: {
                options: docFolders.map((item, idx) => ({
                    label: item.doc_folder_preference?.folder_name || item.folder_name,
                    key: item.folder_id,
                    value: item.folder_id,
                })),
            },
            render: (_, record) => (
                <>
                    <Tag className={`${record.status === 1 ? '' : ''}`}>
                        {record.doc_folder?.doc_folder_preference?.folder_name ||
                            record.doc_folder?.folder_name}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: isMainMenuTaskTab ? '230px' : '200px',
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.description}
                    >
                        {record.description || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Uploaded By',
            dataIndex: 'account.fullname',
            width: isMainMenuTaskTab ? '90px' : '70px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.account?.fullname}
                    >
                        {record.account?.fullname || record.sender_email || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Uploaded On',
            dataIndex: 'posted_date',
            width: isMainMenuTaskTab ? '90px' : '80px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text type={record.status === 1 ? 'secondary' : undefined}>
                        <EZDateFormat value={record.date_uploaded} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: isMainMenuTaskTab ? '120px' : '85px',
            valueType: 'option',
            // hideInTable: UserPermission && UserPermission?.documents_toggle === 2 ? false : true,
            render: (_, record, index, action) =>
                UserPermission && UserPermission?.documents_toggle === 2 ? (
                    <Space size="small" className="pl-1">
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<FieldTimeOutlined />}
                            title="Quick-edit this document"
                            onClick={() => {
                                action?.startEditable(record.doc_id);
                            }}
                        >
                            Quick Edit
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            className="p-0 mr-1"
                            size="small"
                            type="link"
                            icon={<FileSearchOutlined />}
                            title="Preview"
                            onClick={() => {
                                previewDoc('contract_document', [
                                    {
                                        file_id: record.doc_id,
                                        contract_id: contractID,
                                    },
                                ]);
                            }}
                        ></Button>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={() => _downloadDocument(record)}>
                                        <DownloadOutlined className="mr-3" />
                                        <span className="">Download</span>
                                    </Menu.Item>
                                    <Menu.Item key="1" onClick={() => _initiateDocuSign(record)}>
                                        <AuditOutlined className="mr-3" />
                                        <span className="">Send to DocuSign</span>
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item
                                        key="2"
                                        danger
                                        onClick={() => _deleteDocument(record)}
                                    >
                                        <DeleteOutlined className="mr-3" />
                                        Delete Document
                                    </Menu.Item>
                                </Menu>
                            }
                            placement="bottomRight"
                        >
                            <span onClick={e => e.preventDefault()}>
                                <Button size="small" type="link" icon={<EllipsisOutlined />} />
                            </span>
                        </Dropdown>
                    </Space>
                ) : (
                    <Space size="small" className="pl-1">
                        {' '}
                        <Button
                            className="p-0 mr-1"
                            size="small"
                            type="link"
                            icon={<FileSearchOutlined />}
                            title="Preview"
                            onClick={() => {
                                previewDoc('contract_document', [
                                    {
                                        file_id: record.doc_id,
                                        contract_id: contractID,
                                    },
                                ]);
                            }}
                        ></Button>
                    </Space>
                ),
            fixed: 'right',
        },
    ];

    const _expandSelectedRows = (rowKeys: number[]) => {
        const newRowIDs: number[] = [];
        for (let index = 0; index < rowKeys.length; index++) {
            const rowID = rowKeys[index];

            if (!expandedRecordIDs.find(elm => elm === rowID)) newRowIDs.push(rowID);
        }

        if (newRowIDs.length) setExpandedRecordIDs([...expandedRecordIDs, ...newRowIDs]);
    };

    const _expandIcon: RenderExpandIcon<TransactionDocItemType> = ({
        expanded,
        onExpand,
        record,
    }) => {
        return expanded ? (
            <DownOutlined
                id={`ez-tr-icon-expandable-c_${contractID}-doc_${record.doc_id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        ) : (
            <RightOutlined
                id={`ez-tr-icon-expandable-c_${contractID}-doc_${record.doc_id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        );
    };

    const _saveDocNote = async (record: TransactionDocItemType, note: string) => {
        return addNewNote(contractID, record.doc_id, note).then(resp => {
            const note = resp.data as DocNotesType;

            record.doc_notes?.unshift(note);

            if (record.doc_id) EZTableRef.current?.modifyData(record, record.doc_id);

            return resp.data;
        });
    };

    const _deleteDocument = (record: TransactionDocItemType) => {
        if (isDemoENV()) {
            showMessage('info', 'Delete document function is not available on Demo server.');

            return;
        }

        const deleteConfirmationModal = Modal.confirm({
            title: `Delete confirmation`,
            content: (
                <>
                    Are you sure to delete document/file: <b>{record.filename}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                deleteDocument(record.contract_id, record.doc_id).then(() => {
                    deleteConfirmationModal.destroy();
                    if (reloadTransactionStat && contractID) {
                        reloadTransactionStat(contractID);
                    }

                    showNotification(
                        'success',
                        `Document/file: "${record.filename}" has been successfully deleted.`
                    );
                    _reloadSubTabData(false, true);
                });
                return true;
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _deleteDocuments = (docIDs: number[]) => {
        if (isDemoENV()) {
            showMessage('info', 'Delete documents function is not available on Demo server.');

            return;
        }
        deleteDocuments(contractID, docIDs).then(() => {
            showNotification(
                'success',
                `${docIDs.length} selected docs have been successfully deleted.`
            );
            setImmediate(() => {
                if (reloadTransactionStat && contractID) {
                    reloadTransactionStat(contractID);
                }

                _reloadSubTabData(false, true);

                setSelectedRowKeys([]);
            });
        });
    };

    const _doBulkAction_moveDocs = (
        selectedRowKeys: React.Key[],
        newFolderID: number,
        newFolderTitle?: string
    ) => {
        const noOfKeys = selectedRowKeys.length;

        moveSelectedDocs(contractID, selectedRowKeys as number[], newFolderID).then(resp => {
            showNotification(
                'success',
                `${noOfKeys} selected doc(s) have been moved to ${newFolderTitle}.`
            );
            setImmediate(() => {
                _reloadSubTabData(false, true);

                setSelectedRowKeys([]);
            });
        });
    };

    const _doBulkAction_linkTasks = (
        selectedRowKeys: React.Key[],
        selectedTasks: TransactionTaskItemType[]
    ) => {
        const noOfKeys = selectedRowKeys.length;

        linkDocsToTasks(
            contractID,
            selectedRowKeys as number[],
            selectedTasks.map(task => task.id)
        ).then(result => {
            showNotification('success', `Linked tasks for the selected docs have been updated.`);
            setImmediate(() => {
                _reloadSubTabData(false, true);

                setSelectedRowKeys([]);
                setSelectedTaskListBulk([]);
                setBulkLinkTasksVisible(false);
            });
        });
    };

    const ExpandedRowComponent: React.FC<{ record: TransactionDocItemType }> = ({ record }) => {
        const [editMode, setEditMode] = useState<boolean>(false);
        const [tasksEditMode, setTasksEditMode] = useState<boolean>(false);
        const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);

        const [selectedTaskList, setSelectedTaskList] = useState<TransactionTaskItemType[]>([]);

        const _saveSelectedTasks = () => {
            linkDocsToTasks(
                record.contract_id,
                [record.doc_id],
                selectedTaskList.map(task => task.id)
            )
                .then(result => {
                    const tasks = result.data as TransactionTaskItemType[];

                    record.task_todolists = tasks;

                    if (record.doc_id) EZTableRef.current?.modifyData(record, record.doc_id);
                })
                .finally(() => {
                    setTasksEditMode(false);
                });
        };

        const _onChangeSelectedTaskIDs = (selectedTaskIDs: any[], selectedTaskList: any[]) => {
            setSelectedTaskList(selectedTaskList);
        };

        useEffect(() => {
            if (tasksEditMode) {
                // _fetchTasks(); // temporarily disable this as it triggers rerendering the whole table.
                setSelectedTaskList(record.task_todolists || []);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tasksEditMode]);

        useEffect(() => {
            console.log('Updated...');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div className="doc-note-wrapper p-2">
                <div style={{ paddingLeft: '65px' }}>
                    <Row>
                        <Col span={12} className="pr-5">
                            <Row>
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Text strong>Document Notes</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {UserPermission?.documents_toggle === 2 && (
                                                <EditNoteComponent
                                                    record={record}
                                                    saveNote={_saveDocNote}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    {record.doc_notes?.length ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={record.doc_notes}
                                            renderItem={record => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar size={42}>
                                                                {record.account &&
                                                                record.account.fullname
                                                                    ? getInitialFromFullname(
                                                                          record.account.fullname
                                                                      )
                                                                    : 'N/A'}
                                                            </Avatar>
                                                        }
                                                        title={
                                                            <span>
                                                                <Text strong className="mr-1">
                                                                    {record.account?.fullname}
                                                                </Text>
                                                                <Text className="mr-1">
                                                                    {record.account?.email
                                                                        ? `(${record.account.email})`
                                                                        : ``}
                                                                </Text>
                                                                <Text
                                                                    type="secondary"
                                                                    className="mr-2"
                                                                >
                                                                    On{' '}
                                                                    <EZDateFormat
                                                                        value={record.date_posted}
                                                                        format="MM/DD/YYYY - hh:mma"
                                                                    />
                                                                </Text>
                                                            </span>
                                                        }
                                                        description={
                                                            <Paragraph
                                                                style={{ whiteSpace: 'pre-wrap' }}
                                                                ellipsis={{
                                                                    rows: 2,
                                                                    expandable: true,
                                                                }}
                                                                title={`${record.notes}`}
                                                            >
                                                                {record.notes}
                                                            </Paragraph>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Text italic type="secondary">
                                            No note added.&nbsp;
                                            <Tooltip
                                                title={`Click the "Add Note" button to add new note.`}
                                            >
                                                <InfoCircleFilled />
                                            </Tooltip>
                                        </Text>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} className="pl-5">
                            <Row className="">
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Text strong>Linked Tasks</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {!tasksEditMode &&
                                                UserPermission?.documents_toggle === 2 && (
                                                    <>
                                                        <Button
                                                            className=""
                                                            size="small"
                                                            type="link"
                                                            icon={<EditOutlined />}
                                                            onClick={() => setTasksEditMode(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </>
                                                )}

                                            {tasksEditMode && (
                                                <Space>
                                                    <Button
                                                        className="mr-2"
                                                        type="default"
                                                        size="small"
                                                        onClick={() => setTasksEditMode(false)}
                                                        disabled={isSubmittingForm}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        size="small"
                                                        icon={<SaveOutlined />}
                                                        type="primary"
                                                        loading={isSubmittingForm}
                                                        disabled={isSubmittingForm}
                                                        onClick={() => _saveSelectedTasks()}
                                                    >
                                                        {isSubmittingForm ? 'Saving' : 'Save'}
                                                    </Button>
                                                </Space>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    {!tasksEditMode && (
                                        <>
                                            {record.task_todolists?.length ? (
                                                <List
                                                    size="small"
                                                    itemLayout="horizontal"
                                                    bordered={false}
                                                    dataSource={record.task_todolists}
                                                    renderItem={item => (
                                                        <List.Item className="p-0">
                                                            <Space align="center">
                                                                <Text
                                                                    type={
                                                                        item.compl === 1
                                                                            ? 'secondary'
                                                                            : undefined
                                                                    }
                                                                    className={`task-completion-toggle ${
                                                                        item.compl === 1
                                                                            ? 'task-completed'
                                                                            : 'task-not-completed'
                                                                    }`}
                                                                >
                                                                    {item.compl === 1 ? (
                                                                        <CheckCircleFilled />
                                                                    ) : (
                                                                        <CheckCircleOutlined />
                                                                    )}
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        item.compl === 1
                                                                            ? 'secondary'
                                                                            : undefined
                                                                    }
                                                                >
                                                                    <span className="">
                                                                        {item.title}{' '}
                                                                    </span>
                                                                    <span className="">
                                                                        (Due on{' '}
                                                                        <EZDateFormat
                                                                            value={item.duedate}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                        )
                                                                    </span>
                                                                </Text>
                                                            </Space>
                                                        </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <Text italic type="secondary">
                                                    No linked tasks.&nbsp;
                                                    <Tooltip
                                                        title={`Click the edit button to link the tasks.`}
                                                    >
                                                        <InfoCircleFilled />
                                                    </Tooltip>
                                                </Text>
                                            )}
                                        </>
                                    )}
                                    {tasksEditMode && (
                                        <>
                                            <EZSelectTask
                                                options={taskList as any[]}
                                                onChange={(values: any, options: any) =>
                                                    _onChangeSelectedTaskIDs(values, options)
                                                }
                                                notFoundContent={
                                                    <span>
                                                        No added tasks on <b>"Tasks"</b> tab.
                                                    </span>
                                                }
                                                value={selectedTaskList.map(d => d.id)}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    const uploadProps: EZUploadProps = {
        fileList: fileUploadList,
        onChange: _handleFileUploadChange,
        getPresignedURL: _fetchPresignURL,
    };

    useEffect(() => {
        _applyFilter(filterType, docCompletionTypeFilter, docFolderFilter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, docCompletionTypeFilter, docFolderFilter]);

    useEffect(() => {
        if (!isMainMenuTaskTab) {
            _reloadSubTabData();
            _fetchDocFolders();
            _fetchTasks();
        } else {
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    useEffect(() => {
        _fetchTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-component-documents">
            <Row className="row-wrapper">
                {UserPermission && UserPermission?.documents_toggle === 2 && (
                    <Col span={24} className="mb-3">
                        <div>
                            <UploadProgress
                                fileUploadList={fileUploadList}
                                setFileUploadList={setFileUploadList}
                            />
                        </div>
                        <EZUpload dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                        </EZUpload>
                        <Select
                            style={{ textAlign: 'left', width: 300 }}
                            className="mb-2 default-upload-folder-selector"
                            defaultValue={1}
                            disabled={
                                fileUploadList.filter(file => file.status === 'uploading').length >
                                0
                            }
                            onChange={(val: number) => {
                                setDocUploadFolderID(val);
                            }}
                        >
                            {docFolders.map(item => (
                                <Option
                                    value={item.folder_id}
                                    key={`docfolder-select-${item.folder_id}`}
                                >
                                    <span>
                                        <Text>Upload To: </Text>
                                        <Text strong>
                                            {item.doc_folder_preference?.folder_name ||
                                                item.folder_name}
                                        </Text>
                                    </span>
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}
                <Col span={24}>
                    {pageType === DOC_EMBEDDED_TYPE.TRANSACTION_DETAIL && (
                        <Tabs
                            type="editable-card"
                            defaultActiveKey="-1"
                            addIcon={
                                <Tooltip title={`Edit the Document Folders on Admin Area menu.`}>
                                    <SettingOutlined />
                                </Tooltip>
                            }
                            onChange={val => {
                                const docFolderID = parseInt(val.replace('tab-docfolder-', ''));
                                setDocFolderFilter(docFolderID);
                                setSelectedRowKeys([]);
                            }}
                            tabBarExtraContent={
                                <div>
                                    <Select
                                        style={{ textAlign: 'left', width: 300 }}
                                        value={docCompletionTypeFilter}
                                        onChange={val => setDocCompletionTypeFilter(val)}
                                        className="mb-2 ml-3"
                                    >
                                        <Option value="unchecked_docs">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>Marked as unchecked</Text>
                                            </span>
                                        </Option>
                                        <Option value="checked_docs">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>Marked as checked</Text>
                                            </span>
                                        </Option>
                                        <Option value="all_docs">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>All Documents and Files</Text>
                                            </span>
                                        </Option>
                                    </Select>
                                </div>
                            }
                        >
                            <Tabs.TabPane
                                tab="All Documents and Files"
                                key="-1"
                                closable={false}
                            ></Tabs.TabPane>
                            {docFolders.map(item => (
                                <Tabs.TabPane
                                    tab={`${
                                        item.doc_folder_preference?.folder_name || item.folder_name
                                    }`}
                                    key={`tab-docfolder-${item.folder_id}`}
                                    closable={false}
                                ></Tabs.TabPane>
                            ))}
                        </Tabs>
                    )}
                </Col>
                <Col span={24}>
                    <Row
                        className={`table-selected-indicator ${
                            selectedRowKeys?.length ? 'has-row-selected' : 'no-row-selected'
                        }`}
                    >
                        <Col span={24}>
                            <Alert
                                style={{ minWidth: '500px' }}
                                message={
                                    selectedRowKeys && selectedRowKeys.length ? (
                                        <Space>
                                            <Tooltip title="Move the selected doc(s)">
                                                <Dropdown
                                                    trigger={['click']}
                                                    overlay={
                                                        <Menu
                                                            onClick={e =>
                                                                _doBulkAction_moveDocs(
                                                                    selectedRowKeys,
                                                                    parseInt(e.key),
                                                                    docFolders.find(
                                                                        d =>
                                                                            d.folder_id ===
                                                                            parseInt(e.key)
                                                                    )?.folder_name
                                                                )
                                                            }
                                                            items={docFolders.map((item, idx) => ({
                                                                label:
                                                                    item.doc_folder_preference
                                                                        ?.folder_name ||
                                                                    item.folder_name,
                                                                key: item.folder_id,
                                                                disabled:
                                                                    item.folder_id ===
                                                                    docFolderFilter,
                                                            }))}
                                                        />
                                                    }
                                                    placement="bottomLeft"
                                                >
                                                    <Button icon={<DeliveredProcedureOutlined />}>
                                                        Move To
                                                    </Button>
                                                </Dropdown>
                                            </Tooltip>
                                            <Divider type="vertical" />
                                            <Tooltip title="Link to task(s)">
                                                <Popover
                                                    trigger={['click']}
                                                    placement="bottomRight"
                                                    showArrow={false}
                                                    visible={bulkLinkTasksVisible}
                                                    content={
                                                        <>
                                                            <Row
                                                                style={{ minWidth: '650px' }}
                                                                align="middle"
                                                            >
                                                                <Col span={18}>
                                                                    <EZSelectTask
                                                                        style={{
                                                                            maxWidth: '500px',
                                                                            width: '100%',
                                                                        }}
                                                                        maxTagCount="responsive"
                                                                        options={taskList as any[]}
                                                                        notFoundContent={
                                                                            <span>
                                                                                No added tasks on{' '}
                                                                                <b>"Tasks"</b> tab.
                                                                            </span>
                                                                        }
                                                                        onChange={(
                                                                            _: any,
                                                                            options: any
                                                                        ) =>
                                                                            setSelectedTaskListBulk(
                                                                                options
                                                                            )
                                                                        }
                                                                        value={selectedTaskListBulk.map(
                                                                            d => d.id
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    span={6}
                                                                    // className="mt-2"
                                                                    style={{ textAlign: 'right' }}
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        className="ml-2"
                                                                        onClick={() =>
                                                                            _doBulkAction_linkTasks(
                                                                                selectedRowKeys,
                                                                                selectedTaskListBulk
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            !selectedTaskListBulk.length
                                                                        }
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                    <Button
                                                                        type="default"
                                                                        className="ml-2"
                                                                        onClick={() =>
                                                                            setBulkLinkTasksVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                >
                                                    <Button
                                                        icon={<CarryOutOutlined />}
                                                        onClick={() =>
                                                            setBulkLinkTasksVisible(true)
                                                        }
                                                    ></Button>
                                                </Popover>
                                            </Tooltip>
                                            {/* <Tooltip title="Mail the selected doc(s)">
                                                <Button icon={<MailOutlined />}></Button>
                                            </Tooltip>
                                            <Tooltip title="Merge the selected doc(s)">
                                                <Button icon={<MergeCellsOutlined />}></Button>
                                            </Tooltip> */}
                                            <Divider type="vertical" />
                                            <Tooltip title="Remove the selected doc(s)">
                                                <Popconfirm
                                                    arrowPointAtCenter
                                                    title="Are you sure to delete the selected document(s)?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="bottomLeft"
                                                    onConfirm={() =>
                                                        _deleteDocuments(
                                                            selectedRowKeys as number[]
                                                        )
                                                    }
                                                >
                                                    <Button danger icon={<DeleteFilled />}></Button>
                                                </Popconfirm>
                                            </Tooltip>
                                        </Space>
                                    ) : (
                                        ''
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <EZTable
                        ref={EZTableRef}
                        className="eztable-hide-delete-editable"
                        pagination={{
                            pageSize: pageType === DOC_EMBEDDED_TYPE.TRANSACTION_LIST ? 10 : 50,
                            pageSizeOptions:
                                pageType === DOC_EMBEDDED_TYPE.TRANSACTION_LIST
                                    ? [10]
                                    : [20, 50, 100],
                            position: ['bottomLeft', 'topLeft'],
                        }}
                        queryParams={queryParams}
                        columns={_columns}
                        size="small"
                        rowKey="doc_id"
                        fetchData={_fetchData}
                        scroll={{ x: isMainMenuTaskTab ? 2000 : 2000 }}
                        rowSelection={
                            UserPermission && UserPermission?.documents_toggle === 2
                                ? {
                                      selectedRowKeys: selectedRowKeys,
                                      onChange: (newSelectedRowKeys: React.Key[]) => {
                                          setSelectedRowKeys(newSelectedRowKeys);
                                      },
                                      alwaysShowAlert: true,
                                      columnWidth: '20px',
                                      selections: [Table.SELECTION_NONE],
                                  }
                                : false
                        }
                        tableAlertRender={false}
                        toolBarComponents={
                            (pageType !== DOC_EMBEDDED_TYPE.TRANSACTION_LIST &&
                                [
                                    // <EZUpload {...uploadProps} key="ez-upload-1">
                                    //     <Button
                                    //         key={`toolBarComponents-2-task-list`}
                                    //         className="ez-action-btn"
                                    //     >
                                    //         <PlusOutlined />
                                    //         Upload New Document
                                    //     </Button>
                                    // </EZUpload>,
                                ]) ||
                            []
                        }
                        expandable={{
                            columnWidth: '16px',
                            expandedRowRender: (record: TransactionDocItemType) => (
                                <ExpandedRowComponent record={record} />
                            ),
                            rowExpandable: (record: TransactionDocItemType) => {
                                return true;
                            },
                            expandIcon: _expandIcon,
                            expandedRowKeys: expandedRecordIDs,
                            onExpand: (expanded, record: TransactionDocItemType) => {
                                if (expanded) {
                                    setExpandedRecordIDs([...expandedRecordIDs, record.doc_id]);
                                } else {
                                    setExpandedRecordIDs([
                                        ...expandedRecordIDs.filter(id => id !== record.doc_id),
                                    ]);
                                }
                            },
                        }}
                        editable={{
                            actionRender: (_, __, defaultDom) => [
                                defaultDom.save,
                                defaultDom.cancel,
                            ],
                            type: 'multiple',
                            onSave: async (_, data: TransactionDocItemType, oldData) => {
                                return _saveDocumentRecord(
                                    {
                                        ...data,
                                    },
                                    oldData
                                );
                            },
                            saveText: (
                                <>
                                    <Button
                                        className="p-0 pl-1"
                                        type="link"
                                        icon={<SaveFilled />}
                                        title="Edit this doc"
                                    >
                                        Save
                                    </Button>
                                </>
                            ),
                            cancelText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0 text-grey"
                                        type="text"
                                        title="Cancel and back"
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </>
                            ),
                        }}
                        onChangeCallback={_onChangeCallback}
                    />
                </Col>
            </Row>
        </div>
    );
};
