import React, { PropsWithChildren, ReactNode } from 'react';

import { AccountDataType, AccountPermissionType } from 'types/account';
import { TriggerRefetchDataKey } from 'types/triggerRefetchData';
import { TAB_CATEGORY } from 'types/webAppTab';

interface UserContextProviderProps extends PropsWithChildren<any> {
    userInfo: AccountDataType | undefined;
    userPermission: AccountPermissionType | undefined;

    closeTab: (tabKey: string) => void;
    openTransactionDetailTab: (
        contractID: number | string,
        contractAddress: string | undefined,
        contractData: any,
        origin?: string
    ) => void;

    openProspectDetailTab: (
        prospectID: number | string,
        prospectName: string | undefined,
        prospectData: any | undefined
    ) => void;

    openMainTab: (tabKey: TAB_CATEGORY, shiftFocus?: boolean) => void;
    refetchOnboardingList: () => void;
    isOnboadingLoading: boolean;

    triggerRefetchDataList: TriggerRefetchDataKey[];
    setTriggerRefetchDataList: React.Dispatch<React.SetStateAction<TriggerRefetchDataKey[]>>;

    children?: ReactNode;
}

export const HomeContext = React.createContext<UserContextProviderProps>({} as any);

export const UserContextProvider = React.memo((props: UserContextProviderProps) => {
    const {
        children,
        userInfo,
        userPermission,
        openTransactionDetailTab,
        openProspectDetailTab,
        closeTab,
        openMainTab,
        triggerRefetchDataList,
        setTriggerRefetchDataList,
        refetchOnboardingList,
        isOnboadingLoading,
    } = props;

    const contextValue = React.useMemo(
        () => ({
            userInfo,
            userPermission,
            openTransactionDetailTab,
            openProspectDetailTab,
            openMainTab,
            closeTab,
            triggerRefetchDataList,
            setTriggerRefetchDataList,
            refetchOnboardingList,
            isOnboadingLoading,
        }),
        [
            userInfo,
            userPermission,
            openTransactionDetailTab,
            openProspectDetailTab,
            openMainTab,
            closeTab,
            triggerRefetchDataList,
            setTriggerRefetchDataList,
            refetchOnboardingList,
            isOnboadingLoading,
        ]
    );

    return <HomeContext.Provider value={contextValue}>{children}</HomeContext.Provider>;
});
