// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { debounce, forEach, toNumber } from 'lodash';
import moment from 'moment';
import {
    Button,
    Row,
    Col,
    Typography,
    Tooltip,
    Input,
    Select,
    BackTop,
    DatePicker,
    Divider,
    Tabs,
    Form,
    InputRef,
    Tag,
    Table,
    Badge,
} from 'antd';
import {
    FlagTwoTone,
    EyeOutlined,
    EyeInvisibleOutlined,
    UndoOutlined,
    ContainerOutlined,
    CloudServerOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import { RangeValue } from 'rc-picker/es/interface';

// EZ web-app types import
import { DEBOUNCE_DURATION, ITablePaginationConfig } from 'types/base';
import { TransactionTabIcon, ITabContentProps, TAB_CATEGORY } from 'types/webAppTab';
import {
    ACTIVE_STATUS_IDS,
    ITransactionListDataType,
    TransactionListQuickStatType,
} from 'types/transaction';
// EZ web-app components import
import { IOnChangeCallbackProps, ITableQueryParams } from 'components/table/EZProTable';
import {
    EZTable,
    EZPopupable,
    EZInputNumber,
    EZSelectParty,
    EZSelectFlag,
    EZSelectStatus,
    EZSelectPropertyType,
    EZSelectSaleType,
    EZDateFormat,
    EZCurrencyFormat,
} from 'components';
// EZ web-app utils import
import {
    getAllTransactions,
    getQuickStatByContractIDs,
    getTransactionQuickStats,
} from 'utils/ez_api/transactionAPIs';
// EZ web-app pages import
import ViewDetailTransaction from './ViewDetailTransaction';

import './Transaction.scss';
import { AppPageTasks, TASK_EMBEDDED_TYPE } from 'components/appPage/tasks';
import { showMessage } from 'utils/notificationUtil';
import { AppPageNotes, NOTE_EMBEDDED_TYPE } from 'components/appPage/notes';
import { getTransactionStatusCategory, isRentalProperty } from 'utils/webAppUtil';
import { Mixpanel } from 'utils/mixPanelUtil';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';

const { Text, Title } = Typography;
const { Search } = Input;
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

let LastScrollYPosition = 0;

const transactionDateTypes = [
    { label: 'Closing Date', value: 'close_date' },
    { label: 'Contract Date', value: 'contract_date' },
    { label: 'Contract Effective Date', value: 'effective_date' },
    { label: 'Inspection Contingency', value: 'inspection_contingency_date' },
    { label: 'Financing Contingency', value: 'financing_contingency_date' },

    { label: 'Application Date', value: 'application_date' },
    { label: 'Lease Signed Date', value: 'lease_signed_date' },
    { label: 'Lease Start Date', value: 'lease_start_date' },
    { label: 'Lease End Date', value: 'lease_end_date' },
    { label: 'Move In Date', value: 'move_in_date' },
];

const _initialQueryFormValue = {
    searchKeyword: '',
    status_id: [],
    order_by: 'contract_id',
    order_direction: 'desc',

    transaction_date_type: 'contract_date',
    transaction_date_range: null,
    property_types: [],
    sale_types: [],

    price_type: 'salesprice',
    price_type_minimum: '',
    price_type_maximum: '',
    flag_types: [],

    buyers: [],
    sellers: [],
    buyer_agents: [],
    seller_agents: [],
};

async function _fetchData(EZTableQueryParams: string): Promise<any> {
    return getAllTransactions('full', EZTableQueryParams).then(result => result);
}

const TransactionList: React.FC<ITabContentProps> = ({
    appTabs,
    addTab,
    setActiveTabKey,
    isCurrentTabActive,
    data,
}) => {
    const [form] = Form.useForm();
    const searchKeywordElement = useRef<InputRef>(null);
    const [isShiftKeyPressed, setIsShiftKeyPressed] = useState<boolean>(false);
    const [toggleAdvanceFilter, setToggleAdvanceFilter] = useState<boolean>(false);
    const HomeCtx = useContext(HomeContext);

    // const [toggleAllRows, setToggleAllRows] = useState<boolean>(false);
    // const [transactionIDs, setTransactionIDs] = useState<number[]>([]);
    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const [pagination, setPagination] = useState<ITablePaginationConfig>({});
    const [queryParams, setQueryParams] = useState<ITableQueryParams>({
        searchKeyword: '',
        orderParams: [],
        whereParams: {},
        whereFilterParams: {},
    });

    const [transactionByPriceMin, setTransactionByPriceMin] = useState<number>(0);

    const _onChangeCallback: IOnChangeCallbackProps = (
        data: ITransactionListDataType[],
        pagination
    ) => {
        if (data?.length) {
        } else {
            setExpandedRecordIDs([]);
        }

        if (data?.length) {
            const tempData = [...data];

            getQuickStatByContractIDs(data.map(item => item.contract_id)).then(
                respQuickStatRecords => {
                    const quickStatRecords: TransactionListQuickStatType[] =
                        respQuickStatRecords.data;
                    if (quickStatRecords?.length) {
                        // for (let index = 0; index < quickStatRecords.length; index++) {
                        quickStatRecords.forEach(record => {
                            const contract_id = record.contract_id;
                            // const record = quickStatRecords[index];

                            // const originalRecord = tempData.find(
                            //     i => i.contract_id === record.contract_id
                            // );

                            // if (originalRecord) {
                            //     originalRecord.complete_task_count = record.complete_task_count;
                            //     originalRecord.total_task_count =
                            //         record.complete_task_count + record.incomplete_task_count;
                            //     originalRecord.complete_doc_count = record.complete_doc_count;
                            //     originalRecord.total_doc_count =
                            //         record.complete_doc_count + record.incomplete_doc_count;
                            //     originalRecord.total_note_count =
                            //         record.email_msg_count + record.contract_note_count;
                            // }

                            const completeTaskCount = document.getElementById(
                                `tList-contact_id-complete-task-count-${contract_id}`
                            );
                            const totalTaskCount = document.getElementById(
                                `tList-contact_id-total-task-count-${contract_id}`
                            );

                            if (completeTaskCount) {
                                completeTaskCount.innerHTML = record.complete_task_count.toString();
                            }

                            if (totalTaskCount) {
                                totalTaskCount.innerHTML = (
                                    record.complete_task_count + record.incomplete_task_count
                                ).toString();
                            }

                            const completeDocCount = document.getElementById(
                                `tList-contact_id-complete-doc-count-${contract_id}`
                            );
                            const totalDocCount = document.getElementById(
                                `tList-contact_id-total-doc-count-${contract_id}`
                            );
                            if (completeDocCount) {
                                completeDocCount.innerHTML = record.complete_doc_count.toString();
                            }

                            if (totalDocCount) {
                                totalDocCount.innerHTML = (
                                    record.complete_doc_count + record.incomplete_doc_count
                                ).toString();
                            }

                            const totalNoteCount = document.getElementById(
                                `tList-contact_id-total-note-count-${contract_id}`
                            );
                            if (totalNoteCount) {
                                totalNoteCount.innerHTML = (
                                    record.email_msg_count + record.contract_note_count
                                ).toString();
                            }
                        });
                        // }
                    }
                }
            );
        }

        setPagination(pagination);
    };

    const _onClick = (record: ITransactionListDataType, subTabKey?: string) => {
        const tabTitle = record.address || "Address isn't set";

        const activeTransactionRecordTab = appTabs.find(
            tab =>
                tab.recordId === record.contract_id && tab.tabCategory === TAB_CATEGORY.TRANSACTION
        );

        //check whether the selected record has already an active tab
        if (activeTransactionRecordTab) {
            if (isShiftKeyPressed === false) setActiveTabKey(activeTransactionRecordTab.key);
            if (isShiftKeyPressed) {
                showMessage('info', `You have already opened a tab for this address: ${tabTitle}!`);
            }
        } else {
            addTab(
                {
                    title: tabTitle,
                    key: '-', // this will be overridden by the key generator function inside this addTab function
                    tabIcon: <TransactionTabIcon />,
                    content: ViewDetailTransaction,
                    closeable: true,
                    tooltip: record.address,

                    tabCategory: TAB_CATEGORY.TRANSACTION,
                    isMainMenu: false,
                    recordId: record.contract_id,

                    data: { ...record, subTabKey },
                },
                isShiftKeyPressed ? false : true
            );
            if (isShiftKeyPressed) {
                showMessage('success', `New tab has been created for address: ${tabTitle}!`);
            }
        }
    };

    const _columns: ProColumns<ITransactionListDataType>[] = [
        {
            title: 'Property',
            dataIndex: 'address',
            width: '300px',
            fixed: 'left',
            render: (_, record) => (
                <Row justify="space-between" align="middle">
                    <Col span={24} style={{ paddingLeft: '6px' }}>
                        <div>
                            <div className="action" onClick={() => _onClick(record)}>
                                <Title underline level={5}>
                                    {record.address || <i>Address isn't set</i>}
                                </Title>
                            </div>
                            <div>
                                {record.city || <i>City isn't set</i>},{' '}
                                {record.state || <i>State isn't set</i>},{' '}
                                {record.zip || <i>ZIP isn't set</i>}
                            </div>
                            <div>ESCROW #: {record.escrow || '-'}</div>
                            <div className="mt-1">
                                {getTransactionStatusCategory(record.status_id)}
                                <Badge className="mr-1 ml-2" color={'black'} />
                                <Button
                                    className={`ez-btn-contract-status mr-1 ez-tr-status-${record.status_id}`}
                                    type="text"
                                    shape="round"
                                    style={{
                                        backgroundColor: `${record.contract_status?.color}`,
                                        color: 'white',
                                    }}
                                    size="small"
                                    onClick={() => _onClick(record)}
                                >{`${record.contract_status?.status}`}</Button>
                            </div>
                            <div className="mt-2">
                                <Tooltip title={`Number of tasks completed`} placement="bottomLeft">
                                    <Tag
                                        className={`mr-2 tag-rounded cursor-pointer ${
                                            !record.total_task_count ? 'has-no-record' : ''
                                        }`}
                                        onClick={() => _onClick(record, 'ez_detail_tasks')}
                                        id={`tag-tList-contact_id-task-count-${record.contract_id}`}
                                    >
                                        <CheckCircleOutlined className="" />
                                        <span
                                            id={`tList-contact_id-complete-task-count-${record.contract_id}`}
                                        >
                                            {record.complete_task_count || 0}
                                        </span>
                                        &nbsp;/&nbsp;
                                        <span
                                            id={`tList-contact_id-total-task-count-${record.contract_id}`}
                                        >
                                            {record.total_task_count || 0}
                                        </span>
                                    </Tag>
                                </Tooltip>
                                <Tooltip
                                    title={`Number of documents marked as complete`}
                                    placement="bottomLeft"
                                >
                                    <Tag
                                        className={`mr-2 tag-rounded cursor-pointer ${
                                            !record.total_doc_count ? 'has-no-record' : ''
                                        }`}
                                        onClick={() => _onClick(record, 'ez_detail_documents')}
                                        id={`tag-tList-contact_id-doc-count-${record.contract_id}`}
                                    >
                                        <CloudServerOutlined />
                                        <span
                                            id={`tList-contact_id-complete-doc-count-${record.contract_id}`}
                                        >
                                            {record.complete_doc_count || 0}
                                        </span>
                                        &nbsp;/&nbsp;
                                        <span
                                            id={`tList-contact_id-total-doc-count-${record.contract_id}`}
                                        >
                                            {record.total_doc_count || 0}
                                        </span>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title={`Number of notes`} placement="bottomLeft">
                                    <Tag
                                        className={`mr-2 tag-rounded cursor-pointer ${
                                            !record.total_note_count ? 'has-no-record' : ''
                                        }`}
                                        onClick={() => _onClick(record, 'ez_detail_notes')}
                                        id={`tag-tList-contact_id-total-note-count-${record.contract_id}`}
                                    >
                                        <ContainerOutlined />
                                        <span
                                            id={`tList-contact_id-total-note-count-${record.contract_id}`}
                                        >
                                            {record.total_note_count || 0}
                                        </span>
                                    </Tag>
                                </Tooltip>
                                {record.flag_id !== 1 && record.contract_flag?.flag_color ? (
                                    <Tooltip
                                        placement="right"
                                        title={record.contract_flag.flag}
                                        color={record.contract_flag.flag_color}
                                    >
                                        <Button
                                            type="text"
                                            className=" "
                                            icon={
                                                <FlagTwoTone
                                                    twoToneColor={record.contract_flag.flag_color}
                                                />
                                            }
                                            size="middle"
                                            onClick={() => _onClick(record)}
                                        />
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Added By',
            dataIndex: 'added_by',
            width: '210px',
            render: (_, record) => (
                <Row justify="space-between" align="middle">
                    <Col span={24}>
                        <div>{record.account?.fullname || '-'}</div>
                        <div>
                            On{' '}
                            {record.date_posted
                                ? moment(record.date_posted).format('MMM Do, YYYY hh:mma')
                                : '-'}
                        </div>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Dates',
            dataIndex: 'dates',
            width: '300px',
            render: (_, record) =>
                !isRentalProperty(record.status_id) ? (
                    <Row justify="space-between" align="middle">
                        <Col span={14}>Closing Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat noTimezone value={record.contract_date?.close_date} />
                        </Col>
                        <Col span={14}>Contract Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat noTimezone value={record.contract_date?.contract_date} />
                        </Col>
                        <Col span={14}>Contract Effective Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat noTimezone value={record.contract_date?.effective_date} />
                        </Col>
                        <Col span={14}>Inspection Contingency</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat
                                noTimezone
                                value={record.contract_date?.inspection_contingency_date}
                            />
                        </Col>
                        <Col span={14}>Financing Contingency</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat
                                noTimezone
                                value={record.contract_date?.financing_contingency_date}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row justify="space-between" align="middle">
                        <Col span={14}>Application Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat
                                noTimezone
                                value={record.contract_date?.application_date}
                            />
                        </Col>
                        <Col span={14}>Lease Signed Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat
                                noTimezone
                                value={record.contract_date?.lease_signed_date}
                            />
                        </Col>
                        <Col span={14}>Lease Start</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat
                                noTimezone
                                value={record.contract_date?.lease_start_date}
                            />
                        </Col>
                        <Col span={14}>Lease End</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat noTimezone value={record.contract_date?.lease_end_date} />
                        </Col>
                        <Col span={14}>Move In Date</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZDateFormat noTimezone value={record.contract_date?.move_in_date} />
                        </Col>
                    </Row>
                ),
        },
        {
            title: 'Prices',
            dataIndex: 'prices',
            width: '250px',
            render: (_, record) =>
                !isRentalProperty(record.status_id) ? (
                    <Row justify="space-between" align="middle">
                        <Col span={14}>List price</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.listprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={14}>Sale price</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.salesprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={14}>Closing Cost Credit</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.cccredit}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={14}>Deposit</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.deposits}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row justify="space-between" align="middle">
                        <Col span={14}>Marketed price</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.marketedprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={14}>Rental price</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.rentalprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={14}>Credit</Col>
                        <Col span={10}>
                            :&nbsp;
                            <EZCurrencyFormat
                                value={record.contract_price?.credits}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                    </Row>
                ),
        },
        {
            title: 'Parties',
            dataIndex: 'parties',
            width: '600px',
            render: (_, record) =>
                !isRentalProperty(record.status_id) ? (
                    <Row justify="start" align="top" gutter={[16, 8]}>
                        <Col span={4}>Buyer(s) :</Col>
                        <Col span={8}>
                            {record.contract_buyers && record.contract_buyers.length ? (
                                <ol>
                                    <>
                                        {record.contract_buyers.map((party, idx) => (
                                            <li key={`contract_buyers-${idx}`}>
                                                <EZPopupable
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.buyer_name,

                                                        fullname: party.buyer_name,
                                                        phone: party.telephone,
                                                        email: party.email,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                        <Col span={4}>Seller(s) :</Col>
                        <Col span={8}>
                            {record.contract_sellers && record.contract_sellers.length ? (
                                <ol>
                                    <>
                                        {record.contract_sellers.map((party, idx) => (
                                            <li key={`contract_sellers-${idx}`}>
                                                <EZPopupable
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.seller_name,

                                                        fullname: party.seller_name,
                                                        phone: party.telephone,
                                                        email: party.email,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <Row justify="start" align="top" gutter={[16, 8]}>
                        <Col span={4}>Tenant(s) :</Col>
                        <Col span={8}>
                            {record.contract_tenants?.length ? (
                                <ol>
                                    <>
                                        {record.contract_tenants.map((party, idx) => (
                                            <li key={`contract_tenants-${idx}`}>
                                                <EZPopupable
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.tenant_name,

                                                        fullname: party.tenant_name,
                                                        phone: party.telephone,
                                                        email: party.email,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                        <Col span={4}>Landlord(s) :</Col>
                        <Col span={8}>
                            {record.contract_landlords?.length ? (
                                <ol>
                                    <>
                                        {record.contract_landlords.map((party, idx) => (
                                            <li key={`contract_landlords-${idx}`}>
                                                <EZPopupable
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.landlord_name,

                                                        fullname: party.landlord_name,
                                                        phone: party.telephone,
                                                        email: party.email,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                    </Row>
                ),
        },
        {
            title: 'Agents',
            dataIndex: 'agents',
            width: '600px',
            render: (_, record) =>
                !isRentalProperty(record.status_id) ? (
                    <Row justify="start" align="top">
                        <Col span={4}>Buyer's agent(s) :</Col>
                        <Col span={8}>
                            {record.contract_buyer_agents && record.contract_buyer_agents.length ? (
                                <ol>
                                    <>
                                        {record.contract_buyer_agents.map((party, idx) => (
                                            <li key={`contract_buyer_agents-${idx}`}>
                                                <EZPopupable
                                                    showCommission
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.agent_name,

                                                        fullname: party.agent_name,
                                                        phone: party.telephone,
                                                        email: party.email,

                                                        commission_base:
                                                            record.contract_price?.salesprice,
                                                        commission_rate: party.commission_rate,
                                                        commission_amount: party.commission_amount,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                        <Col span={4}>Seller's agent(s) :</Col>
                        <Col span={8}>
                            {record.contract_seller_agents &&
                            record.contract_seller_agents.length ? (
                                <ol>
                                    <>
                                        {record.contract_seller_agents.map((party, idx) => (
                                            <li key={`contract_seller_agents-${idx}`}>
                                                <EZPopupable
                                                    showCommission
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.broker_name,

                                                        fullname: party.broker_name,
                                                        phone: party.telephone,
                                                        email: party.email,

                                                        commission_base:
                                                            record.contract_price?.salesprice,
                                                        commission_rate: party.commission_rate,
                                                        commission_amount: party.commission_amount,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <Row justify="start" align="top">
                        <Col span={4}>
                            Referral <br />
                            Source :
                        </Col>
                        <Col span={8}>
                            {record.contract_referral_sources?.length ? (
                                <ol>
                                    <>
                                        {record.contract_referral_sources.map((party, idx) => (
                                            <li key={`contract_referral_sources-${idx}`}>
                                                <EZPopupable
                                                    showCommission
                                                    emptyLabel="Name"
                                                    data={{
                                                        title: party.referral_source_name,

                                                        fullname: party.referral_source_name,
                                                        phone: party.telephone,
                                                        email: party.email,

                                                        commission_base:
                                                            record.contract_price?.marketedprice,
                                                        commission_rate: party.commission_rate,
                                                        commission_amount: party.commission_amount,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                        <Col span={4}>Leasing agent(s) :</Col>
                        <Col span={8}>
                            {record.contract_leasing_agents?.length ? (
                                <ol>
                                    <>
                                        {record.contract_leasing_agents.map((party, idx) => (
                                            <li key={`contract_leasing_agents-${idx}`}>
                                                <EZPopupable
                                                    emptyLabel="Name"
                                                    showCommission
                                                    data={{
                                                        title: party.leasing_agent_name,

                                                        fullname: party.leasing_agent_name,
                                                        phone: party.telephone,
                                                        email: party.email,

                                                        commission_base:
                                                            record.contract_price?.marketedprice,
                                                        commission_rate: party.commission_rate,
                                                        commission_amount: party.commission_amount,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </>
                                </ol>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>
                    </Row>
                ),
        },
    ];

    const _setTransactionDateFilterParam = () => {
        const currentWhereFilterParams = { ...queryParams.whereFilterParams };
        const transaction_date_type =
            form.getFieldValue('transaction_date_type') ||
            _initialQueryFormValue.transaction_date_type;
        const transaction_date_range: RangeValue<moment.Moment> =
            form.getFieldValue('transaction_date_range') ||
            _initialQueryFormValue.transaction_date_range;

        transactionDateTypes.forEach(element => {
            delete currentWhereFilterParams[`>::<$contract_date.${element.value}$`];
        });

        if (transaction_date_range && transaction_date_range[0]) {
            currentWhereFilterParams[`>::<$contract_date.${transaction_date_type}$`] = [
                transaction_date_range[0]?.format('YYYY-MM-DD'),
                transaction_date_range[1]?.format('YYYY-MM-DD'),
            ];
        }
        setQueryParams({
            ...queryParams,
            whereFilterParams: currentWhereFilterParams,
        });
    };

    const _setTransactionPriceFilterParam = () => {
        const currentWhereFilterParams = { ...queryParams.whereFilterParams };
        const price_type = form.getFieldValue('price_type') || _initialQueryFormValue.price_type;
        const price_type_maximum = toNumber(form.getFieldValue('price_type_maximum')) || 0;
        const price_type_minimum = toNumber(form.getFieldValue('price_type_minimum')) || 0;

        const _removeExistingParams = () => {
            if (
                currentWhereFilterParams &&
                currentWhereFilterParams['>::<$contract_price.salesprice$']
            ) {
                delete currentWhereFilterParams['>::<$contract_price.salesprice$'];
            }
            if (
                currentWhereFilterParams &&
                currentWhereFilterParams['>::<$contract_price.listprice$']
            ) {
                delete currentWhereFilterParams['>::<$contract_price.listprice$'];
            }
        };

        if (price_type_maximum > price_type_minimum) {
            _removeExistingParams();

            currentWhereFilterParams[`>::<$contract_price.${price_type}$`] = [
                price_type_minimum,
                price_type_maximum,
            ];
            setQueryParams({
                ...queryParams,
                whereFilterParams: currentWhereFilterParams,
            });
        } else {
            _removeExistingParams();

            setQueryParams({
                ...queryParams,
                whereFilterParams: currentWhereFilterParams,
            });
        }
    };

    const _updateTableQuery = (elmName: string, value: string | number | any[]) => {
        let useAdvanceFilterOptions = false;

        switch (elmName) {
            case 'filter.keyword':
                setQueryParams({ ...queryParams, searchKeyword: value.toString() });
                break;

            case 'order.column':
                setQueryParams({
                    ...queryParams,
                    orderParams: [
                        [
                            value?.toString() || 'contract_id',
                            queryParams.orderParams[0]?.[1] || 'desc',
                        ],
                    ],
                });
                break;

            case 'order.direction':
                setQueryParams({
                    ...queryParams,
                    orderParams: [
                        [
                            queryParams.orderParams[0]?.[0] || 'contract_id',
                            value?.toString() || 'desc',
                        ],
                    ],
                });
                break;

            case 'filter.status_id':
                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        status_id:
                            value && (value as any[]).includes('all_active_statuses')
                                ? ACTIVE_STATUS_IDS
                                : value,
                    },
                });
                break;

            case 'filter.transaction_date_type': // form.getFieldValue('transaction_date_type')
                useAdvanceFilterOptions = true;

                _setTransactionDateFilterParam();
                break;
            case 'filter.transaction_date_range': // form.getFieldValue('transaction_date_range')
                useAdvanceFilterOptions = true;

                _setTransactionDateFilterParam();
                break;
            case 'filter.property_types':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: { ...queryParams.whereFilterParams, type_id: value },
                });
                break;
            case 'filter.sale_types':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: { ...queryParams.whereFilterParams, sale_types_id: value },
                });
                break;

            case 'filter.price_type': // form.getFieldValue('price_type')
                useAdvanceFilterOptions = true;

                _setTransactionPriceFilterParam();
                break;
            case 'filter.price_type_minimum': // form.getFieldValue('price_type_minimum')
                useAdvanceFilterOptions = true;

                if (value === null) {
                    form.setFieldValue('price_type_maximum', null);
                } else {
                    setTransactionByPriceMin(toNumber(value));
                }
                _setTransactionPriceFilterParam();

                break;
            case 'filter.price_type_maximum': // form.getFieldValue('price_type_maximum')
                useAdvanceFilterOptions = true;

                _setTransactionPriceFilterParam();
                break;
            case 'filter.flag_types':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: { ...queryParams.whereFilterParams, flag_id: value },
                });
                break;

            case 'filter.seller_agents':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_seller_agents.broker_id$': value,
                    },
                });
                break;
            case 'filter.buyer_agents':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_buyer_agents.agent_id$': value,
                    },
                });
                break;
            case 'filter.sellers':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_sellers.seller_id$': value,
                    },
                });
                break;
            case 'filter.buyers':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_buyers.buyer_id$': value,
                    },
                });
                break;

            //////

            case 'filter.tenants':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_tenants.tenant_id$': value,
                    },
                });
                break;
            case 'filter.landlords':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_landlords.landlord_id$': value,
                    },
                });
                break;
            case 'filter.leasing_agents':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_leasing_agents.leasing_agent_id$': value,
                    },
                });
                break;
            case 'filter.referral_sources':
                useAdvanceFilterOptions = true;

                setQueryParams({
                    ...queryParams,
                    whereFilterParams: {
                        ...queryParams.whereFilterParams,
                        '$contract_referral_sources.referral_source_id$': value,
                    },
                });
                break;

            default:
                break;
        }

        // update onboarding step to completed
        updateOnboardingStep('use_advanced_search', HomeCtx.refetchOnboardingList);
        Mixpanel.track('app.search_transaction_list', { elmName, value, useAdvanceFilterOptions });
    };

    const _triggerUpdateTableQueryDelayed = debounce((elmName, value) => {
        _updateTableQuery(elmName, value);
    }, DEBOUNCE_DURATION);

    const _generatePaginationLabel = () => {
        const current = pagination?.current || 0;
        const pageSize = pagination?.pageSize || 0;
        const total = pagination?.total || 0;

        const filteredFrom = pagination.totalFiltered
            ? `(filtered from ${pagination.totalFiltered} total entries)`
            : '';

        if (!total) {
            return `0 item ${filteredFrom}`;
        }

        if (current === 1 && total < current * pageSize) {
            return `${total} items ${filteredFrom}`;
        }

        return pagination
            ? `${1 + (current - 1) * pageSize}-${Math.min(
                  total,
                  current * pageSize
              )} of ${total} items ${filteredFrom}`
            : '';
    };

    const _resetFilterOptions = () => {
        form.resetFields();
        setQueryParams({
            searchKeyword: '',
            orderParams: [],
            whereParams: {},
            whereFilterParams: {},
        });
    };

    const _checkShiftKeyIsPressed: React.KeyboardEventHandler<HTMLDivElement> = e => {
        if (e.altKey || e.metaKey) setIsShiftKeyPressed(true);
    };

    const ExpandedRowElement: React.FC<{ contract_id: number }> = ({ contract_id }) => {
        const _reloadTransactionStat = (contract_id: number) => {
            getTransactionQuickStats(contract_id).then(resp => {
                if (resp.data) {
                    const completeTaskCount = document.getElementById(
                        `tList-contact_id-complete-task-count-${contract_id}`
                    );
                    const totalTaskCount = document.getElementById(
                        `tList-contact_id-total-task-count-${contract_id}`
                    );

                    if (completeTaskCount) {
                        completeTaskCount.innerHTML = resp.data?.complete_task_count;
                    }

                    if (totalTaskCount) {
                        totalTaskCount.innerHTML =
                            resp.data?.complete_task_count + resp.data?.incomplete_task_count || 0;
                    }
                }
            });
        };

        useEffect(() => {
            _reloadTransactionStat(contract_id);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [contract_id]);

        return (
            <div>
                <Tabs type="card">
                    <TabPane
                        // tab={<>Tasks List {generateTaskBadge(transactionQuickStat)}</>}
                        tab={<>Tasks List</>}
                        key="1"
                    >
                        <AppPageTasks
                            pageType={TASK_EMBEDDED_TYPE.TRANSACTION_LIST}
                            contractID={contract_id}
                            manualReloadCount={1}
                            reloadTransactionStat={_reloadTransactionStat}
                        />
                    </TabPane>
                    <TabPane tab="Notes" key="2" className="p-3">
                        <AppPageNotes
                            pageType={NOTE_EMBEDDED_TYPE.TRANSACTION_LIST}
                            contractID={contract_id}
                            manualReloadCount={1}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    };

    useEffect(() => {
        if (isCurrentTabActive === false) {
            LastScrollYPosition = window.scrollY;
        } else {
            window.scrollTo(0, LastScrollYPosition);
        }

        if (isCurrentTabActive && data?.autoFocusEnabled) {
            searchKeywordElement?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCurrentTabActive]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-tab-transaction-list">
            <div className="mb-5 ez-sub-content-wrapper">
                <Form form={form} initialValues={_initialQueryFormValue}>
                    <Row justify="space-between" align="middle" gutter={[16, 12]}>
                        <Col
                            span={10}
                            xs={24}
                            md={24}
                            lg={10}
                            xl={12}
                            style={{ textAlign: 'left' }}
                        >
                            <span className="mr-2">
                                <Text strong>Shows </Text>
                            </span>
                            <span>
                                <Text>{_generatePaginationLabel()}</Text>
                            </span>
                        </Col>
                        <Col
                            span={14}
                            xs={24}
                            md={24}
                            lg={14}
                            xl={12}
                            style={{ textAlign: 'right' }}
                        >
                            <Button
                                className="mr-3 ez-action-btn"
                                icon={<UndoOutlined />}
                                // size="small"
                                onClick={() => _resetFilterOptions()}
                            >
                                Reset filter
                            </Button>
                            <Button
                                className="ez-action-btn"
                                icon={
                                    toggleAdvanceFilter ? <EyeInvisibleOutlined /> : <EyeOutlined />
                                }
                                // size="small"
                                onClick={() => {
                                    setToggleAdvanceFilter(!toggleAdvanceFilter);
                                }}
                            >
                                {toggleAdvanceFilter ? 'Hide' : 'Show'} advance filter options
                            </Button>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="searchKeyword" noStyle>
                                <Search
                                    allowClear
                                    autoFocus
                                    placeholder="Enter the keyword(s)"
                                    style={{ width: '100%' }}
                                    size="middle"
                                    onChange={e =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.keyword',
                                            e.target.value
                                        )
                                    }
                                    ref={searchKeywordElement}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="status_id" noStyle>
                                <EZSelectStatus
                                    showAllActiveStatusesOpt
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    placeholder={
                                        <span>
                                            <Text>Transaction Status: </Text>
                                            <Text strong>All Statuses</Text>
                                        </span>
                                    }
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.status_id', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Input.Group compact>
                                <Form.Item name="order_by" noStyle>
                                    <Select
                                        style={{ width: '65%' }}
                                        listHeight={400}
                                        // defaultValue={'contract_id'}
                                        onChange={value => _updateTableQuery('order.column', value)}
                                    >
                                        <Option value="contract_id">
                                            <span>
                                                <Text>Sort By: </Text>
                                                <Text strong>Added Date</Text>
                                            </span>
                                        </Option>
                                        <OptGroup label="Important Dates">
                                            <Option value="close_date">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Closing Date</Text>
                                                </span>
                                            </Option>
                                            <Option value="contract_date">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Contract Date</Text>
                                                </span>
                                            </Option>
                                            <Option value="inspection_contingency_date">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Inspection Contingency</Text>
                                                </span>
                                            </Option>
                                            <Option value="financing_contingency_date">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Financing Contingency</Text>
                                                </span>
                                            </Option>
                                        </OptGroup>
                                        <OptGroup label="Property Information and Status">
                                            <Option value="address">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Address</Text>
                                                </span>
                                            </Option>
                                            <Option value="city">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>City</Text>
                                                </span>
                                            </Option>
                                            <Option value="state">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>State</Text>
                                                </span>
                                            </Option>
                                            <Option value="status_id">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Transaction Status</Text>
                                                </span>
                                            </Option>
                                            <Option value="flag_id">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Transaction Flag</Text>
                                                </span>
                                            </Option>
                                        </OptGroup>
                                        <OptGroup label="Property Price">
                                            <Option value="salesprice">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Sale Price</Text>
                                                </span>
                                            </Option>
                                            <Option value="listprice">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Listing Price</Text>
                                                </span>
                                            </Option>
                                            <Option value="marketedprice">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Marketed Price</Text>
                                                </span>
                                            </Option>
                                            <Option value="rentalprice">
                                                <span>
                                                    <Text>Sort By: </Text>
                                                    <Text strong>Rental Price</Text>
                                                </span>
                                            </Option>
                                        </OptGroup>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="order_direction" noStyle>
                                    <Select
                                        style={{ width: '35%' }}
                                        // defaultValue={'desc'}
                                        onChange={value =>
                                            _updateTableQuery('order.direction', value)
                                        }
                                    >
                                        <Option value="asc">Ascending</Option>
                                        <Option value="desc">Descending</Option>
                                    </Select>
                                </Form.Item>
                            </Input.Group>
                        </Col>
                    </Row>
                    <Divider
                        style={{ display: `${toggleAdvanceFilter ? 'block' : 'none'}` }}
                        orientation="left"
                    ></Divider>
                    <Row
                        style={toggleAdvanceFilter === false ? { display: 'none' } : undefined}
                        justify="space-between"
                        align="middle"
                        gutter={[16, 12]}
                    >
                        <Col span={10}>
                            <Input.Group compact>
                                <Form.Item name="transaction_date_type" noStyle>
                                    <Select
                                        style={{ width: '41%' }}
                                        placeholder="Choose Transaction Date"
                                        onChange={value =>
                                            _updateTableQuery('filter.transaction_date_type', value)
                                        }
                                        options={transactionDateTypes}
                                    ></Select>
                                </Form.Item>
                                <Form.Item name="transaction_date_range" noStyle>
                                    <DatePicker.RangePicker
                                        style={{ width: '59%' }}
                                        format="MMM DD, YYYY"
                                        onChange={value =>
                                            _updateTableQuery('filter.transaction_date_range', [
                                                value?.[0]?.toString(),
                                                value?.[1]?.toString(),
                                            ])
                                        }
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="property_types" noStyle>
                                <EZSelectPropertyType
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    placeholder={
                                        <span>
                                            <Text>Property Type: </Text>
                                            <Text strong>All Types</Text>
                                        </span>
                                    }
                                    onChange={(value: string) =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.property_types',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="sale_types" noStyle>
                                <EZSelectSaleType
                                    maxTagCount="responsive"
                                    mode="multiple"
                                    placeholder={
                                        <span>
                                            <Text>Sale Type: </Text>
                                            <Text strong>All Types</Text>
                                        </span>
                                    }
                                    onChange={(value: string) =>
                                        _triggerUpdateTableQueryDelayed('filter.sale_types', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Input.Group compact>
                                <Form.Item name="price_type" noStyle>
                                    <Select
                                        style={{ width: '41%' }}
                                        // defaultValue="salesprice"
                                        placeholder="Filter By Price"
                                        onChange={value =>
                                            _updateTableQuery('filter.price_type', value)
                                        }
                                    >
                                        <Option value="salesprice">Sale Price</Option>
                                        <Option value="listprice">List Price</Option>
                                        <Option value="marketedprice">Marketed Price</Option>
                                        <Option value="rentalprice">Rental Price</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="price_type_minimum" noStyle>
                                    <EZInputNumber
                                        numberType="currency"
                                        style={{ width: '26%', textAlign: 'center' }}
                                        placeholder="Minimum"
                                        value={transactionByPriceMin}
                                        onChange={value =>
                                            _triggerUpdateTableQueryDelayed(
                                                'filter.price_type_minimum',
                                                value
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Input
                                    className="site-input-split"
                                    style={{
                                        width: '7%',
                                        borderLeft: 0,
                                        borderRight: 0,
                                        pointerEvents: 'none',
                                    }}
                                    placeholder="~"
                                    disabled
                                />
                                <Form.Item name="price_type_maximum" noStyle>
                                    <EZInputNumber
                                        numberType="currency"
                                        className="site-input-right"
                                        style={{ width: '26%', textAlign: 'center' }}
                                        placeholder="Maximum"
                                        min={transactionByPriceMin}
                                        onChange={value =>
                                            _triggerUpdateTableQueryDelayed(
                                                'filter.price_type_maximum',
                                                value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Col>
                        <Col span={14}>
                            <Form.Item name="flag_types" noStyle>
                                <EZSelectFlag
                                    maxTagCount="responsive"
                                    mode="multiple"
                                    className="mr-3"
                                    placeholder={
                                        <span>
                                            <Text>Flag: </Text>
                                            <Text strong>All Flags</Text>
                                        </span>
                                    }
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.flag_types', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="buyers" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_buyers"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Buyer: </Text>
                                            <Text strong>All Buyers</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.buyers', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="buyer_agents" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_buyer_agents"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Buyer's Agent: </Text>
                                            <Text strong>All Agents</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.buyer_agents',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="sellers" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_sellers"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Seller: </Text>
                                            <Text strong>All Sellers</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.sellers', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="seller_agents" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_seller_agents"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Seller's Agent: </Text>
                                            <Text strong>All Agents</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.seller_agents',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="tenants" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_tenants"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Tenant: </Text>
                                            <Text strong>All Tenants</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.tenants', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="referral_sources" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_referral_sources"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Referral Source: </Text>
                                            <Text strong>All Sources</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.referral_sources',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="landlords" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_landlords"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Landlord: </Text>
                                            <Text strong>All Landlords</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed('filter.landlords', value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="leasing_agents" noStyle>
                                <EZSelectParty
                                    style={{ width: '100%' }}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    partyType="contract_leasing_agents"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    showSearch
                                    showArrow
                                    allowClear
                                    placeholderOnFocus={
                                        <span>
                                            <Text>Search by name or email </Text>
                                        </span>
                                    }
                                    defaultPlaceholder={
                                        <span>
                                            <Text>Leasing Agent: </Text>
                                            <Text strong>All Agents</Text>
                                        </span>
                                    }
                                    optionLabelProp="selectedLabel"
                                    onChange={value =>
                                        _triggerUpdateTableQueryDelayed(
                                            'filter.leasing_agents',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div
                tabIndex={-1}
                onKeyUp={() => setIsShiftKeyPressed(false)}
                onKeyDown={e => _checkShiftKeyIsPressed(e)}
            >
                <EZTable
                    // expandable={{
                    //     expandedRowRender: (record: ITransactionListDataType, _, __, expand) => {
                    //         return expand ? (
                    //             <ExpandedRowElement contract_id={record.contract_id} />
                    //         ) : (
                    //             <></>
                    //         );
                    //     },
                    //     defaultExpandedRowKeys: expandedRecordIDs,
                    //     expandedRowKeys: expandedRecordIDs,
                    //     onExpand: (expanded, record: ITransactionListDataType) => {
                    //         if (expanded) {
                    //             setExpandedRecordIDs([record.contract_id]);
                    //         } else {
                    //             setExpandedRecordIDs([
                    //                 ...expandedRecordIDs.filter(id => id !== record.contract_id),
                    //             ]);
                    //         }
                    //     },
                    // }}
                    pagination={{
                        pageSize: 20,
                    }}
                    columns={_columns}
                    rowKey="contract_id"
                    fetchData={_fetchData}
                    onChangeCallback={_onChangeCallback}
                    size="large"
                    scroll={{ x: 1300 }}
                    queryParams={queryParams}
                    // toolBarComponents={[
                    //     <Button
                    //         key={`toolBarComponents-1-trans-list`}
                    //         className="mr-1 ez-action-btn"
                    //         icon={toggleAllRows ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                    //         // size="small"
                    //         onClick={() => _toggleAllRows(!toggleAllRows)}
                    //     >
                    //         {toggleAllRows ? 'Collapse' : 'Expand'} all rows
                    //     </Button>,
                    // ]}
                    disableNativeSearch
                />
            </div>
            <BackTop />
        </div>
    );
};

export default TransactionList;
