// Core React libraries import
import React, { useContext, useEffect } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Tabs } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

// EZ web-app utils import
import { GOOGLE_CLIENTID } from 'utils/envUtil';
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { HomeContext } from 'context/EZContext';

import { SubTab_EmailLogs } from './subTabs/subTabs_EmailLogs';
import { SubTab_EmailTemplates } from './subTabs/subTabs_Templates';
import { SubTab_EmailIntegration } from './subTabs/subTabs_Integration';

// Page level styles
import './email.scss';
import { SubTab_TemplateCodes } from './subTabs/subTabs_TemplateCodes';

const EmailTab: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const hasFullAccessPermission = [1, 3].includes(UserPermission?.accountrole_id || 0);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENTID}>
            <div className="ez-component-email ez-tab-email">
                <div className="mb-4 ez-sub-content-wrapper">
                    <Row className="row-wrapper">
                        <Col span={24}>
                            <Tabs defaultActiveKey="all_draft_sent_emails">
                                <Tabs.TabPane
                                    tab="All Draft and Sent Emails"
                                    key="all_draft_sent_emails"
                                    closable={false}
                                >
                                    <SubTab_EmailLogs />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab="Email Templates"
                                    key="email_templates"
                                    closable={false}
                                >
                                    <SubTab_EmailTemplates />
                                </Tabs.TabPane>
                                {/* <Tabs.TabPane
                                    tab="Template Codes"
                                    key="template_codes"
                                    closable={false}
                                >
                                    <SubTab_TemplateCodes />
                                </Tabs.TabPane> */}
                                <Tabs.TabPane tab="Integration" key="integration" closable={false}>
                                    <SubTab_EmailIntegration />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default EmailTab;
