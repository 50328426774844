// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Modal,
    Form,
    Input,
    Select,
    Typography,
    InputNumber,
    Popconfirm,
    Table,
    Button,
    Divider,
} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
    CloseOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { get, isNil, omitBy, pick } from 'lodash';

// EZ web-app utils import
import { updateTransactionParty, deleteTransactionParty } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import { ITransactionListDataType, PARTY_TYPES, STATUS_LIST_RENTAL } from 'types/transaction';
// EZ web-app components import
import { showNotification } from 'utils/notificationUtil';
import {
    EZInputNumber,
    EZCurrencyFormat,
    EZSelectUSState,
    EZAutocompleteCity,
    EZNumberFormat,
    EZAutocompleteParty,
} from 'components';
import { FixedType } from 'rc-table/lib/interface';
import { ColumnType } from 'antd/lib/table';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

interface ItemType {
    key: string;
    name?: string;
    telephone?: string;
    telephone2?: string;
    fax?: string;
    email?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    commission?: number;
    commission_rate?: number;
    commission_amount?: number;
}

const originData: ItemType[] = [];
for (let i = 0; i < 5; i++) {
    originData.push({
        key: i.toString(),
        name: `Edrward Edrwardasd Edrward ${i}`,
        email: `Edrward${i}@gmmail.co`,
        telephone: '+61 (555) 555-1234',
        telephone2: '-',
        fax: '-',
        company: '-',
        address: `London Park no. ${i}`,
        city: `City no. ${i}`,
        state: `state no. ${i}`,
        zip: `zip no. ${i}`,
        commission: 100,
    });
}

type InputType = 'number' | 'text' | 'custom';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: InputType;
    customInputNode?: (record: ItemType) => React.ReactNode;
    onKeyDownFunc?: (e: React.KeyboardEvent<HTMLInputElement>, record: ItemType) => void;
    record: ItemType;
    index: number;
    children: React.ReactNode;
}

const { Text } = Typography;
const { Option, OptGroup } = Select;

const colLabelSpan = 4;
const colValueSpan = 20;

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    customInputNode,
    record,
    index,
    children,
    onKeyDownFunc,
    ...restProps
}) => {
    const inputNode =
        inputType === 'number' ? (
            <InputNumber
                onKeyDown={e => {
                    if (onKeyDownFunc) {
                        onKeyDownFunc(e, record);
                    }
                }}
            />
        ) : (
            <Input
                onKeyDown={e => {
                    if (onKeyDownFunc) {
                        onKeyDownFunc(e, record);
                    }
                }}
                allowClear
            />
        );
    // const inputNode = inputType === 'number' ? <InputNumber /> : <Input allowClear />;

    return (
        <td {...restProps}>
            {editing ? (
                inputType === 'custom' ? (
                    customInputNode ? (
                        customInputNode(record)
                    ) : (
                        <Form.Item name={[record.key, dataIndex]} style={{ margin: 0 }}>
                            {inputNode}
                        </Form.Item>
                    )
                ) : (
                    <Form.Item name={[record.key, dataIndex]} style={{ margin: 0 }}>
                        {inputNode}
                    </Form.Item>
                )
            ) : (
                children
            )}
        </td>
    );
};

export const ModalEditTransactionParty: React.FC<{
    transactionData: ITransactionListDataType | undefined;
    isModalVisible: boolean;
    isEditMode: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    modalTitle?: string;
    listName: string;
    listPrimaryID: string;
    listFullnameColumnName: string;
    reloadSubTabData: () => void;
}> = ({
    transactionData,
    isModalVisible = false,
    isEditMode,
    setIsModalVisible = () => {},
    modalTitle,
    listName,
    listPrimaryID,
    listFullnameColumnName,
    reloadSubTabData,
}) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const dataList: any[] = get(transactionData, listName) || [];

    const hasCommission = [
        'contract_buyer_agents',
        'contract_seller_agents',
        'contract_referral_sources',
        'contract_leasing_agents',
    ].includes(listName);

    const [form] = Form.useForm();

    const [submittingDataKeys, setSubmittingDataKeys] = useState<string[]>([]);
    const [editableKeys, setEditableKeys] = useState<string[]>([]);

    const [recordList, setRecordList] = useState<ItemType[]>([]);

    const isRentalProperty = STATUS_LIST_RENTAL.find(
        item => item.status_id === transactionData?.status_id
    )
        ? true
        : false;

    const _onSubmitForm = (formValues?: any) => {};

    const _onOk = () => {
        setIsModalVisible(false);
    };

    const _addNewRow = () => {
        const key = 'UNSAVED-' + new Date().getTime();
        setRecordList([
            ...recordList,
            {
                key: key,
                name: ``,
                email: ``,
                telephone: '',
                telephone2: '',
                fax: '',
                company: '',
            },
        ]);
        setEditableKeys([...editableKeys, key]);
    };

    const isEditing = (record: ItemType) =>
        editableKeys.find(eKey => eKey === record.key) ? true : false;
    const isSubmitting = (record: ItemType) =>
        submittingDataKeys.find(eKey => eKey === record.key) ? true : false;

    const _edit = (record: Partial<ItemType> & { key: React.Key }) => {
        form.setFieldsValue({
            [record.key]: {
                ...record,
                commission_type:
                    record.commission_amount && !record.commission_rate
                        ? 'use_amount'
                        : 'use_percentage',
            },
        });
        setEditableKeys([...editableKeys, record.key]);
    };

    const _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, record: ItemType) => {
        if (e.key === 'Enter') {
            _save(record.key);
        }
    };

    const _save = async (key: string) => {
        try {
            const payload = form.getFieldsValue()?.[key] || null;
            const isEditMode = !key.includes('UNSAVED');

            if (!isEditMode && !payload.name && !payload.email && !payload.telephone) {
                return;
            }

            setSubmittingDataKeys([...submittingDataKeys, key]);

            updateTransactionParty(
                transactionData?.contract_id,
                key,
                listName as PARTY_TYPES,
                payload
            )
                .then(resp => {
                    const keyRecordObjIndex = recordList.findIndex(r => r.key === key);

                    if (keyRecordObjIndex >= 0) {
                        recordList[keyRecordObjIndex] = {
                            ...recordList[keyRecordObjIndex],
                            ...payload,
                        };

                        if (!isEditMode && resp?.data?.tablePrimaryIDName) {
                            recordList[keyRecordObjIndex].key = resp.data[
                                resp.data.tablePrimaryIDName
                            ] as string;
                        }
                    }

                    setEditableKeys(editableKeys.filter(k => k !== key));
                    setRecordList([...recordList]);

                    reloadSubTabData();

                    if (!isEditMode)
                        showNotification('success', 'New record has been added successfully.');
                    else showNotification('success', 'New change(s) has been saved successfully.');
                })
                .finally(() => {
                    setSubmittingDataKeys(submittingDataKeys.filter(k => k !== key));
                });
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const _delete = (record: ItemType) => {
        setSubmittingDataKeys([...submittingDataKeys, record.key]);

        deleteTransactionParty(transactionData?.contract_id, record.key, listName as PARTY_TYPES)
            .then(() => {
                setRecordList([...recordList.filter(r => r.key !== record.key)]);

                reloadSubTabData();

                showNotification('success', `A record has been deleted successfully.`);
            })
            .finally(() => {
                setSubmittingDataKeys(submittingDataKeys.filter(key => key !== record.key));
            });
    };

    const _onSelectName = (value: any, opt: any, record: ItemType) => {
        form.setFieldsValue({
            [record.key]: {
                ...omitBy(
                    pick(opt, [
                        'email',
                        'telephone',
                        'telephone2',
                        'fax',
                        'address',
                        'city',
                        'state',
                        'zip',
                        'company',
                    ]),
                    isNil
                ),
            },
        });
    };

    const CommissionCustomInput: React.FC<{ record: ItemType }> = ({ record }) => {
        const [commissionType, setCommissionType] = useState(
            record.commission_amount && !record.commission_rate ? 'use_amount' : 'use_percentage'
        );

        const _onChangeComission = (
            commissionFieldType: 'commission_rate' | 'commission_amount',
            value: number
        ) => {
            let commission_amount: number | null = null;
            let commission_rate: number | null = null;

            const salesprice = transactionData?.contract_price?.salesprice || 0;
            const rentalprice = transactionData?.contract_price?.rentalprice || 0;

            if (commissionFieldType === 'commission_rate') {
                switch (listName) {
                    case 'contract_buyer_agents':
                        commission_amount = value ? salesprice * 0.01 * value : null;
                        break;
                    case 'contract_seller_agents':
                        commission_amount = value ? salesprice * 0.01 * value : null;
                        break;
                    case 'contract_referral_sources':
                        commission_amount = value ? rentalprice * 0.01 * value : null;
                        break;
                    case 'contract_leasing_agents':
                        commission_amount = value ? rentalprice * 0.01 * value : null;
                        break;

                    default:
                        break;
                }
            } else {
                switch (listName) {
                    case 'contract_buyer_agents':
                        commission_rate =
                            value && salesprice > 0 ? (value / salesprice) * 100 : null;
                        break;
                    case 'contract_seller_agents':
                        commission_rate =
                            value && salesprice > 0 ? (value / salesprice) * 100 : null;
                        break;
                    case 'contract_referral_sources':
                        commission_rate =
                            value && rentalprice > 0 ? (value / rentalprice) * 100 : null;
                        break;
                    case 'contract_leasing_agents':
                        commission_rate =
                            value && rentalprice > 0 ? (value / rentalprice) * 100 : null;
                        break;

                    default:
                        break;
                }
            }

            if (
                commission_amount !== null &&
                commission_amount !== 0 &&
                typeof commission_amount == 'number'
            ) {
                commission_amount = Number(commission_amount.toFixed(2));
            }

            if (commissionFieldType === 'commission_rate') {
                form.setFieldsValue({ [record.key]: { commission_amount: commission_amount } });
            } else {
                form.setFieldsValue({
                    [record.key]: { commission_rate: commission_rate },
                });
            }
        };

        return (
            <>
                <Form.Item name={[record.key, 'commission_type']} noStyle>
                    <Select
                        style={{ width: '60px' }}
                        placeholder=""
                        onChange={value => setCommissionType(value)}
                        className="mr-2"
                    >
                        <Option value="use_percentage">%</Option>
                        <Option value="use_amount">$</Option>
                    </Select>
                </Form.Item>
                <Form.Item name={[record.key, 'commission_rate']} noStyle>
                    <EZInputNumber
                        numberType="percentage"
                        style={{ width: '110px', textAlign: 'center' }}
                        className="mr-2"
                        placeholder="..."
                        controls={false}
                        precision={2}
                        disabled={!commissionType || commissionType === 'use_amount'}
                        onChange={(value: number | string) =>
                            _onChangeComission('commission_rate', value as number)
                        }
                    />
                </Form.Item>
                <Form.Item name={[record.key, 'commission_amount']} noStyle>
                    <EZInputNumber
                        numberType="currency"
                        className="site-input-right"
                        style={{ width: '110px', textAlign: 'center' }}
                        placeholder="$ Commission"
                        step={5000}
                        disabled={!commissionType || commissionType === 'use_percentage'}
                        precision={3}
                        onChange={(value: number | string) =>
                            _onChangeComission('commission_amount', value as number)
                        }
                    />
                </Form.Item>
            </>
        );
    };

    let commissionColumns: any[] = [];

    if (hasCommission) {
        commissionColumns.push({
            title: 'Commission',
            dataIndex: 'comission_rate',
            inputType: 'custom' as InputType,
            customInputNode: (record: ItemType) => {
                return <CommissionCustomInput record={record} />;
            },
            fixed: false as FixedType,
            editable: true,
            width: '330px',
            render: (_: any, record: ItemType) => {
                return (
                    <span>
                        {record.commission_amount ? (
                            <>
                                <EZCurrencyFormat
                                    value={record.commission_amount}
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    displayType={'text'}
                                />
                                {record.commission_rate ? (
                                    <span className="ml-2">
                                        (
                                        <EZNumberFormat
                                            value={record.commission_rate}
                                            suffix={'%'}
                                            displayType={'text'}
                                        />
                                        )
                                    </span>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            '-'
                        )}
                    </span>
                );
            },
        });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            width: '50px',
            fixed: 'left' as FixedType,
            editable: false,
            render: (_: any, record: ItemType, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '200px',
            // fixed: 'left' as FixedType,
            inputType: 'custom' as InputType,
            customInputNode: (record: ItemType) => {
                return (
                    <Form.Item name={[record.key, 'name']} noStyle>
                        <EZAutocompleteParty
                            style={{ width: '100%' }}
                            onSelect={(value: any, option: any) => {
                                _onSelectName(value, option, record);
                            }}
                        />
                    </Form.Item>
                );
            },
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '280px',
            inputType: 'text',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'telephone',
            // valueType: 'text',
            width: '180px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        ...commissionColumns,
        {
            title: 'Company',
            dataIndex: 'company',
            // valueType: 'text',
            width: '250px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Address',
            dataIndex: 'address',
            // valueType: 'text',
            width: '250px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'City',
            dataIndex: 'city',
            // valueType: 'text',
            width: '170px',
            inputType: 'custom' as InputType,
            customInputNode: (record: ItemType) => {
                return (
                    <Form.Item name={[record.key, 'city']} noStyle>
                        <EZAutocompleteCity style={{ width: '100%' }} />
                    </Form.Item>
                );
            },
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'State',
            dataIndex: 'state',
            // valueType: 'text',
            width: '150px',
            inputType: 'custom' as InputType,
            customInputNode: (record: ItemType) => {
                return (
                    <Form.Item name={[record.key, 'state']} noStyle>
                        <EZSelectUSState
                            fieldNames={{
                                label: 'state_name',
                                value: 'state_code',
                            }}
                        />
                    </Form.Item>
                );
            },
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            // valueType: 'text',
            width: '125px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            // valueType: 'text',
            width: '150px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Phone #2',
            dataIndex: 'telephone2',
            // valueType: 'text',
            width: '180px',
            render: (text: any) => {
                return <span>{text ? <Text copyable>{text}</Text> : '-'}</span>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            width: '140px',
            editable: false,
            fixed: 'right' as FixedType,
            hideFromTable: UserPermission && UserPermission?.toggle === 2 ? false : true,
            render: (_: any, record: ItemType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<SaveFilled />}
                            title="Save this record"
                            onClick={() => _save(record.key.toString())}
                            loading={isSubmitting(record)}
                        >
                            Save
                        </Button>

                        <Divider type="vertical" />
                        <Button
                            className="p-0 text-grey"
                            type="text"
                            title="Cancel and back"
                            icon={<CloseOutlined />}
                            onClick={() => _cancel(record)}
                        ></Button>
                    </span>
                ) : (
                    <>
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<EditOutlined />}
                            title="Edit this record"
                            onClick={() => _edit(record)}
                        >
                            Edit
                        </Button>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure to delete?"
                            placement="bottomRight"
                            arrowPointAtCenter
                            onConfirm={() => _delete(record)}
                        >
                            <Button
                                className="p-0 mr-1"
                                type="link"
                                style={{ color: 'red' }}
                                icon={<DeleteOutlined />}
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            ></Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    const mergedColumns = columns
        .filter(c => c.hideFromTable !== true)
        .map(col => {
            const _column: ColumnType<ItemType> = pick(col, [
                'title',
                'dataIndex',
                'fixed',
                'render',
                'width',
            ]);

            if (col.editable !== false) {
                _column.onCell = (record: ItemType) => ({
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    customInputNode: col.customInputNode,
                    onKeyDownFunc: _onKeyDown,
                    title: col.title,
                    editing: isEditing(record),
                });
            }

            return _column;
        });

    const _cancel = (record?: ItemType) => {
        if (record) {
            if (
                record.key.toString().includes('UNSAVED') &&
                !record.name &&
                !record.email &&
                !record.telephone
            ) {
                setRecordList(recordList.filter(r => r.key !== record.key));
            }
            setEditableKeys(editableKeys.filter(key => key !== record.key));
        } else setEditableKeys([]);
    };

    const _onValuesChange = (changedValues: any, values: any) => {
        const recordKey = Object.keys(changedValues)?.[0];
    };

    const _resetDataList = () => {
        const dtList = dataList.map(record => {
            record.key = record[listPrimaryID]?.toString();
            record.name = record[listFullnameColumnName];
            return record;
        });

        setRecordList(dtList);

        if (isEditMode) {
            setEditableKeys(dtList.map(r => r.key));

            for (let index = 0; index < dtList.length; index++) {
                const record = dtList[index];
                form.setFieldsValue({
                    [record.key]: {
                        ...record,
                        commission_type:
                            record.commission_amount && !record.commission_rate
                                ? 'use_amount'
                                : 'use_percentage',
                    },
                });
            }
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            _resetDataList();
        } else {
            setRecordList([]);
            setEditableKeys([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    return (
        <>
            <Modal
                title={
                    (modalTitle || `Show involved parties information`) +
                    ` for ${transactionData?.address}`
                }
                visible={isModalVisible}
                onOk={() => _onOk()}
                onCancel={() => setIsModalVisible(false)}
                okText={`Save Changes`}
                cancelText="Close"
                width={'1100px'}
                style={{ top: 40 }}
                bodyStyle={{ overflowY: 'auto', height: '600px' }}
                okButtonProps={{ style: true ? { display: 'none' } : {} }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={_onSubmitForm}
                    onValuesChange={_onValuesChange}
                    requiredMark={false}
                >
                    <Row gutter={20}>
                        {hasCommission && (
                            <>
                                <Col span={24}>
                                    {!isRentalProperty ? (
                                        <Row>
                                            <Col span={colLabelSpan} className="ez-form-label">
                                                List Price
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <EZCurrencyFormat
                                                    value={
                                                        transactionData?.contract_price?.listprice
                                                    }
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    displayType={'text'}
                                                />
                                            </Col>
                                            <Col span={colLabelSpan} className="ez-form-label">
                                                Sales Price
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <EZCurrencyFormat
                                                    value={
                                                        transactionData?.contract_price?.salesprice
                                                    }
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    displayType={'text'}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col span={colLabelSpan} className="ez-form-label">
                                                Marketed Price
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <EZCurrencyFormat
                                                    value={
                                                        transactionData?.contract_price
                                                            ?.marketedprice
                                                    }
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    displayType={'text'}
                                                />
                                            </Col>
                                            <Col span={colLabelSpan} className="ez-form-label">
                                                Rental Price
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <EZCurrencyFormat
                                                    value={
                                                        transactionData?.contract_price?.rentalprice
                                                    }
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    displayType={'text'}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Divider />
                            </>
                        )}
                        <Col span={24}>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                dataSource={recordList}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                // pagination={{
                                //     onChange: () => _cancel(),
                                //     pageSize: 100,
                                // }}
                                pagination={false}
                                // size="small"
                                scroll={{ x: '100%' }}
                                footer={() =>
                                    UserPermission && UserPermission?.toggle === 2 ? (
                                        <Button
                                            type="dashed"
                                            style={{ width: '100%' }}
                                            onClick={() => _addNewRow()}
                                            icon={<PlusOutlined />}
                                        >
                                            Add new record
                                        </Button>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
