import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Row, Col, Form, TreeSelect } from 'antd';

import ReactQuill, { QuillOptions, ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { EZRichTextEditorForwardedRefProps, EZRichTextEditorProps } from './richTextEditor';
import { EmailCodeTreeDataChildType, TemplateListTreeDataChildType } from 'types/email';
import { EMAIL_TEMPLATE_CODES } from 'static/emailCodes';
import { getFoldersWithEmailTemplates } from 'utils/ez_api/emailTemplateAPIs';
import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
import { TeamOutlined } from '@ant-design/icons';
import { DataTableQueryType } from 'types/base';
import { HomeContext } from 'context/EZContext';

const defaultToolbar: any = {
    container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
    ],
    handlers: {},
};

const emailCodesTreeData: EmailCodeTreeDataChildType[] = [...EMAIL_TEMPLATE_CODES];

type EmailEditorFormItemProps = EZRichTextEditorProps & {
    onSelectTemplate?: (emailTemplate: EmailTemplateType) => void;
    hideToolbar?: boolean;

    excludedTemplateIDs?: number[];
};

export const EmailEditorFormItem = React.forwardRef<
    EZRichTextEditorForwardedRefProps,
    EmailEditorFormItemProps | undefined
>(
    (
        {
            showTemplateDropdown,
            isRequired = false,
            onSelectTemplate,
            excludedTemplateIDs,
            hideToolbar = false,
            ...props
        },
        forwardedRef
    ) => {
        const reactQuillRef = useRef<ReactQuill>(null);

        const HomeCtx = useContext(HomeContext);
        const UserInfo = HomeCtx.userInfo;

        const [templateListTreeData, setTemplateListTreeData] = useState<
            TemplateListTreeDataChildType[]
        >([]);

        useImperativeHandle(forwardedRef, () => ({
            getEditorInstance: () => reactQuillRef.current?.getEditor(),
        }));

        const [editorLastIndex, setEditorLastIndex] = useState(0);

        if (showTemplateDropdown) {
        }

        const modules = useMemo(
            () => ({
                toolbar: !hideToolbar ? defaultToolbar : false,
            }),
            []
        );

        const _fetchEmailTemplates = () => {
            const whereParams: DataTableQueryType = {};

            if (excludedTemplateIDs && excludedTemplateIDs.length) {
                whereParams.where = {
                    '!$email_templates.email_template_id$': excludedTemplateIDs,
                };
            }

            getFoldersWithEmailTemplates(
                {
                    ...whereParams,
                },
                true
            ).then(({ data }) => {
                let folderWithEmailTemplates = data as EmailTemplateFolderType[];

                // const myFolders: EmailTemplateFolderType[] = [];
                // const foldersOwnedByOthers: EmailTemplateFolderType[] = [];

                // for (let index = 0; index < folderWithEmailTemplates.length; index++) {
                //     const folder = folderWithEmailTemplates[index];

                //     if (folder.account_id === UserInfo?.account_id) {
                //         myFolders.push(folder);
                //     } else {
                //         foldersOwnedByOthers.push(folder);
                //     }
                // }

                // folderWithEmailTemplates = [...myFolders, ...foldersOwnedByOthers];

                const templateListData: TemplateListTreeDataChildType[] =
                    folderWithEmailTemplates?.map(folder => ({
                        title: (
                            <>
                                {folder.is_shared && <TeamOutlined className="mr-2" />}
                                {folder.folder_name}
                                {folder.is_shared ? ` - shared by ${folder.account?.fullname}` : ``}
                            </>
                        ),
                        value: `folder_${folder.folder_id}`,
                        key: `folder_${folder.folder_id}`,
                        selectable: false,
                        children: folder.email_templates?.map(emailTemplate => ({
                            title: emailTemplate.template_name,
                            key: emailTemplate.email_template_id,
                            value: emailTemplate.template_name,
                            meta: emailTemplate,
                        })),
                    }));
                setTemplateListTreeData(templateListData);
            });
        };

        const loadSelectedTemplate = (selectedNode: TemplateListTreeDataChildType) => {
            const quill = reactQuillRef.current?.getEditor();
            if (quill) {
                if (selectedNode.meta?.msg_body) {
                    const delta = quill.clipboard.convert(selectedNode.meta?.msg_body || '');

                    quill.setContents(delta, 'silent');
                } else {
                    quill.setText('');
                }
            }

            if (selectedNode.meta) onSelectTemplate?.(selectedNode.meta);
        };

        const insertChosenValue = (originalVal: string, node: EmailCodeTreeDataChildType) => {
            const quill = reactQuillRef.current?.getEditor();
            if (quill) {
                const strValue = `{{ ${originalVal} }}`;
                const cursorPosition = quill.getSelection()?.index;

                if (cursorPosition) {
                    // quill.insertText(cursorPosition, strValue);
                    // quill.setSelection(cursorPosition + strValue.length, 0);
                    quill.insertEmbed(
                        cursorPosition,
                        //Insert the TemplateMarker in the same range as the cursor is

                        'CodeTemplateMarker',
                        //This is the name of the Embed

                        {
                            colour: 'yellow',
                            marker: strValue,
                            title: strValue,
                        }
                        //These are the variables to enter
                    );
                } else {
                    // quill.insertText(editorLastIndex, strValue);

                    quill.insertEmbed(
                        editorLastIndex,
                        //Insert the TemplateMarker in the same range as the cursor is

                        'CodeTemplateMarker',
                        //This is the name of the Embed

                        {
                            colour: 'code',
                            marker: strValue,
                            title: node.title,
                        }
                        //These are the variables to enter
                    );
                }
            }
        };

        useEffect(() => {
            if (showTemplateDropdown) {
                _fetchEmailTemplates();
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div className="ezrichtext-placeholder">
                <Form.Item
                    label="Email Body"
                    name="msg_body"
                    rules={[
                        { required: isRequired, message: 'Email body is required' },
                        {
                            validator: (_, value) => {
                                const parser = new DOMParser();

                                const { textContent } = parser.parseFromString(
                                    value,
                                    'text/html'
                                ).documentElement;

                                if (!textContent?.trim()) {
                                    return Promise.reject("Email body can't be empty");
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}
                >
                    <ReactQuill
                        ref={reactQuillRef}
                        style={{ width: '100%' }}
                        modules={modules}
                        theme="snow"
                        onBlur={previousSelection => {
                            setEditorLastIndex(previousSelection?.index || 0);
                        }}
                        {...props}
                    />
                </Form.Item>
                <div className="email-msg-editor-toolbar">
                    {showTemplateDropdown && (
                        <TreeSelect
                            style={{ width: '300px', marginRight: '8px' }}
                            value={null}
                            dropdownStyle={{
                                maxHeight: 400,
                                width: '500px',
                                maxWidth: '500px',
                                overflow: 'auto',
                            }}
                            placeholder={<b>Load from Template</b>}
                            allowClear
                            showSearch
                            treeDefaultExpandAll={false}
                            disabled={props.readOnly}
                            onSelect={(value: any, node: TemplateListTreeDataChildType) => {
                                if (node.children?.length) {
                                    return;
                                }
                                loadSelectedTemplate(node);
                            }}
                            treeData={templateListTreeData}
                            treeLine={{ showLeafIcon: false }}
                        />
                    )}
                    {!hideToolbar && (
                        <TreeSelect
                            style={{ width: '264px' }}
                            value={null}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={<b>Insert Code</b>}
                            allowClear
                            showSearch
                            treeDefaultExpandAll={false}
                            disabled={props.readOnly}
                            onSelect={(value: any, node: EmailCodeTreeDataChildType) => {
                                if (node.children?.length) {
                                    return;
                                }

                                insertChosenValue(value as string, node);
                            }}
                            treeData={emailCodesTreeData}
                            treeLine={{ showLeafIcon: false }}
                        />
                    )}
                </div>
            </div>
        );
    }
);
