// Core React libraries import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Button, Divider, Space, Form, Input, Popconfirm } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { EditOutlined, MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app APIs import
import { updateTransactionDetail } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import { TransactionDetailType } from 'types/transaction';
// EZ web-app components import
import { EZCurrencyFormat, EZDateFormat, EZDatePicker, EZInputNumber } from 'components';
import { showNotification } from 'utils/notificationUtil';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const colLabelSpan = 14;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];

export const GeneralInformationSection: React.FC<{
    contractID: number;
    reloadSubTabData: () => void;
    transactionData: TransactionDetailType | undefined;
    isRentalProperty: boolean;
}> = ({ contractID, reloadSubTabData, transactionData, isRentalProperty }) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const [form] = Form.useForm();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const _onSubmitForm = async (formValues?: any) => {
        if (isSubmittingForm) return;

        const formValuesFormatted = {
            ...formValues,

            // dates
            contract_date: formValues.contract_date
                ? moment(formValues.contract_date).format('YYYY-MM-DD')
                : null,
            effective_date: formValues.effective_date
                ? moment(formValues.effective_date).format('YYYY-MM-DD')
                : null,
            close_date: formValues.close_date
                ? moment(formValues.close_date).format('YYYY-MM-DD')
                : null,
            listing_date: formValues.listing_date
                ? moment(formValues.listing_date).format('YYYY-MM-DD')
                : null,
            listing_live_date: formValues.listing_live_date
                ? moment(formValues.listing_live_date).format('YYYY-MM-DD')
                : null,
            expiration_date: formValues.expiration_date
                ? moment(formValues.expiration_date).format('YYYY-MM-DD')
                : null,
            inspection_contingency_date: formValues.inspection_contingency_date
                ? moment(formValues.inspection_contingency_date).format('YYYY-MM-DD')
                : null,
            appraisal_contingency_date: formValues.appraisal_contingency_date
                ? moment(formValues.appraisal_contingency_date).format('YYYY-MM-DD')
                : null,
            financing_contingency_date: formValues.financing_contingency_date
                ? moment(formValues.financing_contingency_date).format('YYYY-MM-DD')
                : null,

            application_date: formValues.application_date
                ? moment(formValues.application_date).format('YYYY-MM-DD')
                : null,
            lease_signed_date: formValues.lease_signed_date
                ? moment(formValues.lease_signed_date).format('YYYY-MM-DD')
                : null,
            lease_start_date: formValues.lease_start_date
                ? moment(formValues.lease_start_date).format('YYYY-MM-DD')
                : null,
            lease_end_date: formValues.lease_end_date
                ? moment(formValues.lease_end_date).format('YYYY-MM-DD')
                : null,
            move_in_date: formValues.move_in_date
                ? moment(formValues.move_in_date).format('YYYY-MM-DD')
                : null,

            contingency_dates:
                formValues.contingency_dates && formValues.contingency_dates.length
                    ? formValues.contingency_dates?.map((elm: any) => ({
                          ...elm,
                          val_date: elm.val_date ? moment(elm.val_date).format('YYYY-MM-DD') : null,
                      }))
                    : [],
        };

        setIsSubmittingForm(true);
        updateTransactionDetail(contractID, formValuesFormatted, 'general_information')
            .then(async resp => {
                if (reloadSubTabData) {
                    reloadSubTabData();
                }

                showNotification(
                    'success',
                    'New data changes on general information have been saved successfully'
                );
                setIsEditMode(false);
            })
            .finally(() => {
                setIsSubmittingForm(false);
            });
    };

    useEffect(() => {
        if (isEditMode) {
            const contingency_dates = transactionData?.contract_date_additionals?.map(elm => {
                const obj = {
                    date_additional_id: elm.date_additional_id ? elm.date_additional_id : '',
                    date_name: elm.date_name ? elm.date_name : '',
                    val_date: elm.val_date ? moment.utc(elm.val_date) : null,
                };

                return obj;
            });

            if (!isRentalProperty) {
                form.setFieldsValue({
                    // prices
                    listprice: transactionData?.contract_price?.listprice || null,
                    salesprice: transactionData?.contract_price?.salesprice || null,
                    cccredit: transactionData?.contract_price?.cccredit || null,
                    deposits: transactionData?.contract_price?.deposits || null,

                    // dates
                    contract_date: transactionData?.contract_date?.contract_date
                        ? moment.utc(transactionData.contract_date.contract_date)
                        : null,
                    effective_date: transactionData?.contract_date?.effective_date
                        ? moment.utc(transactionData.contract_date.effective_date)
                        : null,
                    close_date: transactionData?.contract_date?.close_date
                        ? moment.utc(transactionData.contract_date.close_date)
                        : null,
                    listing_date: transactionData?.contract_date?.listing_date
                        ? moment.utc(transactionData.contract_date.listing_date)
                        : null,
                    listing_live_date: transactionData?.contract_date?.listing_live_date
                        ? moment.utc(transactionData.contract_date.listing_live_date)
                        : null,
                    expiration_date: transactionData?.contract_date?.expiration_date
                        ? moment.utc(transactionData.contract_date.expiration_date)
                        : null,
                    inspection_contingency_date: transactionData?.contract_date
                        ?.inspection_contingency_date
                        ? moment.utc(transactionData.contract_date.inspection_contingency_date)
                        : null,
                    appraisal_contingency_date: transactionData?.contract_date
                        ?.appraisal_contingency_date
                        ? moment.utc(transactionData.contract_date.appraisal_contingency_date)
                        : null,
                    financing_contingency_date: transactionData?.contract_date
                        ?.financing_contingency_date
                        ? moment.utc(transactionData.contract_date.financing_contingency_date)
                        : null,
                    year_built: transactionData?.contract_date?.year_built || null,

                    contingency_dates: contingency_dates,
                });
            } else {
                form.setFieldsValue({
                    // prices
                    marketedprice: transactionData?.contract_price?.marketedprice || null,
                    rentalprice: transactionData?.contract_price?.rentalprice || null,
                    credits: transactionData?.contract_price?.credits || null,

                    // dates
                    application_date: transactionData?.contract_date?.application_date
                        ? moment.utc(transactionData.contract_date.application_date)
                        : null,
                    lease_signed_date: transactionData?.contract_date?.lease_signed_date
                        ? moment.utc(transactionData.contract_date.lease_signed_date)
                        : null,
                    lease_start_date: transactionData?.contract_date?.lease_start_date
                        ? moment.utc(transactionData.contract_date.lease_start_date)
                        : null,
                    lease_end_date: transactionData?.contract_date?.lease_end_date
                        ? moment.utc(transactionData.contract_date.lease_end_date)
                        : null,
                    move_in_date: transactionData?.contract_date?.move_in_date
                        ? moment.utc(transactionData.contract_date.move_in_date)
                        : null,
                    year_built: transactionData?.contract_date?.year_built || null,

                    // lease term
                    lease_term: transactionData?.contract_date?.lease_term || '',

                    contingency_dates: contingency_dates,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    return (
        <Form form={form} onFinish={_onSubmitForm}>
            <Row gutter={rowFormViewGutter} className="row-wrapper">
                <Col span={24} className="">
                    <Row className="">
                        <Col span={12} className="text-title-secondary">
                            General Information
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            {!isEditMode && UserPermission && UserPermission.toggle === 2 && (
                                <Button
                                    className="ez-action-btn"
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => setIsEditMode(true)}
                                >
                                    Edit
                                </Button>
                            )}

                            {isEditMode && (
                                <Space>
                                    <Button
                                        className="mr-2"
                                        type="default"
                                        size="small"
                                        onClick={() => setIsEditMode(false)}
                                        disabled={isSubmittingForm}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className=""
                                        size="small"
                                        icon={<SaveOutlined />}
                                        type="primary"
                                        loading={isSubmittingForm}
                                        onClick={() => form.submit()}
                                    >
                                        {isSubmittingForm ? 'Saving' : 'Save'}
                                    </Button>
                                </Space>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Divider />

                {!isEditMode && !isRentalProperty && (
                    <>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Year Built
                        </Col>
                        <Col span={colValueSpan}>
                            {transactionData?.contract_date?.year_built || '-'}
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            List Price
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.listprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Sales Price
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.salesprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Closing Cost Credit
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.cccredit}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Deposit
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.deposits}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Contract Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.contract_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Contract Effective Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.effective_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Close Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.close_date}
                            />
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.listing_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Live Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                value={transactionData?.contract_date?.listing_live_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Expiration Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.expiration_date}
                            />
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Inspection Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.inspection_contingency_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Appraisal Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.appraisal_contingency_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Financing Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.financing_contingency_date}
                            />
                        </Col>
                    </>
                )}
                {!isEditMode && isRentalProperty && (
                    <>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Year Built
                        </Col>
                        <Col span={colValueSpan}>
                            {transactionData?.contract_date?.year_built || '-'}
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Marketed Price
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.marketedprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Rental Price
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.rentalprice}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Credits
                        </Col>
                        <Col span={colValueSpan}>
                            <EZCurrencyFormat
                                value={transactionData?.contract_price?.credits}
                                thousandSeparator={true}
                                prefix={'$ '}
                                displayType={'text'}
                            />
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Application Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.application_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Signed Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.lease_signed_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Start
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.lease_start_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease End
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.lease_end_date}
                            />
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Move In Date
                        </Col>
                        <Col span={colValueSpan}>
                            <EZDateFormat
                                noTimezone
                                value={transactionData?.contract_date?.move_in_date}
                            />
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Term(s)
                        </Col>
                        <Col span={colValueSpan}>
                            {transactionData?.contract_date?.lease_term || '-'}
                        </Col>
                    </>
                )}

                {!isEditMode &&
                    (transactionData?.contract_date_additionals?.length ? (
                        <>
                            <Divider />
                            {transactionData.contract_date_additionals.map(
                                (dateAdditional, index) => (
                                    <React.Fragment key={`contract_date_additionals_${index}`}>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {dateAdditional.date_name}
                                            </span>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZDateFormat value={dateAdditional?.val_date} />
                                        </Col>
                                    </React.Fragment>
                                )
                            )}
                        </>
                    ) : (
                        ''
                    ))}

                {isEditMode && !isRentalProperty && (
                    <>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Year Built
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="year_built" noStyle>
                                <EZInputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Enter the year"
                                    step={1}
                                    max={2100}
                                />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            List Price
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="listprice" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Sales Price
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="salesprice" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Closing Cost Credit
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="cccredit" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Deposit
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="deposits" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Contract Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="contract_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Contract Effective Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="effective_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Close Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="close_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="listing_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Live Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="listing_live_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Listing Expiration Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="expiration_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Inspection Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="inspection_contingency_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Appraisal Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="appraisal_contingency_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Financing Contingency Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="financing_contingency_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                    </>
                )}

                {isEditMode && isRentalProperty && (
                    <>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Year Built
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="year_built" noStyle>
                                <EZInputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Enter the year"
                                    step={1}
                                    max={2100}
                                />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Marketed Price
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="marketedprice" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Rental Price
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="rentalprice" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Credits
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="credits" noStyle>
                                <EZInputNumber
                                    numberType="currency"
                                    style={{ width: '100%' }}
                                    placeholder="Enter the price"
                                    step={10000}
                                />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Application Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="application_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Signed Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="lease_signed_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Start
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="lease_start_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease End
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="lease_end_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={colLabelSpan} className="ez-form-label">
                            Move In Date
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="move_in_date" noStyle>
                                <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={colLabelSpan} className="ez-form-label">
                            Lease Term(s)
                        </Col>
                        <Col span={colValueSpan}>
                            <Form.Item name="lease_term" noStyle>
                                <Input placeholder="Enter the lease term(s)" />
                            </Form.Item>
                        </Col>
                    </>
                )}

                {isEditMode && (
                    <>
                        <Divider />
                        <Form.List name="contingency_dates">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <React.Fragment key={`contingency_dates_${index}`}>
                                            <Col span={colLabelSpan} className="ez-form-label">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'date_additional_id']}
                                                    noStyle
                                                    hidden
                                                >
                                                    <Input hidden />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'date_name']}
                                                    noStyle
                                                >
                                                    <Input placeholder="Enter the field name" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'val_date']}
                                                    noStyle
                                                >
                                                    <EZDatePicker
                                                        style={{ width: '100%' }}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Form.Item>

                                                <Popconfirm
                                                    placement="bottomRight"
                                                    title={`Delete this date?`}
                                                    okText="Yes"
                                                    cancelText="Cancel"
                                                    onConfirm={() => remove(name)}
                                                    overlayClassName="custom-contingency-remover-popup"
                                                    // showArrow={false}
                                                >
                                                    <MinusCircleOutlined className="custom-contingency-remover" />
                                                </Popconfirm>
                                            </Col>
                                        </React.Fragment>
                                    ))}
                                    <Col span={24}>
                                        {' '}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add new contingency date
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.List>
                    </>
                )}
            </Row>
        </Form>
    );
};
