// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import moment from 'moment';
import { Row, Col, Typography, Badge, DatePicker, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { Gutter } from 'antd/lib/grid/row';
import { RangeValue } from 'rc-picker/es/interface';

// EZ web-app types import
import { ITabContentProps } from 'types/webAppTab';
// EZ web-app components import;
import { EZCurrencyFormat, EZOverlaySpinner } from 'components';
// EZ web-app utils import
import { getSummaryData } from 'utils/ez_api/summaryAPIs';
// EZ web-app pages import

// Page level styles
import './Summary.scss';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const rowGutter: [Gutter, Gutter] = [16, 16];
const rowGutterChild: [Gutter, Gutter] = [16, 16];

type SummaryStatItem = {
    label: string;
    value: string;
    color?: string;
};

type SummaryResponseData = {
    transactionStatsByStatus?: SummaryStatItem[];
    comissionStats?: SummaryStatItem[][];
    listingPrices?: SummaryStatItem[];
    closingTransactions?: SummaryStatItem[];
    expiringTransactions?: SummaryStatItem[];
    dueTasksStat?: SummaryStatItem[];
};

const defaultTransactionSummaryData: SummaryResponseData = {
    transactionStatsByStatus: [],
    comissionStats: [
        [
            {
                label: 'Buyer(s)',
                value: '0',
            },
            {
                label: 'Commission',
                value: '0',
            },
        ],
        [
            {
                label: 'Listing(s)',
                value: '0',
            },
            {
                label: 'Commission',
                value: '0',
            },
        ],
    ],
    listingPrices: [
        {
            label: 'Avg. List Price',
            value: '0',
        },
        {
            label: 'Avg. Sales Price',
            value: '0',
        },
    ],
    closingTransactions: [
        {
            label: 'Closing This Month',
            value: '0',
        },
        {
            label: 'Closing in 30 Days',
            value: '0',
        },
        {
            label: 'Closing in 60 Days',
            value: '0',
        },
        {
            label: 'Closing in 90 Days',
            value: '0',
        },
    ],
    expiringTransactions: [
        {
            label: 'Expiring This Month',
            value: '0',
        },
        {
            label: 'Expiring in 30 Days',
            value: '0',
        },
        {
            label: 'Expiring in 60 Days',
            value: '0',
        },
        {
            label: 'Expiring in 90 Days',
            value: '0',
        },
    ],
};
const defaultDueTasksSummaryData: SummaryResponseData = {
    dueTasksStat: [
        {
            label: `Due within Last 7 Days`,
            value: '0',
        },
        {
            label: 'Due Today',
            value: '0',
        },
        {
            label: 'Due This Week',
            value: '0',
        },
        {
            label: 'Due in Next 14 Days',
            value: '0',
        },
    ],
};

export const SummaryTab: React.FC<ITabContentProps> = () => {
    const activeDate = moment();

    const [loadingTransactionStats, setLoadingTransactionStats] = useState<boolean>(false);
    const [loadingDuetasksStats, setLoadingDuetasksStats] = useState<boolean>(false);
    const [filterRangeDate, setFilterRangeDate] = useState<RangeValue<moment.Moment>>();
    const [summaryData, setSummaryData] = useState<SummaryResponseData>(
        defaultTransactionSummaryData
    );
    const [summaryDueTasksData, setSummaryDueTasksData] = useState<SummaryResponseData>(
        defaultDueTasksSummaryData
    );

    const _reloadSummaryData = (date_start?: string, date_end?: string) => {
        setLoadingTransactionStats(true);
        setLoadingDuetasksStats(true);
        getSummaryData('by-transactions', date_start, date_end, activeDate.unix())
            .then(resp => {
                const data = resp?.data as SummaryResponseData;

                const _summaryResponseData = {
                    transactionStatsByStatus: data?.transactionStatsByStatus?.length
                        ? data.transactionStatsByStatus
                        : defaultTransactionSummaryData.transactionStatsByStatus,
                    comissionStats: data?.comissionStats?.length
                        ? data.comissionStats
                        : defaultTransactionSummaryData.comissionStats,
                    listingPrices: data?.listingPrices?.length
                        ? data.listingPrices
                        : defaultTransactionSummaryData.listingPrices,
                    closingTransactions: data?.closingTransactions?.length
                        ? data.closingTransactions
                        : defaultTransactionSummaryData.closingTransactions,
                    expiringTransactions: data?.expiringTransactions?.length
                        ? data.expiringTransactions
                        : defaultTransactionSummaryData.expiringTransactions,
                };

                setSummaryData({ ...summaryData, ..._summaryResponseData });
            })
            .finally(() => {
                setLoadingTransactionStats(false);
            });

        getSummaryData('by-duetasks', date_start, date_end, activeDate.unix())
            .then(resp => {
                const data = resp?.data as SummaryResponseData;

                const _summaryResponseData = {
                    dueTasksStat: data?.dueTasksStat?.length
                        ? data.dueTasksStat
                        : defaultDueTasksSummaryData.dueTasksStat,
                };

                setSummaryDueTasksData({ ...summaryDueTasksData, ..._summaryResponseData });
            })
            .finally(() => {
                setLoadingDuetasksStats(false);
            });
    };

    const _changeDateRangeFilter = (newRange: RangeValue<moment.Moment>) => {
        setFilterRangeDate(newRange);

        _reloadSummaryData(
            newRange?.[0]?.format('YYYY-MM-DD'),
            newRange?.[1]?.format('YYYY-MM-DD')
        );
    };

    useEffect(() => {
        _reloadSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row gutter={rowGutter} className="ez-tab-summary">
                <Col span={24}>
                    <Row className="" justify="center">
                        <Col
                            span={12}
                            className=""
                            style={{ textAlign: 'left', paddingTop: '5px' }}
                        ></Col>
                        <Col span={12} className="" style={{ textAlign: 'right' }}>
                            <div>
                                <Button
                                    className="ml-2 ez-action-btn"
                                    icon={<ReloadOutlined />}
                                    size="small"
                                    onClick={() => _reloadSummaryData()}
                                >
                                    Reload
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row className="ez-sub-content-wrapper" gutter={[0, 16]}>
                        {loadingTransactionStats && <EZOverlaySpinner />}
                        <Col span={24}>
                            <Row className="" justify="center">
                                <Col
                                    span={12}
                                    className=""
                                    style={{ textAlign: 'left', paddingTop: '5px' }}
                                >
                                    <Text type="secondary">
                                        <span>Showing summary for: </span>
                                        <span>
                                            {filterRangeDate
                                                ? `${filterRangeDate[0]?.format(
                                                      'MMM DD, YYYY'
                                                  )} to ${filterRangeDate[1]?.format(
                                                      'MMM DD, YYYY'
                                                  )}`
                                                : 'All Time'}
                                        </span>
                                    </Text>
                                </Col>
                                <Col span={12} className="" style={{ textAlign: 'right' }}>
                                    <div>
                                        <RangePicker
                                            format="MMM DD, YYYY"
                                            onChange={newRange => _changeDateRangeFilter(newRange)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className="">
                            <Title level={5} underline>
                                <span>Summary by Transaction Status </span>
                            </Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={rowGutterChild} className="">
                                {summaryData?.transactionStatsByStatus?.map((status, idx) => (
                                    <Col
                                        key={`status-stats-${idx}`}
                                        span={3}
                                        className=" "
                                        style={{ textAlign: 'center' }}
                                    >
                                        <div className="bg-grey summary-status-wrapper">
                                            <div className="summary-status-number">
                                                {status.value}
                                            </div>
                                            <div className="summary-status-label">
                                                <Badge color={status.color} text={status.label} />
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row gutter={rowGutterChild}>
                                <Col span={12}>
                                    <Row gutter={rowGutterChild} className="mt-3">
                                        <Col span={24} className="">
                                            <Title level={5} underline>
                                                Listing and Rental Prices
                                            </Title>
                                        </Col>
                                        <Col span={24} className="">
                                            <Row gutter={rowGutterChild}>
                                                {summaryData?.listingPrices?.length
                                                    ? summaryData.listingPrices.map(
                                                          (listingPriceItem, idx) => (
                                                              <Col
                                                                  span={12}
                                                                  className=" "
                                                                  style={{
                                                                      textAlign: 'center',
                                                                  }}
                                                                  key={`listingPrices-${idx}`}
                                                              >
                                                                  <div className="bg-grey bg-rounded">
                                                                      <Row
                                                                          gutter={rowGutterChild}
                                                                          justify="space-between"
                                                                      >
                                                                          <Col span={24}>
                                                                              <div className=" summary-status-wrapper">
                                                                                  <div className="summary-status-number">
                                                                                      <EZCurrencyFormat
                                                                                          ifEmptyText={
                                                                                              '$ 0'
                                                                                          }
                                                                                          value={
                                                                                              listingPriceItem?.value
                                                                                          }
                                                                                          thousandSeparator={
                                                                                              true
                                                                                          }
                                                                                          prefix={
                                                                                              '$ '
                                                                                          }
                                                                                          displayType={
                                                                                              'text'
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                                  <div className="summary-status-label">
                                                                                      {
                                                                                          listingPriceItem?.label
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                  </div>
                                                              </Col>
                                                          )
                                                      )
                                                    : ''}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row gutter={rowGutterChild} className="mt-3">
                                        <Col span={24} className="">
                                            <Title level={5} underline>
                                                Commission
                                            </Title>
                                        </Col>
                                        <Col span={24} className="">
                                            <Row gutter={rowGutterChild}>
                                                {summaryData?.comissionStats?.length
                                                    ? summaryData.comissionStats.map(
                                                          (comissionItem, idx) => (
                                                              <Col
                                                                  span={12}
                                                                  className=" "
                                                                  style={{
                                                                      textAlign: 'center',
                                                                  }}
                                                                  key={`comissionStats-${idx}`}
                                                              >
                                                                  <div className="bg-grey bg-rounded">
                                                                      <Row
                                                                          gutter={rowGutterChild}
                                                                          justify="space-between"
                                                                      >
                                                                          <Col span={9}>
                                                                              <div className="  summary-status-wrapper">
                                                                                  <div className="summary-status-number">
                                                                                      {
                                                                                          comissionItem?.[0]
                                                                                              .value
                                                                                      }
                                                                                  </div>
                                                                                  <div className="summary-status-label">
                                                                                      {
                                                                                          comissionItem?.[0]
                                                                                              .label
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          </Col>
                                                                          <Col span={15}>
                                                                              <div className=" summary-status-wrapper">
                                                                                  <div className="summary-status-number">
                                                                                      <EZCurrencyFormat
                                                                                          ifEmptyText={
                                                                                              '$ 0'
                                                                                          }
                                                                                          value={
                                                                                              comissionItem?.[1]
                                                                                                  .value
                                                                                          }
                                                                                          thousandSeparator={
                                                                                              true
                                                                                          }
                                                                                          prefix={
                                                                                              '$ '
                                                                                          }
                                                                                          displayType={
                                                                                              'text'
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                                  <div className="summary-status-label">
                                                                                      {
                                                                                          comissionItem?.[1]
                                                                                              .label
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                  </div>
                                                              </Col>
                                                          )
                                                      )
                                                    : ''}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={rowGutter}>
                        <Col span={24}>
                            <Row gutter={rowGutter} className="">
                                <Col span={8}>
                                    <div className="ez-sub-content-wrapper">
                                        <Row gutter={rowGutterChild} className="">
                                            <Col span={24} className="">
                                                <div className="">
                                                    <Title level={5} underline>
                                                        Closing Transactions
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col span={24} className="">
                                                {loadingTransactionStats && <EZOverlaySpinner />}
                                                <Row gutter={rowGutterChild} justify="center">
                                                    {summaryData?.closingTransactions?.length
                                                        ? summaryData.closingTransactions.map(
                                                              (closingTransactionItem, idx) => (
                                                                  <Col
                                                                      span={24}
                                                                      key={`ClosingTransactions-${idx}`}
                                                                  >
                                                                      <div className="bg-grey bg-rounded">
                                                                          <Row
                                                                              gutter={
                                                                                  rowGutterChild
                                                                              }
                                                                              justify="center"
                                                                          >
                                                                              <Col
                                                                                  span={9}
                                                                                  style={{
                                                                                      textAlign:
                                                                                          'right',
                                                                                  }}
                                                                              >
                                                                                  <div className="  summary-status-wrapper">
                                                                                      <div className="summary-status-number">
                                                                                          {
                                                                                              closingTransactionItem.value
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                              <Col span={15}>
                                                                                  <div className=" summary-status-wrapper">
                                                                                      <div
                                                                                          className="summary-status-label"
                                                                                          style={{
                                                                                              marginTop:
                                                                                                  '4px',
                                                                                              textAlign:
                                                                                                  'left',
                                                                                          }}
                                                                                      >
                                                                                          {
                                                                                              closingTransactionItem.label
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                          </Row>
                                                                      </div>
                                                                  </Col>
                                                              )
                                                          )
                                                        : ''}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="ez-sub-content-wrapper">
                                        <Row gutter={rowGutterChild} className="">
                                            <Col span={24} className="">
                                                <div className="">
                                                    <Title level={5} underline>
                                                        Expiring Transactions
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col span={24} className="">
                                                {loadingTransactionStats && <EZOverlaySpinner />}
                                                <Row gutter={rowGutterChild} justify="center">
                                                    {summaryData?.expiringTransactions?.length
                                                        ? summaryData.expiringTransactions.map(
                                                              (expiringTransactionItem, idx) => (
                                                                  <Col
                                                                      span={24}
                                                                      key={`expiringTransactions-${idx}`}
                                                                  >
                                                                      <div className="bg-grey bg-rounded">
                                                                          <Row
                                                                              gutter={
                                                                                  rowGutterChild
                                                                              }
                                                                              justify="center"
                                                                          >
                                                                              <Col
                                                                                  span={9}
                                                                                  style={{
                                                                                      textAlign:
                                                                                          'right',
                                                                                  }}
                                                                              >
                                                                                  <div className="  summary-status-wrapper">
                                                                                      <div className="summary-status-number">
                                                                                          {
                                                                                              expiringTransactionItem.value
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                              <Col span={15}>
                                                                                  <div className=" summary-status-wrapper">
                                                                                      <div
                                                                                          className="summary-status-label"
                                                                                          style={{
                                                                                              marginTop:
                                                                                                  '4px',
                                                                                              textAlign:
                                                                                                  'left',
                                                                                          }}
                                                                                      >
                                                                                          {
                                                                                              expiringTransactionItem.label
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                          </Row>
                                                                      </div>
                                                                  </Col>
                                                              )
                                                          )
                                                        : ''}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="ez-sub-content-wrapper">
                                        <Row gutter={rowGutterChild}>
                                            <Col span={24} className="">
                                                <div className="">
                                                    <Title level={5} underline>
                                                        Due Tasks
                                                    </Title>
                                                </div>
                                            </Col>
                                            <Col span={24} className="">
                                                {loadingDuetasksStats && <EZOverlaySpinner />}
                                                <Row gutter={rowGutterChild} justify="center">
                                                    {summaryDueTasksData?.dueTasksStat?.length
                                                        ? summaryDueTasksData.dueTasksStat.map(
                                                              (dueTaskStatItem, idx) => (
                                                                  <Col
                                                                      span={24}
                                                                      key={`dueTasksStat-${idx}`}
                                                                  >
                                                                      <div className="bg-grey bg-rounded">
                                                                          <Row
                                                                              gutter={
                                                                                  rowGutterChild
                                                                              }
                                                                              justify="center"
                                                                          >
                                                                              <Col
                                                                                  span={9}
                                                                                  style={{
                                                                                      textAlign:
                                                                                          'right',
                                                                                  }}
                                                                              >
                                                                                  <div className="  summary-status-wrapper">
                                                                                      <div className="summary-status-number">
                                                                                          {
                                                                                              dueTaskStatItem.value
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                              <Col span={15}>
                                                                                  <div className=" summary-status-wrapper">
                                                                                      <div
                                                                                          className="summary-status-label"
                                                                                          style={{
                                                                                              marginTop:
                                                                                                  '4px',
                                                                                              textAlign:
                                                                                                  'left',
                                                                                          }}
                                                                                      >
                                                                                          {
                                                                                              dueTaskStatItem.label
                                                                                          }
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                          </Row>
                                                                      </div>
                                                                  </Col>
                                                              )
                                                          )
                                                        : ''}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default SummaryTab;
