// Core React libs import
import React, { ReactEventHandler, useEffect, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { DefaultOptionType, SelectProps } from 'antd/es/select';
import { AccountCompactDataType } from 'types/account';
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';

type EZSelectEmailRecipientProps = SelectProps & {
    showTransactionOptions?: boolean;
    showMe?: boolean;
};

const _onSelectRecipient: SelectProps['onSelect'] = (value: string, option: any) => {
    // console.log(this);
    return false;
};

const _onChange: SelectProps['onChange'] = (value: string, option: any) => {
    // console.log(value, option);
};

const _onKeyDown = (e: React.KeyboardEvent) => {};

export const DefaultMeStrOptions: string[] = ['{{ Me }}'];
export const DefaultMeOptions: {
    value: string;
    label: string;
}[] = DefaultMeStrOptions.map(v => ({
    value: v,
    label: v,
}));

export const DefaultTransactionStrOptions_Listing: string[] = [
    '{{ Buyers }}',
    "{{ Buyer's agents }}",
    '{{ Sellers }}',
    "{{ Seller's agents }}",
];
export const DefaultTransactionStrOptions_Rental: string[] = [
    '{{ Tenants }}',
    '{{ Referral Source }}',
    '{{ Landlord }}',
    '{{ Leasing agents }}',
];

export const DefaultTransactionOptions: SelectProps['options'] = [
    {
        label: 'Listing',
        options: DefaultTransactionStrOptions_Listing.map(v => ({
            value: v,
            label: v,
        })),
    },
    {
        label: 'Rental',
        options: DefaultTransactionStrOptions_Rental.map(v => ({
            value: v,
            label: v,
        })),
    },
];

let hasTeamMemberAPIBeenCalled = false;
let teamMembers: AccountCompactDataType[] = [];

export const EZSelectEmailRecipient: React.FC<EZSelectEmailRecipientProps> = ({
    showTransactionOptions,
    showMe,
    ...props
}) => {
    const opts: SelectProps['options'] = [];

    if (showMe) {
        opts.push(...DefaultMeOptions);
    }

    if (showTransactionOptions) {
        opts.push(...DefaultTransactionOptions);
    }

    const [selectOpts, setSelectOpts] = useState<DefaultOptionType[]>(opts);

    // opts.push({
    //     label: 'Rental',
    //     options: DefaultTransactionStrOptions_Rental.map(v => ({
    //         value: v,
    //         label: v,
    //     })),
    // });

    const _fetchTeamMembers = () => {
        if (hasTeamMemberAPIBeenCalled) {
            setSelectOpts([
                ...opts,
                {
                    label: 'Team Members',
                    options: teamMembers.map(member => ({
                        label: `${member.fullname} - ${member.email}`,
                        value: member.email,
                    })),
                },
            ]);
        } else {
            getAllTeamMembers().then(({ data }) => {
                hasTeamMemberAPIBeenCalled = true;
                teamMembers = (data as AccountCompactDataType[]) || [];
                setSelectOpts([
                    ...opts,
                    {
                        label: 'Team Members',
                        options: teamMembers.map(member => ({
                            label: member.fullname,
                            value: member.email,
                        })),
                    },
                ]);
            });
        }
    };

    useEffect(() => {
        // _buildOpts();
        _fetchTeamMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select<any, any>
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Choose from the list or enter the email address(es)"
            optionLabelProp="value"
            notFoundContent={''}
            options={selectOpts}
            allowClear
            {...props}
        ></Select>
    );
};
