// Core React libraries import
import React from 'react';

// Core ANTD and 3rdparty libraries import

// EZ web-app utils import
// EZ web-app APIs importtransactionNoteAPIs';
// EZ web-app types import
import { TransactionDetailTabProps } from 'types/transaction';
// EZ web-app components import
import { AppPageNotes, NOTE_EMBEDDED_TYPE } from 'components/appPage/notes';

// EZ web-app styles and assets import

export const SubTab_Notes: React.FC<TransactionDetailTabProps> = ({
    contractID,
    manualReloadCount,
    reloadTransactionStat,
}) => {
    return (
        <div className="ez_detail_notes">
            <AppPageNotes
                pageType={NOTE_EMBEDDED_TYPE.TRANSACTION_DETAIL}
                contractID={contractID}
                manualReloadCount={manualReloadCount}
                reloadTransactionStat={reloadTransactionStat}
            />
        </div>
    );
};
