// Core React libraries import
import { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    Popover,
    Radio,
    Row,
    Select,
    Space,
    Typography,
} from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    EllipsisOutlined,
    SaveFilled,
    SendOutlined,
    UnlockOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewSubUser,
    deleteSubUser,
    getSubUserPermission,
    getSubUsers,
    resendInvitation,
    updateSubUser,
    updateSubUserPermission,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { ACCOUNT_ROLES, OfficeType, SubUserDataType } from 'types/account';
import { EZDateFormat, EZTable } from 'components';
import { forwardRefEZTableProps } from 'components/table/EZProTable';
import { getAllOffices } from 'utils/ez_api/officeAPIs';
import { AccountRolePermission } from './accountRoleSetting';
import { FormProps } from 'antd/es/form/Form';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';
// EZ web-app components import

// EZ web-app styles and assets import

const AGENT_ACCOUNTROLE_ID = 4;

async function _fetchData(EZTableQueryParams: string): Promise<any> {
    return getSubUsers(EZTableQueryParams).then(result => result);
}

const UserRolesInformation = () => {
    return (
        <Row gutter={[0, 12]} style={{ width: '500px' }}>
            <Col>
                <b>Manager</b> - Has access to all transactions in the system and has full
                administrative access.
            </Col>
            <Col>
                <b>Broker</b> - Has access to all transactions in the system. This is perfect for a
                broker, or office manager or even a transaction coordinator who works on all of your
                transactions.
            </Col>
            <Col>
                <b>Coordinator</b> - Has access to transactions that they create themselves, or
                transactions that they are assigned to. To assign a Coordinator to a transaction,
                click the pen icon to edit a transaction and look for the 'Assign To' field
                underneath the 'Year Built' field at the top of your transaction page.
            </Col>
            <Col>
                <b>Agent</b> - Has access to transactions that they create themselves, or
                transactions that they are assigned to. To assign an agent to a transaction, click
                the pen icon to edit a transaction and then start typing an agents name in the
                Buyer's or Seller's agent fields. The system will search for that sub-user account.
                When the system finds their name, select their name and they will be assigned to the
                transaction.
            </Col>
        </Row>
    );
};

const AccountPermission: React.FC<{ subUser: SubUserDataType }> = ({ subUser }) => {
    const [form] = Form.useForm();

    const [isPermissionSettingActive, setIsPermissionSettingActive] = useState(false);
    const [mainToggleValue, setMainToggleValue] = useState<number>(-1);

    const _fetchPermissionSetting = () => {
        getSubUserPermission(subUser.account_id)
            .then(({ data }) => {
                form.setFieldsValue(data);

                setIsPermissionSettingActive(data.active ? true : false);
                setMainToggleValue(data.toggle || 0);
            })
            .finally(() => {});
    };

    const _onFieldsChange: FormProps['onFieldsChange'] = changedField => {
        const changedRoleField = changedField?.[0];

        if (changedRoleField) {
            const field_name = (changedRoleField.name as string[])?.[0];
            const field_value = changedRoleField.value;

            const payload: any = {};

            if (field_name !== 'active') {
                payload.toggle_name = field_name;
                payload.toggle_value = field_value;
            }

            if (field_name === 'active') {
                payload.active = field_value;
            }

            if (field_name === 'toggle') {
                setMainToggleValue(field_value);
            }

            updateSubUserPermission(subUser.account_id, payload).then(() => {
                _fetchPermissionSetting();
                setTimeout(() => {
                    if (field_name !== 'active') {
                        // showMessage(
                        //     'success',
                        //     `Permission setting for: ${subUser.fullname} has been successfully saved.`
                        // );
                    }
                }, 100);
            });
        }
    };

    const _changeTogglePermission = (toggle: boolean) => {
        // setIsPermissionSettingActive(toggle);
    };

    useEffect(() => {
        _fetchPermissionSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Form form={form} onFieldsChange={_onFieldsChange}>
            <Row className="mt-2" gutter={[24, 0]}>
                <Col span={24} className="">
                    <Form.Item label="" name="active" valuePropName="checked" initialValue={false}>
                        <Checkbox onChange={evt => _changeTogglePermission(evt?.target?.checked)}>
                            Use account-level permission
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="mt-5" gutter={[24, 0]}>
                <Col span={4} className="pb-2 border-left border-bottom">
                    <b>Edit</b>
                </Col>

                <Col span={3} className="pb-2 border-left border-bottom">
                    <b>Contacts</b>
                </Col>

                <Col span={4} className="pb-2 border-left border-bottom">
                    <b>Documents</b>
                </Col>

                <Col span={3} className="pb-2 border-left border-bottom">
                    <b>Task</b>
                </Col>

                <Col span={3} className="pb-2 border-left border-bottom">
                    <b>Notes</b>
                </Col>

                <Col span={3} className="pb-2 border-left border-bottom border-right">
                    <b>Assign Tasks</b>
                </Col>

                <Col span={4} className="pb-2 border-left border-bottom border-right">
                    <b>Email Function</b>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={4} className="border-left pt-3">
                    <Form.Item name="toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={3} className="border-left pt-3">
                    <Form.Item name="contacts_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1} disabled={[0].includes(mainToggleValue)}>
                                    View Only
                                </Radio>
                                <Radio value={2} disabled={[0, 1].includes(mainToggleValue)}>
                                    Full Access
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={4} className="border-left pt-3">
                    <Form.Item name="documents_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1} disabled={[0].includes(mainToggleValue)}>
                                    View Only
                                </Radio>
                                <Radio value={2} disabled={[0, 1].includes(mainToggleValue)}>
                                    Full Access
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={3} className="border-left pt-3">
                    <Form.Item name="tasks_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1} disabled={[0].includes(mainToggleValue)}>
                                    View Only
                                </Radio>
                                <Radio value={2} disabled={[0, 1].includes(mainToggleValue)}>
                                    Full Access
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={3} className="border-left pt-3">
                    <Form.Item name="notes_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1} disabled={[0].includes(mainToggleValue)}>
                                    View Only
                                </Radio>
                                <Radio value={2} disabled={[0, 1].includes(mainToggleValue)}>
                                    Full Access
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={3} className="border-left pt-3 ">
                    <Form.Item name="assigntasks_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>No</Radio>
                                <Radio value={1} disabled={[0, 1].includes(mainToggleValue)}>
                                    Yes
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={4} className="border-left pt-3 border-right">
                    <Form.Item name="emails_toggle" noStyle>
                        <Radio.Group disabled={!isPermissionSettingActive}>
                            <Space direction="vertical">
                                <Radio value={0}>No</Radio>
                                <Radio value={1} disabled={[0, 1].includes(mainToggleValue)}>
                                    Yes
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export const SubUserSetting: React.FC<{ tabKey: string; activeTabKey: string }> = ({
    tabKey,
    activeTabKey,
}) => {
    const accountRoles = ACCOUNT_ROLES.filter(role => role.accountrole_id !== 1);
    const [form] = Form.useForm();
    const EZTableRef = useRef<forwardRefEZTableProps>();
    const HomeCtx = useContext(HomeContext);

    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

    const [offices, setOffices] = useState<OfficeType[]>([]);

    const _doActionTaskMenu = (menuKey: string, record: SubUserDataType) => {
        switch (menuKey) {
            case 'account_permission':
                // setIsPermissionModalOpen(true);
                const accountPermissionModal = Modal.confirm({
                    title: `Set Account-level Permission for ${record.fullname}`,
                    content: <AccountPermission subUser={record} />,
                    onOk: () => {
                        return Promise.resolve(true);
                    },
                    closable: true,
                    maskClosable: true,
                    width: 1100,
                    cancelText: 'Close',
                    okButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    style: {
                        top: '40px',
                    },
                });
                break;
            case 'resend_invitation':
                const resendInviteConfirmationModal = Modal.confirm({
                    title: `Resend Invitation confirmation`,
                    content: (
                        <>
                            Are you sure to resend the invitation for user: <b>{record.fullname}</b>
                            ?
                        </>
                    ),
                    onOk: () => {
                        resendInviteConfirmationModal.update({
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Sending...',
                        });
                        resendInvitation(record.account_id).then(() => {
                            showMessage('success', 'Invitation has been resent.');
                        });
                        return Promise.resolve(true);
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;

            case 'delete_user':
                const deleteConfirmationModal = Modal.confirm({
                    title: `Delete confirmation`,
                    content: (
                        <>
                            Are you sure to delete user: <b>{record.fullname}</b>?
                        </>
                    ),
                    onOk: () => {
                        deleteConfirmationModal.update({
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Deleting...',
                        });
                        deleteSubUser(record.account_id).then(() => {
                            showNotification(
                                'success',
                                `Sub User: ${record.fullname} has been successfully deleted.`
                            );
                            EZTableRef.current?.reload();
                        });
                        return Promise.resolve(true);
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    const columns: ProColumns<SubUserDataType>[] = [
        {
            title: '#',
            dataIndex: '',
            key: '',
            width: '50px',
            editable: false,
            render: (_, __, index) => index + 1,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '240px',
            editable: false,
            render: (_, record) => <Typography.Text strong>{record.email}</Typography.Text>,
        },
        {
            title: 'Fullname',
            dataIndex: 'fullname',
            key: 'fullname',
            width: '180px',
            formItemProps: (_, config) => {
                return {
                    initialValue: config.entity.fullname,
                    rules: [{ required: true }],
                };
            },
        },
        {
            title: 'Role',
            dataIndex: 'accountrole_id',
            key: 'accountrole_id',
            width: '150px',
            valueType: 'select',
            render: (_, record) => {
                return record.accountrole.accountrole;
            },
            fieldProps: {
                options: accountRoles,
                optionFilterProp: 'accountrole',
                fieldNames: {
                    label: 'accountrole',
                    value: 'accountrole_id',
                },
                placeholder: 'Role',
            },
            formItemProps: (_, config) => {
                return {
                    initialValue: config.entity.accountrole_id,
                    rules: [{ required: true }],
                };
            },
        },
        {
            title: 'Office',
            dataIndex: 'office_id',
            key: 'office_id',
            width: '160px',
            valueType: 'select',
            render: (_, record) => {
                return record.offices?.[0]?.office;
            },
            formItemProps: (_, config) => {
                return {
                    initialValue: config.entity.offices?.[0]?.office_id,
                };
            },
            fieldProps: {
                options: offices,
                optionFilterProp: 'office',
                fieldNames: {
                    label: 'office',
                    value: 'office_id',
                },
                placeholder: 'Select Office',
            },
        },
        {
            title: 'Commission Split (Agent)',
            dataIndex: 'commission_split',
            key: 'commission_split',
            width: '120px',
            valueType: 'percent',
            render: (_, record) => {
                return record.accountrole_id === AGENT_ACCOUNTROLE_ID ? (
                    record.agent_commission_rate?.rate ? (
                        `${record.agent_commission_rate?.rate * 100} %`
                    ) : (
                        <i>Empty</i>
                    )
                ) : (
                    '-'
                );
            },
            formItemProps: (_, config) => {
                return {
                    initialValue:
                        config.entity.accountrole_id !== 4
                            ? null
                            : config.entity.agent_commission_rate?.rate
                            ? config.entity.agent_commission_rate.rate * 100
                            : null,
                };
            },
            fieldProps: (form, config) => {
                const new_accountrole_id =
                    form?.getFieldValue([config.entity.account_id, 'accountrole_id']) ||
                    config.entity.accountrole_id;

                return {
                    placeholder: '',
                    min: 0,
                    addonAfter: '%',
                    style: {
                        display: new_accountrole_id === AGENT_ACCOUNTROLE_ID ? 'inherit' : 'none',
                    },
                    disabled:
                        form?.getFieldValue([config.entity.account_id, 'accountrole_id']) !==
                        AGENT_ACCOUNTROLE_ID,
                };
            },
        },
        {
            title: 'Date Added',
            dataIndex: 'date_posted',
            key: 'date_posted',
            width: '120px',
            editable: false,
            sorter: true,
            renderText: (_, record) => {
                return <EZDateFormat value={record.date_posted} format="MM/DD/YYYY hh:mma" />;
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '140px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Edit user"
                        size="small"
                        onClick={() => {
                            action?.startEditable(record.account_id);
                        }}
                    >
                        Edit
                    </Button>

                    <Divider type="vertical" />

                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={[
                                    {
                                        icon: <UnlockOutlined className="mr-3" />,
                                        label: 'Set Permission',
                                        key: 'account_permission',
                                    },
                                    { type: 'divider' },
                                    {
                                        icon: <SendOutlined className="mr-3" />,
                                        label: 'Resend Invitation',
                                        key: 'resend_invitation',
                                    },
                                    { type: 'divider' },
                                    {
                                        icon: <DeleteOutlined className="mr-3" />,
                                        label: 'Delete User',
                                        key: 'delete_user',
                                        danger: true,
                                    },
                                ]}
                                onClick={({ key }) => _doActionTaskMenu(key, record)}
                            />
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button
                                className="p-0"
                                type="link"
                                icon={<EllipsisOutlined />}
                                title="More action"
                            ></Button>
                        </span>
                    </Dropdown>
                </span>
            ),
        },
    ];

    const _saveSubUser = (subUser: any) => {
        console.log('🚀 ~ file: subUserSetting.tsx ~ line 276 ~ SubUserDataType', subUser);
        return updateSubUser(subUser.account_id, {
            fullname: subUser.fullname,

            office_id: subUser.office_id || null,

            accountrole_id: subUser.accountrole_id,

            commission_split: subUser.commission_split
                ? subUser.commission_split / 100
                : subUser.commission_split,
        }).then(() => {
            EZTableRef.current?.reload();
            setTimeout(() => {
                showMessage(
                    'success',
                    `Sub User: ${subUser.fullname} has been successfully updated.`
                );
            }, 100);
            return true;
        });
    };

    const _onFinish = (value: any) => {
        return addNewSubUser({
            email: value.email,

            fullname: value.fullname,

            office_id: value.office_id || null,

            accountrole_id: value.accountrole_id,

            subject: value.subject,
            message: value.message,
        })
            .then(() => {
                EZTableRef.current?.reload();
                setTimeout(() => {
                    showMessage(
                        'success',
                        `New Sub User: ${value.fullname} has been successfully added.`
                    );
                    form.resetFields();
                    form.setFieldValue('office_id', offices?.[0]?.office_id);
                }, 100);
                // update onboarding step to completed
                updateOnboardingStep('add_new_sub_account', HomeCtx.refetchOnboardingList);
                return true;
            })
            .catch(err => {
                const message = err?.response?.data?.message;
                showNotification('error', message, 'Error happened');
            });
    };

    const _fetchOffices = () => {
        getAllOffices().then(({ data }) => {
            setOffices(data || []);

            if (data) {
                form.setFieldValue('office_id', data[0]?.office_id);
            }
        });
    };

    const _handleCancelPermissionModal = () => {
        setIsPermissionModalOpen(false);
    };

    useEffect(() => {
        _fetchOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row gutter={[48, 24]}>
            {showAddUserForm && (
                <Col span={24} className="mt-3 pt-3">
                    <Form layout="vertical" form={form} onFinish={_onFinish} requiredMark={false}>
                        <Row gutter={[12, 0]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: "Email can't be empty" }]}
                                >
                                    <Input
                                        allowClear
                                        placeholder="Enter the user's email address"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Fullname"
                                    name="fullname"
                                    rules={[{ required: true, message: "Fullname can't be empty" }]}
                                >
                                    <Input allowClear placeholder="Enter the user's name" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Office"
                                    name="office_id"
                                    rules={[{ required: true, message: "Office can't be empty" }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        options={offices}
                                        optionFilterProp="office"
                                        fieldNames={{
                                            label: 'office',
                                            value: 'office_id',
                                        }}
                                        defaultActiveFirstOption
                                        showArrow
                                        placeholder={'Select the office'}
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    extra={
                                        <>
                                            Click{' '}
                                            <Popover
                                                content={<UserRolesInformation />}
                                                title="How do the Roles work?"
                                                trigger="click"
                                                placement="right"
                                            >
                                                <a>here</a>
                                            </Popover>{' '}
                                            to see the detail on how do the Roles work.
                                        </>
                                    }
                                    label="Role"
                                    name="accountrole_id"
                                    rules={[{ required: true, message: "Role can't be empty" }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        options={accountRoles}
                                        optionFilterProp="accountrole"
                                        fieldNames={{
                                            label: 'accountrole',
                                            value: 'accountrole_id',
                                        }}
                                        defaultActiveFirstOption
                                        showArrow
                                        placeholder={'Select the role'}
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    initialValue={
                                        'You have been setup with an EZCoordinator Account'
                                    }
                                    label="Invitation Subject"
                                    name="subject"
                                >
                                    <Input allowClear placeholder="Enter the invitation subject" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Invitation Message (optional)" name="message">
                                    <Input.TextArea
                                        rows={3}
                                        placeholder="Enter the invitation message..."
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Form.Item>
                                    <Button
                                        type="default"
                                        className="mr-3"
                                        onClick={() => setShowAddUserForm(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            )}
            <Col span={24}>
                <EZTable
                    rowKey="account_id"
                    ref={EZTableRef}
                    columns={columns}
                    pagination={{
                        pageSize: 20,
                        pageSizeOptions: [20, 50, 100],
                    }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: SubUserDataType, oldData) => {
                            return _saveSubUser({
                                ...newData,
                                fullname: newData.fullname,
                            });
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    fetchData={_fetchData}
                    toolBarComponents={[
                        <Button
                            type="primary"
                            className="ml-3"
                            onClick={() => {
                                setShowAddUserForm(true);
                                form.resetFields();
                                form.setFieldValue('office_id', offices?.[0]?.office_id);
                            }}
                            icon={<UserAddOutlined />}
                        >
                            Add New User
                        </Button>,
                    ]}
                />
            </Col>
            <>
                <Modal
                    title={``}
                    visible={isPermissionModalOpen}
                    onCancel={_handleCancelPermissionModal}
                    closable
                    maskClosable
                    width={1000}
                >
                    {/* <AccountRolePermission /> */}
                </Modal>
            </>
        </Row>
    );
};
