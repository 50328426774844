// Core React libs import
import React, { useState, useEffect, useContext } from 'react';

// Core ANTD and other libs import
import moment from 'moment';
import {
    Typography,
    Row,
    Col,
    Descriptions,
    Button,
    Dropdown,
    Menu,
    Space,
    BackTop,
    Tabs,
    Select,
    Collapse,
    Badge,
    Tag,
    Tooltip,
    Modal,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import {
    EditOutlined,
    DownOutlined,
    TableOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
    MailOutlined,
    CaretRightOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    FlagTwoTone,
    StopOutlined,
    SaveOutlined,
} from '@ant-design/icons';

// EZ web-app APIs import
import {
    getTransactionDetail,
    DetailType,
    getTransactionQuickStats,
    archiveTransaction,
    requestTransactionBackup,
} from 'utils/ez_api/transactionAPIs';
// Core EZ app level import
import { HomeContext } from 'context/EZContext';
import { ITabContentProps } from 'types/webAppTab';
import {
    TransactionDetailType,
    TransactionDetailTabPane,
    TransactionQuickStat,
    STATUS_LIST_RENTAL,
} from 'types/transaction';
import { EZCopyable, EZOverlaySpinner } from 'components';
import { TransactionSalesType } from 'components/select/selectSaleType';

import { SubTab_ContractInfo } from './subTabs/SubTab_ContractInfo';
import { SubTab_Contacts } from './subTabs/SubTab_Contacts';
import { SubTab_Notes } from './subTabs/SubTab_Notes';
import { SubTab_Tasks } from './subTabs/SubTab_Tasks';
// import { SubTab_PropertyDetail } from './subTabs/SubTab_PropertyDetail';
import { SubTab_Changelogs } from './subTabs/SubTab_Changelogs';
import { SubTab_Documents } from './subTabs/SubTab_Documents';
import { SubTab_Greensheet } from './subTabs/SubTab_Greensheet';
import { showNotification } from 'utils/notificationUtil';
import { ModalEditTransactionInfo } from 'pages/tabs/transaction/modals';
import { getTransactionStatusCategory, isRentalProperty } from 'utils/webAppUtil';
import { AccountPermissionType } from 'types/account';
import { isDemoENV } from 'utils/envUtil';
import { SubTab_Emails } from './subTabs/SubTab_Emails';

import './ViewDetailTransaction.scss';
import { HOTJAR_EVENT_TYPE, hotjarEvent } from 'utils/3rd_party_api/hotjarUtil';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const _subTabs: TransactionDetailTabPane[] = [
    {
        key: 'ez_detail_general_information',
        label: 'Contract Information',
        tabContent: SubTab_ContractInfo,
    },
    {
        key: 'ez_detail_tasks',
        label: <span>Tasks</span>,
        tabContent: SubTab_Tasks,
    },
    {
        key: 'ez_detail_emails',
        label: <span>Emails</span>,
        tabContent: SubTab_Emails,
    },
    {
        key: 'ez_detail_contacts',
        label: 'Additional Contacts',
        tabContent: SubTab_Contacts,
    },
    {
        key: 'ez_detail_documents',
        label: <span>Documents and Files</span>,
        tabContent: SubTab_Documents,
    },
    {
        key: 'ez_detail_notes',
        label: 'Notes',
        tabContent: SubTab_Notes,
    },
    {
        key: 'ez_detail_greensheet',
        label: 'Green Sheet',
        tabContent: SubTab_Greensheet,
    },
    {
        key: 'ez_detail_changelog',
        label: 'Changelogs',
        tabContent: SubTab_Changelogs,
    },
];

const DefaultTransactionQuickStat = {
    taskStatInfo: {
        incomplete: 0,
        complete: 0,
        total: 0,
    },
    documentStatInfo: {
        incomplete: 0,
        complete: 0,
        total: 0,
    },
    noteStatInfo: {
        total: 0,
    },
};

const colLabelSpan = 6;
const colValueSpan = 18;
const rowFormViewGutter: [Gutter, Gutter] = [12, 4];

const ChangeSubTabLayoutElm: React.FC<{
    subTabLayout: string;
    setSubTabLayout: React.Dispatch<React.SetStateAction<string>>;
}> = ({ subTabLayout, setSubTabLayout }) => {
    return (
        <Select
            defaultValue={subTabLayout}
            style={{ width: 200 }}
            onChange={val => setSubTabLayout(val)}
        >
            <Option value="tabs">
                <span>
                    <Text>View Layout: </Text>
                    <Text strong>Tabs</Text>
                </span>
            </Option>
            <Option value="collapsible">
                <span>
                    <Text>View Layout: </Text>
                    <Text strong>Collapsible</Text>
                </span>
            </Option>
        </Select>
    );
};

export const generateTaskBadge = (transactionQuickStat: TransactionQuickStat) => {
    const hasIncompleteRecords =
        transactionQuickStat.taskStatInfo.complete < transactionQuickStat.taskStatInfo.total;

    return (
        <Tooltip
            title={`${transactionQuickStat.taskStatInfo.complete} of ${transactionQuickStat.taskStatInfo.total} tasks are complete`}
        >
            <Tag
                className={`tag-rounded ml-2 mr-0 ${
                    hasIncompleteRecords && 'has-incomplete-records'
                }`}
            >
                {transactionQuickStat.taskStatInfo.complete}&nbsp;/&nbsp;
                {transactionQuickStat.taskStatInfo.total}
            </Tag>
        </Tooltip>
    );
};

export const generateDocBadge = (transactionQuickStat: TransactionQuickStat) => {
    return (
        <Tag className={`tag-rounded ml-2 mr-0 `}>
            {transactionQuickStat.documentStatInfo.complete}&nbsp;/&nbsp;
            {transactionQuickStat.documentStatInfo.total}
        </Tag>
    );
};

export const generateNoteBadge = (transactionQuickStat: TransactionQuickStat) => {
    return (
        // <></>
        <Tag className={`tag-rounded ml-2 mr-0 `}>{transactionQuickStat.noteStatInfo.total}</Tag>
    );
};

const getTabsByPermission = (permission?: AccountPermissionType) => {
    let subTabs: TransactionDetailTabPane[] = _subTabs;
    if (permission) {
        if (!permission.tasks_toggle) {
            subTabs = subTabs.filter(t => t.key !== 'ez_detail_tasks');
        }

        if (!permission.contacts_toggle) {
            subTabs = subTabs.filter(t => t.key !== 'ez_detail_contacts');
        }

        if (!permission.documents_toggle) {
            subTabs = subTabs.filter(t => t.key !== 'ez_detail_documents');
        }

        if (!permission.notes_toggle) {
            subTabs = subTabs.filter(t => t.key !== 'ez_detail_notes');
            subTabs = subTabs.filter(t => t.key !== 'ez_detail_changelog');
        }

        return subTabs;
    } else {
        return [];
    }
};

const ViewDetailTransaction: React.FC<ITabContentProps> = ({ tabKey, data }) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const [isRental, setIsRental] = useState<boolean>(false);

    // custom reload counter to force the sub tab to re-load the data
    const [manualReloadCount, setManualReloadCount] = useState<number>(0);

    // const subTabs = [..._subTabs];
    const subTabs = getTabsByPermission(UserPermission);
    const [subTabLayout, setSubTabLayout] = useState<string>('tabs');

    const [isLoadingTransactionDetail, setIsLoadingTransactionDetail] = useState<boolean>(false);
    const [isLoadingSubTabData, setIsLoadingSubTabData] = useState<boolean>(false);
    const [transactionData, setTransactionData] = useState<TransactionDetailType>(
        data as TransactionDetailType
    );
    const [transactionQuickStat, setTransactionQuickStat] = useState<TransactionQuickStat>(
        DefaultTransactionQuickStat
    );

    const [isModalEditTransactionInfoVisible, setIsModalEditTransactionInfoVisible] =
        useState(false);

    const _handleChangeSubTab = (subTab: string) => {
        // hit hotjar event every click sub-tab tasks
        if (subTab === 'ez_detail_tasks') {
            hotjarEvent(HOTJAR_EVENT_TYPE.CLICK_TRANSACTION_TASKS);
            return;
        }
    };

    const _reloadTransactionDetail = async (
        showLoadingProgress: boolean = false,
        detailType: DetailType = 'all'
    ) => {
        if (showLoadingProgress) {
            setIsLoadingTransactionDetail(true);
        }
        return getTransactionDetail(transactionData.contract_id, detailType)
            .then(resp => {
                if (resp.data) {
                    setTransactionData({ ...transactionData, ...resp.data });
                    setIsRental(isRentalProperty(resp.data?.status_id));
                }
            })
            .catch(() => {
                showNotification('error', 'Error happened while reloading the transaction data.');
            })
            .finally(() => {
                if (showLoadingProgress) {
                    setIsLoadingTransactionDetail(false);
                }
            });
    };

    const _reloadTransactionStat = (contract_id?: number) => {
        if (!contract_id) return;

        getTransactionQuickStats(contract_id).then(resp => {
            if (resp.data) {
                setTransactionQuickStat({
                    taskStatInfo: {
                        incomplete: resp.data?.incomplete_task_count || 0,
                        complete: resp.data?.complete_task_count || 0,
                        total:
                            resp.data?.complete_task_count + resp.data?.incomplete_task_count || 0,
                    },
                    documentStatInfo: {
                        incomplete: resp.data?.incomplete_doc_count || 0,
                        complete: resp.data?.complete_doc_count || 0,
                        total: resp.data?.complete_doc_count + resp.data?.incomplete_doc_count || 0,
                    },
                    noteStatInfo: {
                        total: resp.data?.email_msg_count + resp.data?.contract_note_count || 0,
                    },
                });
            }
        });
    };

    const _doActionTaskMenu = (menuKey: string) => {
        switch (menuKey) {
            case 'backup_transaction':
                const backupConfirmationModal = Modal.confirm({
                    title: `Confirmation to back up`,
                    width: 600,
                    content: (
                        <>
                            <p>
                                Are you sure to backup the transaction:{' '}
                                <b>{transactionData.address}</b>?
                            </p>
                            <br />
                            <p>
                                *Important to note that:
                                <ul>
                                    <li>
                                        The backup link will be sent to the email(s):{' '}
                                        <b>{HomeCtx?.userInfo?.email}</b> and the backup files will
                                        be automatically deleted after 2 weeks.
                                    </li>
                                    <li>
                                        The email might land in your Spam folder. Please make sure
                                        to double-check the folder.
                                    </li>
                                </ul>
                            </p>
                        </>
                    ),
                    okText: 'Back up',
                    onOk: () => {
                        backupConfirmationModal.update({
                            closable: true,
                            maskClosable: true,
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Requesting...',
                        });
                        return requestTransactionBackup(transactionData.contract_id).then(() => {
                            showNotification(
                                'success',
                                `You have successfully requested to backup the transaction: ${transactionData.address}`
                            );
                            return true;
                        });
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;

            case 'delete_transaction':
                const deleteConfirmationModal = Modal.confirm({
                    title: `Delete confirmation`,
                    width: 600,
                    content: (
                        <>
                            Are you sure to archive the transaction:{' '}
                            <b>{transactionData.address}</b>?
                        </>
                    ),
                    okText: 'Delete',
                    onOk: () => {
                        deleteConfirmationModal.update({
                            closable: true,
                            maskClosable: true,
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Deleting...',
                        });
                        return archiveTransaction(transactionData.contract_id).then(() => {
                            HomeCtx.closeTab?.(tabKey);
                            showNotification(
                                'success',
                                `You have successfully deleted the transaction: ${transactionData.address}`
                            );
                            return true;
                        });
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        _reloadTransactionDetail(true, 'basic_info');
        _reloadTransactionStat(transactionData.contract_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="ez-tab-transaction-detail"
            id={`ez-tab-transaction-detail-${transactionData.contract_id}`}
        >
            <>
                <ModalEditTransactionInfo
                    transactionData={transactionData}
                    setTransactionData={setTransactionData}
                    isModalVisible={isModalEditTransactionInfoVisible}
                    setIsModalVisible={setIsModalEditTransactionInfoVisible}
                    reloadTransactionDetail={_reloadTransactionDetail}
                />
            </>

            <Badge.Ribbon
                className={` ${
                    transactionData.contract_flag && transactionData.flag_id === 1 ? 'no-flag' : ''
                } ${isLoadingTransactionDetail && 'loading'}`}
                text={<span style={{ minWidth: '10px', display: 'inline-block' }}>&nbsp;</span>}
                color={
                    transactionData.contract_flag && transactionData.flag_id !== 1
                        ? transactionData.contract_flag.flag_color
                        : 'transparent'
                }
                placement="start"
            >
                {isLoadingTransactionDetail && <EZOverlaySpinner />}
                <div className="mb-4 ez-sub-content-wrapper ez-sub-content-header-section">
                    <div>
                        <Row>
                            <Col span={24}>
                                <Descriptions
                                    title={
                                        <>{transactionData.address}</> || (
                                            <>
                                                <Text type="secondary" italic>
                                                    Address is empty
                                                </Text>
                                            </>
                                        )
                                    }
                                    bordered={true}
                                    column={1}
                                    size="small"
                                    layout="horizontal"
                                    extra={
                                        <>
                                            <Button
                                                className="ez-action-btn"
                                                icon={<ReloadOutlined />}
                                                // size="small"
                                                onClick={() => {
                                                    _reloadTransactionDetail(true, 'basic_info');
                                                    _reloadTransactionStat(
                                                        transactionData.contract_id
                                                    );
                                                    setManualReloadCount(manualReloadCount + 1);
                                                }}
                                                loading={isLoadingTransactionDetail}
                                            >
                                                Reload
                                            </Button>
                                            {UserPermission && UserPermission.toggle === 2 && (
                                                <Button
                                                    className="mr-3 ml-3 ez-action-btn"
                                                    icon={<EditOutlined />}
                                                    // size="small"
                                                    onClick={() =>
                                                        setIsModalEditTransactionInfoVisible(true)
                                                    }
                                                >
                                                    Edit Basic Information
                                                </Button>
                                            )}
                                            {UserPermission && UserPermission.toggle === 2 && (
                                                <Dropdown
                                                    trigger={['click']}
                                                    className="ez-action-btn"
                                                    placement="bottomRight"
                                                    overlay={
                                                        <Menu
                                                            onClick={({ key }) =>
                                                                _doActionTaskMenu(key)
                                                            }
                                                            items={[
                                                                {
                                                                    label: 'Backup Transaction',
                                                                    key: 'backup_transaction',
                                                                    icon: <SaveOutlined />,
                                                                },
                                                                {
                                                                    type: 'divider',
                                                                },
                                                                {
                                                                    label: 'Delete Transaction',
                                                                    key: 'delete_transaction',
                                                                    danger: true,
                                                                    icon: (
                                                                        <DeleteOutlined
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    ),
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                >
                                                    <Button>
                                                        <Space>
                                                            More actions
                                                            <DownOutlined />
                                                        </Space>
                                                    </Button>
                                                </Dropdown>
                                            )}
                                        </>
                                    }
                                ></Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={24} className="mt-2">
                            <Col span={12} sm={24} md={24} lg={12} xl={12}>
                                <Row gutter={rowFormViewGutter}>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Address</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <div className="">
                                            {transactionData.address || (
                                                <Text type="secondary" italic>
                                                    Address is empty
                                                </Text>
                                            )}
                                        </div>
                                        <div className="">
                                            {transactionData.city || (
                                                <Text type="secondary" italic>
                                                    City is empty
                                                </Text>
                                            )}
                                            ,{' '}
                                            {transactionData.state || (
                                                <Text type="secondary" italic>
                                                    State is empty
                                                </Text>
                                            )}
                                            ,{' '}
                                            {transactionData.zip || (
                                                <Text type="secondary" italic>
                                                    ZIP is empty
                                                </Text>
                                            )}
                                            <EZCopyable
                                                text={`${
                                                    transactionData.address || 'Address is empty'
                                                }\n${transactionData.city || 'City is empty'}, ${
                                                    transactionData.state || 'State is empty'
                                                }, ${transactionData.zip || 'ZIP is empty'}`}
                                                className="ml-1"
                                            />
                                        </div>
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Status</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {getTransactionStatusCategory(transactionData.status_id)}
                                        <Badge className="mr-1 ml-2" color={'black'} />
                                        <Button
                                            className={`ez-btn-contract-status ez-tr-status-${transactionData.status_id}`}
                                            type="text"
                                            shape="round"
                                            style={{
                                                backgroundColor: `${transactionData.contract_status?.color}`,
                                                color: 'white',
                                            }}
                                            size="small"
                                        >{`${transactionData.contract_status?.status}`}</Button>
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>MLS</Text>
                                    </Col>
                                    <Col span={colValueSpan}>{transactionData.mis || '-'}</Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>ESCROW</Text>
                                    </Col>
                                    <Col span={colValueSpan}>{transactionData.escrow || '-'}</Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Type</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {transactionData.contract_property_type?.type_name || '-'}
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Sales Type</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <TransactionSalesType
                                            contract_id={transactionData.contract_id}
                                            sale_types_id={transactionData.sale_types_id}
                                        />
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Flag</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {transactionData.flag_id !== 1 ? (
                                            <FlagTwoTone
                                                twoToneColor={
                                                    transactionData.contract_flag?.flag_color
                                                }
                                            />
                                        ) : (
                                            <StopOutlined />
                                        )}
                                        <span className="ml-2">
                                            {transactionData.contract_flag?.flag}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} sm={24} md={24} lg={12} xl={12}>
                                <Row gutter={rowFormViewGutter}>
                                    {!isDemoENV() && (
                                        <>
                                            <Col span={colLabelSpan}>
                                                <Text strong>Email Address</Text>
                                            </Col>
                                            <Col span={colValueSpan}>
                                                <div>
                                                    <Text code>
                                                        {transactionData.account_id}_
                                                        {transactionData.contract_id}@
                                                        {isDemoENV() && 'demomail.'}
                                                        ezcoordinator.com
                                                    </Text>
                                                    <EZCopyable
                                                        className="ml-1"
                                                        text={`${transactionData.account_id}_
                                                ${transactionData.contract_id}@${
                                                            isDemoENV() ? 'demomail.' : ''
                                                        }ezcoordinator.com`}
                                                    />
                                                </div>
                                                {!isDemoENV() && (
                                                    <div>
                                                        <Text type="secondary">
                                                            <InfoCircleOutlined /> Any message,
                                                            notes and docs sent to this address will
                                                            be synced to the respective transaction
                                                            record
                                                        </Text>
                                                    </div>
                                                )}
                                            </Col>
                                        </>
                                    )}
                                    <Col span={colLabelSpan}>
                                        <Text strong>Added By</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <span className="mr-1">
                                            {transactionData.account?.fullname || '-'}
                                        </span>
                                        <span>
                                            On{' '}
                                            {transactionData.date_posted
                                                ? moment(transactionData.date_posted).format(
                                                      'MMM Do, YYYY hh:mma'
                                                  )
                                                : '-'}
                                        </span>
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Assigned To</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {transactionData.assigned_accounts?.length ? (
                                            <ul style={{ paddingLeft: 14 }}>
                                                {transactionData.assigned_accounts.map(account => (
                                                    <li>
                                                        {account.fullname}
                                                        {/* <Text code className="mb-2 ml-2">
                                                            {account.accountrole?.accountrole}
                                                        </Text> */}
                                                        <Tag className="mb-1 ml-2 tag-rounded">
                                                            {account.accountrole?.accountrole}
                                                        </Tag>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            '-'
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Badge.Ribbon>

            <div className="mb-4 ez-sub-content-wrapper ez-sub-content-section">
                <Row>
                    <Col span={24}>
                        {isLoadingSubTabData && <EZOverlaySpinner />}
                        {subTabLayout === 'tabs' && (
                            <Tabs
                                id="ez-tab-transaction-detail-tab-wrapper-id"
                                className="ez-tab-transaction-detail-tab-wrapper"
                                style={{ marginBottom: 32 }}
                                defaultActiveKey={data?.subTabKey || subTabs[0].key}
                                tabBarExtraContent={
                                    <Row
                                        style={{
                                            paddingRight: '24px',
                                            paddingBottom: '2px',
                                        }}
                                    >
                                        <ChangeSubTabLayoutElm
                                            subTabLayout={subTabLayout}
                                            setSubTabLayout={setSubTabLayout}
                                        />
                                    </Row>
                                }
                                onChange={activeKey => {
                                    _handleChangeSubTab(activeKey);
                                    // setActiveTabKey(activeKey);
                                    if (window.scrollY >= 400) window.scrollTo(0, 387);
                                }}
                            >
                                {subTabs.map(subTab => (
                                    <TabPane
                                        tab={
                                            <>
                                                {subTab.label}
                                                <span>
                                                    {subTab.key === 'ez_detail_tasks'
                                                        ? generateTaskBadge(transactionQuickStat)
                                                        : subTab.key === 'ez_detail_documents'
                                                        ? generateDocBadge(transactionQuickStat)
                                                        : subTab.key === 'ez_detail_notes'
                                                        ? generateNoteBadge(transactionQuickStat)
                                                        : ''}
                                                </span>
                                            </>
                                        }
                                        key={subTab.key}
                                    >
                                        <subTab.tabContent
                                            subTabs={subTabs}
                                            currentTab={subTab}
                                            contractID={transactionData.contract_id}
                                            contractAddress={transactionData.address}
                                            isRentalProperty={isRental}
                                            manualReloadCount={manualReloadCount}
                                            isLoadingSubTabData={isLoadingSubTabData}
                                            setIsLoadingSubTabData={setIsLoadingSubTabData}
                                            reloadTransactionStat={_reloadTransactionStat}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        )}
                        {subTabLayout === 'collapsible' && (
                            <>
                                <Row
                                    style={{
                                        position: 'absolute',
                                        right: '24px',
                                        paddingTop: '4px',
                                        zIndex: 80,
                                    }}
                                >
                                    <Col span={24}>
                                        <ChangeSubTabLayoutElm
                                            subTabLayout={subTabLayout}
                                            setSubTabLayout={setSubTabLayout}
                                        />
                                    </Col>
                                </Row>{' '}
                                <Collapse
                                    defaultActiveKey={subTabs[0].key}
                                    ghost
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                    )}
                                    onChange={activeKey => {}}
                                >
                                    {subTabs.map(subTab => (
                                        <Panel header={subTab.label} key={subTab.key}>
                                            <subTab.tabContent
                                                subTabs={subTabs}
                                                currentTab={subTab}
                                                contractID={transactionData.contract_id}
                                                contractAddress={transactionData.address}
                                                isRentalProperty={isRental}
                                                manualReloadCount={manualReloadCount}
                                                isLoadingSubTabData={isLoadingSubTabData}
                                                setIsLoadingSubTabData={setIsLoadingSubTabData}
                                                reloadTransactionStat={_reloadTransactionStat}
                                            />
                                        </Panel>
                                    ))}
                                </Collapse>
                            </>
                        )}
                    </Col>
                    <BackTop />
                </Row>
            </div>
        </div>
    );
};

export default ViewDetailTransaction;
