// Core React libraries import
import React from 'react';

// Core ANTD and 3rdparty libraries import

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { TransactionDetailTabProps } from 'types/transaction';
// EZ web-app components import
import { AppPageDocuments, DOC_EMBEDDED_TYPE } from 'components/appPage/documents';

// EZ web-app styles and assets import

export const SubTab_Documents: React.FC<TransactionDetailTabProps> = ({
    contractID,
    manualReloadCount,
    reloadTransactionStat,
}) => {
    return (
        <div className="ez_detail_documents">
            <AppPageDocuments
                pageType={DOC_EMBEDDED_TYPE.TRANSACTION_DETAIL}
                contractID={contractID}
                manualReloadCount={manualReloadCount}
                reloadTransactionStat={reloadTransactionStat}
            />
        </div>
    );
};
