// Core React libraries import
import React, { useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Input, Form, FormInstance, Col, Select, InputRef, Button } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

// EZ web-app utils import
import { validateEmailTemplateRecipients } from 'utils/formUtil';
// EZ web-app APIs import
// EZ web-app types import
import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
// EZ web-app components import
import { EZSelectEmailRecipient, EmailEditorFormItem } from 'components';
import { EZRichTextEditorForwardedRefProps } from 'components/richTextEditor/richTextEditor';
import { Partial_EmailTemplateFormItems } from '../partials/PartialEmailTemplateFormItems';
import { getEmailTemplateDetail } from 'utils/ez_api/emailTemplateAPIs';
import { AccountDataType } from 'types/account';

// EZ web-app styles and assets import

const { Option, OptGroup } = Select;

export const EditEmailTemplateModalContent: React.FC<{
    emailTemplate: Partial<EmailTemplateType>;
    form: FormInstance<any>;
    templateFolders: EmailTemplateFolderType[];
    UserInfo?: AccountDataType;
    mode: 'EDIT' | 'ADD';
}> = ({ emailTemplate, form, templateFolders, UserInfo, mode }) => {
    const [isSharedByOther, setIsSharedByOther] = useState<boolean>(false);

    const _getTemplateDetail = (templateId: number) => {
        getEmailTemplateDetail(emailTemplate.email_template_id || 0).then(({ data }) => {
            const emailTemplateDetail = data as EmailTemplateType;
            if (emailTemplateDetail && emailTemplateDetail.email_template_id) {
                if (emailTemplateDetail.account_id !== UserInfo?.account_id) {
                    setIsSharedByOther(true);
                } else {
                    setIsSharedByOther(false);
                }
            }
        });
    };

    const _setFieldValues = () => {
        form.resetFields();

        const emailTemplateTemp = { ...emailTemplate };

        if (!emailTemplateTemp.template_folder_id || emailTemplateTemp.template_folder_id === -1) {
            emailTemplateTemp.template_folder_id = undefined;
        }

        form.setFieldsValue({
            ...emailTemplateTemp,
        });
    };

    useEffect(() => {
        _setFieldValues();

        if (emailTemplate.email_template_id) {
            _getTemplateDetail(emailTemplate.email_template_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <Form layout="vertical" form={form} requiredMark={true}>
            <Row gutter={[16, 0]}>
                <Col span={12} id="template-name">
                    <Form.Item
                        label="Template Name"
                        name="template_name"
                        rules={[{ required: true, message: "Template name can't be empty" }]}
                    >
                        <Input placeholder="Enter the name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Folder"
                        name="template_folder_id"
                        required
                        rules={[{ required: true }]}
                        extra={
                            !isSharedByOther
                                ? ''
                                : "You can't change the folder for the template that is shared by the other."
                        }
                    >
                        <Select
                            placeholder="Select the folder"
                            defaultActiveFirstOption
                            disabled={isSharedByOther}
                        >
                            {templateFolders?.map((folder, idx) => (
                                <Option key={folder.folder_id} value={folder.folder_id}>
                                    {folder.is_shared ? <TeamOutlined className="mr-2" /> : <></>}
                                    {folder.folder_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Description" name="template_desc">
                        <Input.TextArea placeholder="Enter the description." rows={2} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Notes" name="template_note">
                        <Input.TextArea
                            placeholder="Enter the notes. Notes will be displayed before you send the email."
                            rows={2}
                        />
                    </Form.Item>
                </Col>
                <Partial_EmailTemplateFormItems
                    formType="EMAIL_TEMPLATE"
                    excludedTemplateIDs={
                        emailTemplate?.email_template_id ? [emailTemplate.email_template_id] : []
                    }
                />
            </Row>
        </Form>
    );
};
